let wasm;

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function getObject(idx) { return heap[idx]; }

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); };

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder = (typeof TextEncoder !== 'undefined' ? new TextEncoder('utf-8') : { encode: () => { throw Error('TextEncoder not available') } } );

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8ArrayMemory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8ArrayMemory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (cachedDataViewMemory0 === null || cachedDataViewMemory0.buffer.detached === true || (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

const CLOSURE_DTORS = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(state => {
    wasm.__wbindgen_export_2.get(state.dtor)(state.a, state.b)
});

function makeMutClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        const a = state.a;
        state.a = 0;
        try {
            return f(a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_2.get(state.dtor)(a, state.b);
                CLOSURE_DTORS.unregister(state);
            } else {
                state.a = a;
            }
        }
    };
    real.original = state;
    CLOSURE_DTORS.register(real, state, state);
    return real;
}
function __wbg_adapter_58(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h7d92fa6621fd1c75(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_61(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h878c629e821d0fd4(arg0, arg1);
}

function __wbg_adapter_64(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h603382e87092fd26(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_67(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h592ac5838306e377(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_70(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__hd064bc91a5e91caa(arg0, arg1);
}

function __wbg_adapter_77(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h98092fbd833426b0(arg0, arg1);
}

function __wbg_adapter_80(arg0, arg1, arg2) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__he7d3e6eb12fd459d(retptr, arg0, arg1, addHeapObject(arg2));
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        if (r1) {
            throw takeObject(r0);
        }
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function __wbg_adapter_83(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__hc77bfa702ef6d5fe(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_86(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__ha1588aace647c35c(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_89(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h8c56e3222bb796f0(arg0, arg1, addHeapObject(arg2));
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}
/**
* @returns {Promise<void>}
*/
export function main_js() {
    wasm.main_js();
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}
/**
* @param {Config} config
* @returns {Promise<void>}
*/
export function init(config) {
    _assertClass(config, Config);
    const ret = wasm.init(config.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {Config} config
* @returns {Promise<void>}
*/
export function initFresh(config) {
    _assertClass(config, Config);
    const ret = wasm.initFresh(config.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {Config} config
* @returns {Promise<void>}
*/
export function changeConfig(config) {
    _assertClass(config, Config);
    const ret = wasm.changeConfig(config.__wbg_ptr);
    return takeObject(ret);
}

/**
* @returns {Promise<Array<Network>>}
*/
export function getNetworks() {
    const ret = wasm.getNetworks();
    return takeObject(ret);
}

/**
* @returns {Promise<Array<Asset>>}
*/
export function getAssets() {
    const ret = wasm.getAssets();
    return takeObject(ret);
}

/**
* @returns {Promise<Array<AssetBalance>>}
*/
export function getBalances() {
    const ret = wasm.getBalances();
    return takeObject(ret);
}

/**
* @returns {Promise<Array<Transaction>>}
*/
export function getTransactions() {
    const ret = wasm.getTransactions();
    return takeObject(ret);
}

/**
* @returns {Promise<Array<Channel>>}
*/
export function getChannels() {
    const ret = wasm.getChannels();
    return takeObject(ret);
}

/**
* @param {Asset} asset
* @param {FiatCurrency} fiat_currency
* @returns {Promise<number>}
*/
export function getAssetFiatPrice(asset, fiat_currency) {
    _assertClass(asset, Asset);
    const ret = wasm.getAssetFiatPrice(asset.__wbg_ptr, fiat_currency);
    return takeObject(ret);
}

/**
* @param {FiatCurrency} fiat_currency
* @returns {Promise<FiatBalance>}
*/
export function getFiatBalance(fiat_currency) {
    const ret = wasm.getFiatBalance(fiat_currency);
    return takeObject(ret);
}

/**
* Get information about the rental duration and capacity limits of a rental node
*
* # Returns
*
* * `RentalNodeInfo` - Information about the channel rental duration and capacity limits of a rental node
* @returns {Promise<RentalNodeInfo>}
*/
export function getRentalNodeInfo() {
    const ret = wasm.getRentalNodeInfo();
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinConfig} config
* @returns {Promise<void>}
*/
export function bitcoinInit(network, config) {
    _assertClass(config, BitcoinConfig);
    const ret = wasm.bitcoinInit(network, config.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @returns {Promise<boolean>}
*/
export function bitcoinIsWatchOnly(network) {
    const ret = wasm.bitcoinIsWatchOnly(network);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @returns {Promise<BitcoinBlockHeader | undefined>}
*/
export function bitcoinLastSynced(network) {
    const ret = wasm.bitcoinLastSynced(network);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinAsset} asset
* @returns {Promise<void>}
*/
export function bitcoinAddAsset(network, asset) {
    _assertClass(asset, BitcoinAsset);
    const ret = wasm.bitcoinAddAsset(network, asset.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @returns {Promise<BitcoinAddress>}
*/
export function bitcoinGetDepositAddress(network) {
    const ret = wasm.bitcoinGetDepositAddress(network);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @returns {Promise<BitcoinPublicKey>}
*/
export function bitcoinGetNodeId(network) {
    const ret = wasm.bitcoinGetNodeId(network);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @returns {Promise<BitcoinFeeEstimate>}
*/
export function bitcoinGetFeeEstimates(network) {
    const ret = wasm.bitcoinGetFeeEstimates(network);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinAsset} asset
* @param {BitcoinAddress} to
* @param {SendAmount} amount
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<BitcoinTransactionRequest>}
*/
export function bitcoinCreateSendTx(network, asset, to, amount, fee_rate, legacy) {
    _assertClass(asset, BitcoinAsset);
    _assertClass(to, BitcoinAddress);
    _assertClass(amount, SendAmount);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.bitcoinCreateSendTx(network, asset.__wbg_ptr, to.__wbg_ptr, amount.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinTransactionRequest} tx_request
* @returns {Promise<BitcoinSignedTransactionRequest>}
*/
export function bitcoinSignTransaction(network, tx_request) {
    _assertClass(tx_request, BitcoinTransactionRequest);
    const ret = wasm.bitcoinSignTransaction(network, tx_request.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinSignedTransactionRequest} tx_request
* @returns {Promise<BitcoinTxid>}
*/
export function bitcoinFinalizeAndBroadcastTx(network, tx_request) {
    _assertClass(tx_request, BitcoinSignedTransactionRequest);
    const ret = wasm.bitcoinFinalizeAndBroadcastTx(network, tx_request.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinLightningPeer} peer
* @returns {Promise<void>}
*/
export function bitcoinConnectToPeer(network, peer) {
    _assertClass(peer, BitcoinLightningPeer);
    const ret = wasm.bitcoinConnectToPeer(network, peer.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinPublicKey} peer_id
* @param {Map<BitcoinAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function bitcoinEstimateOpenChannelFee(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, BitcoinPublicKey);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.bitcoinEstimateOpenChannelFee(network, peer_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinPublicKey} peer_id
* @param {Map<BitcoinAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<BitcoinTxid>}
*/
export function bitcoinOpenChannel(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, BitcoinPublicKey);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.bitcoinOpenChannel(network, peer_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {LightningChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function bitcoinEstimateCloseChannelFee(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.bitcoinEstimateCloseChannelFee(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {LightningChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<BitcoinTxid>}
*/
export function bitcoinCloseChannel(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.bitcoinCloseChannel(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {LightningChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function bitcoinEstimateForceCloseChannelFee(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.bitcoinEstimateForceCloseChannelFee(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {LightningChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<BitcoinTxid>}
*/
export function bitcoinForceCloseChannel(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.bitcoinForceCloseChannel(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {LightningChannelId} channel_id
* @param {Map<BitcoinAsset, SendAmount>} asset_amounts
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<void>}
*/
export function bitcoinSendChannelPayment(network, channel_id, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(channel_id, LightningChannelId);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinSendChannelPayment(network, channel_id.__wbg_ptr, addHeapObject(asset_amounts), ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinPublicKey} recipient
* @param {Map<BitcoinAsset, SendAmount>} asset_amounts
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<Map<BitcoinAsset, Amount>>}
*/
export function bitcoinEstimateSendPaymentFee(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, BitcoinPublicKey);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinEstimateSendPaymentFee(network, recipient.__wbg_ptr, addHeapObject(asset_amounts), ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinPublicKey} recipient
* @param {Map<BitcoinAsset, SendAmount>} asset_amounts
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<void>}
*/
export function bitcoinSendPayment(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, BitcoinPublicKey);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinSendPayment(network, recipient.__wbg_ptr, addHeapObject(asset_amounts), ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {BitcoinNetwork} network
* @param {BitcoinAsset} asset
* @param {Amount | undefined} [amount]
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<BitcoinLightningInvoice>}
*/
export function bitcoinCreateInvoice(network, asset, amount, hashlock, expiry_timeout_secs) {
    _assertClass(asset, BitcoinAsset);
    let ptr0 = 0;
    if (!isLikeNone(amount)) {
        _assertClass(amount, Amount);
        ptr0 = amount.__destroy_into_raw();
    }
    let ptr1 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr1 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinCreateInvoice(network, asset.__wbg_ptr, ptr0, ptr1, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {BitcoinLightningInvoice} invoice
* @returns {Promise<Amount>}
*/
export function bitcoinEstimatePayInvoiceFee(invoice) {
    _assertClass(invoice, BitcoinLightningInvoice);
    const ret = wasm.bitcoinEstimatePayInvoiceFee(invoice.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinLightningInvoice} invoice
* @returns {Promise<void>}
*/
export function bitcoinPayInvoice(invoice) {
    _assertClass(invoice, BitcoinLightningInvoice);
    const ret = wasm.bitcoinPayInvoice(invoice.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinLightningInvoice} invoice
* @param {SendAmount} amount
* @returns {Promise<Amount>}
*/
export function bitcoinEstimatePayEmptyInvoiceFee(invoice, amount) {
    _assertClass(invoice, BitcoinLightningInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.bitcoinEstimatePayEmptyInvoiceFee(invoice.__wbg_ptr, amount.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {BitcoinLightningInvoice} invoice
* @param {SendAmount} amount
* @returns {Promise<void>}
*/
export function bitcoinPayEmptyInvoice(invoice, amount) {
    _assertClass(invoice, BitcoinLightningInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.bitcoinPayEmptyInvoice(invoice.__wbg_ptr, amount.__wbg_ptr);
    return takeObject(ret);
}

/**
* Rent a Bitcoin channel
*
* # Arguments
*
* * `network` - The Bitcoin network of the rented channel
* * `asset` - The asset of the rented channel
* * `lifetime_secs` - The lifetime of the rented channel in seconds
* * `amount` - The amount to rent the channel for
* * `option` - The rental payment method
* @param {BitcoinNetwork} network
* @param {BitcoinAsset} asset
* @param {bigint} lifetime_secs
* @param {Amount} amount
* @param {RentalOption} option
* @returns {Promise<void>}
*/
export function bitcoinRentChannel(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, BitcoinAsset);
    _assertClass(amount, Amount);
    _assertClass(option, RentalOption);
    const ret = wasm.bitcoinRentChannel(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return takeObject(ret);
}

/**
* Estimates the fee to rent a Bitcoin channel
*
* # Arguments
*
* * `network` - The Bitcoin network of the rented channel
* * `asset` - The asset of the rented channel
* * `lifetime_secs` - The lifetime of the rented channel in seconds
* * `amount` - The amount to rent the channel for
* * `option` - The rental payment method
*
* # Returns
*
* * `Amount` - The estimated fee to rent the channel
* @param {BitcoinNetwork} network
* @param {BitcoinAsset} asset
* @param {bigint} lifetime_secs
* @param {Amount} amount
* @param {RentalOption} option
* @returns {Promise<Amount>}
*/
export function bitcoinEstimateRentChannelFee(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, BitcoinAsset);
    _assertClass(amount, Amount);
    _assertClass(option, RentalOption);
    const ret = wasm.bitcoinEstimateRentChannelFee(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmConfig} config
* @returns {Promise<void>}
*/
export function evmInit(network, config) {
    _assertClass(config, EvmConfig);
    const ret = wasm.evmInit(network, config.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @returns {Promise<boolean>}
*/
export function evmIsWatchOnly(network) {
    const ret = wasm.evmIsWatchOnly(network);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @returns {Promise<EvmBlockHeader | undefined>}
*/
export function evmLastSynced(network) {
    const ret = wasm.evmLastSynced(network);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmAsset} asset
* @returns {Promise<void>}
*/
export function evmAddAsset(network, asset) {
    _assertClass(asset, EvmAsset);
    const ret = wasm.evmAddAsset(network, asset.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @returns {Promise<EvmAddress>}
*/
export function evmGetDepositAddress(network) {
    const ret = wasm.evmGetDepositAddress(network);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @returns {Promise<EvmAddress>}
*/
export function evmGetNodeId(network) {
    const ret = wasm.evmGetNodeId(network);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @returns {Promise<EvmFeeEstimate>}
*/
export function evmGetFeeEstimates(network) {
    const ret = wasm.evmGetFeeEstimates(network);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmToken} token
* @param {EvmAddress} owner
* @param {EvmAddress} spender
* @returns {Promise<Amount>}
*/
export function evmGetTokenAllowance(network, token, owner, spender) {
    _assertClass(token, EvmToken);
    _assertClass(owner, EvmAddress);
    _assertClass(spender, EvmAddress);
    const ret = wasm.evmGetTokenAllowance(network, token.__wbg_ptr, owner.__wbg_ptr, spender.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmAsset} asset
* @param {EvmAddress} to
* @param {SendAmount} amount
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTransactionRequest>}
*/
export function evmCreateSendTx(network, asset, to, amount, fee_rate, legacy) {
    _assertClass(asset, EvmAsset);
    _assertClass(to, EvmAddress);
    _assertClass(amount, SendAmount);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmCreateSendTx(network, asset.__wbg_ptr, to.__wbg_ptr, amount.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmToken} token
* @param {EvmAddress} spender
* @param {AllowanceAmount} amount
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTransactionRequest>}
*/
export function evmCreateSetTokenAllowanceTx(network, token, spender, amount, fee_rate, legacy) {
    _assertClass(token, EvmToken);
    _assertClass(spender, EvmAddress);
    _assertClass(amount, AllowanceAmount);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmCreateSetTokenAllowanceTx(network, token.__wbg_ptr, spender.__wbg_ptr, amount.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmTransactionRequest} tx_request
* @returns {Promise<EvmSignedTransactionRequest>}
*/
export function evmSignTransaction(network, tx_request) {
    _assertClass(tx_request, EvmTransactionRequest);
    const ret = wasm.evmSignTransaction(network, tx_request.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmSignedTransactionRequest} tx_request
* @returns {Promise<EvmTxid>}
*/
export function evmFinalizeAndBroadcastTx(network, tx_request) {
    _assertClass(tx_request, EvmSignedTransactionRequest);
    const ret = wasm.evmFinalizeAndBroadcastTx(network, tx_request.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmLithiumPeer} peer
* @returns {Promise<void>}
*/
export function evmConnectToPeer(network, peer) {
    _assertClass(peer, EvmLithiumPeer);
    const ret = wasm.evmConnectToPeer(network, peer.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmAddress} peer_id
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function evmEstimateOpenChannelFee(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, EvmAddress);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmEstimateOpenChannelFee(network, peer_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmAddress} peer_id
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTxid>}
*/
export function evmOpenChannel(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, EvmAddress);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmOpenChannel(network, peer_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function evmEstimateDepositChannelFee(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmEstimateDepositChannelFee(network, channel_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTxid>}
*/
export function evmDepositChannel(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmDepositChannel(network, channel_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function evmEstimateWithdrawChannelFee(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmEstimateWithdrawChannelFee(network, channel_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTxid>}
*/
export function evmWithdrawChannel(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmWithdrawChannel(network, channel_id.__wbg_ptr, addHeapObject(asset_amounts), fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function evmEstimateCloseChannelFee(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmEstimateCloseChannelFee(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTxid>}
*/
export function evmCloseChannel(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmCloseChannel(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function evmEstimateForceCloseChannelFee(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmEstimateForceCloseChannelFee(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTxid>}
*/
export function evmForceCloseChannel(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmForceCloseChannel(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<Amount>}
*/
export function evmEstimateRedeemClosedChannelFee(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmEstimateRedeemClosedChannelFee(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {FeeRate} fee_rate
* @param {boolean} legacy
* @returns {Promise<EvmTxid>}
*/
export function evmRedeemClosedChannel(network, channel_id, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, FeeRate);
    const ret = wasm.evmRedeemClosedChannel(network, channel_id.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {LithiumChannelId} channel_id
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<void>}
*/
export function evmSendChannelPayment(network, channel_id, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(channel_id, LithiumChannelId);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmSendChannelPayment(network, channel_id.__wbg_ptr, addHeapObject(asset_amounts), ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmAddress} recipient
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<Map<EvmAsset, Amount>>}
*/
export function evmEstimateSendPaymentFee(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, EvmAddress);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmEstimateSendPaymentFee(network, recipient.__wbg_ptr, addHeapObject(asset_amounts), ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmAddress} recipient
* @param {Map<EvmAsset, SendAmount>} asset_amounts
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<void>}
*/
export function evmSendPayment(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, EvmAddress);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmSendPayment(network, recipient.__wbg_ptr, addHeapObject(asset_amounts), ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {EvmNetwork} network
* @param {EvmAsset} asset
* @param {Amount | undefined} [amount]
* @param {Hashlock | undefined} [hashlock]
* @param {bigint | undefined} [expiry_timeout_secs]
* @returns {Promise<EvmLithiumInvoice>}
*/
export function evmCreateInvoice(network, asset, amount, hashlock, expiry_timeout_secs) {
    _assertClass(asset, EvmAsset);
    let ptr0 = 0;
    if (!isLikeNone(amount)) {
        _assertClass(amount, Amount);
        ptr0 = amount.__destroy_into_raw();
    }
    let ptr1 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr1 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmCreateInvoice(network, asset.__wbg_ptr, ptr0, ptr1, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return takeObject(ret);
}

/**
* @param {EvmLithiumInvoice} invoice
* @returns {Promise<Amount>}
*/
export function evmEstimatePayInvoiceFee(invoice) {
    _assertClass(invoice, EvmLithiumInvoice);
    const ret = wasm.evmEstimatePayInvoiceFee(invoice.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmLithiumInvoice} invoice
* @returns {Promise<void>}
*/
export function evmPayInvoice(invoice) {
    _assertClass(invoice, EvmLithiumInvoice);
    const ret = wasm.evmPayInvoice(invoice.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmLithiumInvoice} invoice
* @param {SendAmount} amount
* @returns {Promise<Amount>}
*/
export function evmEstimatePayEmptyInvoiceFee(invoice, amount) {
    _assertClass(invoice, EvmLithiumInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.evmEstimatePayEmptyInvoiceFee(invoice.__wbg_ptr, amount.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {EvmLithiumInvoice} invoice
* @param {SendAmount} amount
* @returns {Promise<void>}
*/
export function evmPayEmptyInvoice(invoice, amount) {
    _assertClass(invoice, EvmLithiumInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.evmPayEmptyInvoice(invoice.__wbg_ptr, amount.__wbg_ptr);
    return takeObject(ret);
}

/**
* Rent an Evm channel
*
* # Arguments
*
* * `network` - The Evm network of the rented channel
* * `asset` - The asset of the rented channel
* * `lifetime_secs` - The lifetime of the rented channel in seconds
* * `amount` - The amount to rent the channel for
* * `option` - The rental payment method
* @param {EvmNetwork} network
* @param {EvmAsset} asset
* @param {bigint} lifetime_secs
* @param {Amount} amount
* @param {RentalOption} option
* @returns {Promise<void>}
*/
export function evmRentChannel(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, EvmAsset);
    _assertClass(amount, Amount);
    _assertClass(option, RentalOption);
    const ret = wasm.evmRentChannel(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return takeObject(ret);
}

/**
* Estimates the fee to rent an Evm channel
*
* # Arguments
*
* * `network` - The Evm network of the rented channel
* * `asset` - The asset of the rented channel
* * `lifetime_secs` - The lifetime of the rented channel in seconds
* * `amount` - The amount to rent the channel for
* * `option` - The rental payment method
*
* # Returns
*
* * `Amount` - The estimated fee to rent the channel
* @param {EvmNetwork} network
* @param {EvmAsset} asset
* @param {bigint} lifetime_secs
* @param {Amount} amount
* @param {RentalOption} option
* @returns {Promise<Amount>}
*/
export function evmEstimateRentChannelFee(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, EvmAsset);
    _assertClass(amount, Amount);
    _assertClass(option, RentalOption);
    const ret = wasm.evmEstimateRentChannelFee(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return takeObject(ret);
}

/**
* @param {Asset} base
* @param {Asset} quote
* @returns {Promise<void>}
*/
export function initPair(base, quote) {
    _assertClass(base, Asset);
    var ptr0 = base.__destroy_into_raw();
    _assertClass(quote, Asset);
    var ptr1 = quote.__destroy_into_raw();
    const ret = wasm.initPair(ptr0, ptr1);
    return takeObject(ret);
}

/**
* @returns {Promise<(MarketInfo)[]>}
*/
export function getMarketsInfo() {
    const ret = wasm.getMarketsInfo();
    return takeObject(ret);
}

/**
* @param {Asset} base
* @param {Asset} quote
* @returns {Promise<Orderbook | undefined>}
*/
export function getOrderbook(base, quote) {
    _assertClass(base, Asset);
    var ptr0 = base.__destroy_into_raw();
    _assertClass(quote, Asset);
    var ptr1 = quote.__destroy_into_raw();
    const ret = wasm.getOrderbook(ptr0, ptr1);
    return takeObject(ret);
}

/**
* @param {Asset} base
* @param {Asset} quote
* @param {Order} order
* @returns {Promise<string>}
*/
export function createOrder(base, quote, order) {
    _assertClass(base, Asset);
    var ptr0 = base.__destroy_into_raw();
    _assertClass(quote, Asset);
    var ptr1 = quote.__destroy_into_raw();
    _assertClass(order, Order);
    var ptr2 = order.__destroy_into_raw();
    const ret = wasm.createOrder(ptr0, ptr1, ptr2);
    return takeObject(ret);
}

/**
* @param {string} order_id
* @returns {Promise<boolean>}
*/
export function cancelOrder(order_id) {
    const ptr0 = passStringToWasm0(order_id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.cancelOrder(ptr0, len0);
    return takeObject(ret);
}

/**
* @param {Asset} base
* @param {Asset} quote
* @returns {Promise<Map<String, Order>>}
*/
export function getOrders(base, quote) {
    _assertClass(base, Asset);
    var ptr0 = base.__destroy_into_raw();
    _assertClass(quote, Asset);
    var ptr1 = quote.__destroy_into_raw();
    const ret = wasm.getOrders(ptr0, ptr1);
    return takeObject(ret);
}

/**
* @param {Asset} base
* @param {Asset} quote
* @param {string} order_id
* @returns {Promise<Order | undefined>}
*/
export function getOrder(base, quote, order_id) {
    _assertClass(base, Asset);
    var ptr0 = base.__destroy_into_raw();
    _assertClass(quote, Asset);
    var ptr1 = quote.__destroy_into_raw();
    const ptr2 = passStringToWasm0(order_id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len2 = WASM_VECTOR_LEN;
    const ret = wasm.getOrder(ptr0, ptr1, ptr2, len2);
    return takeObject(ret);
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getDataViewMemory0();
    const result = [];
    for (let i = ptr; i < ptr + 4 * len; i += 4) {
        result.push(takeObject(mem.getUint32(i, true)));
    }
    return result;
}

function passArrayJsValueToWasm0(array, malloc) {
    const ptr = malloc(array.length * 4, 4) >>> 0;
    const mem = getDataViewMemory0();
    for (let i = 0; i < array.length; i++) {
        mem.setUint32(ptr + 4 * i, addHeapObject(array[i]), true);
    }
    WASM_VECTOR_LEN = array.length;
    return ptr;
}
/**
* @param {number} ms
* @returns {Promise<void>}
*/
export function sleep_ms(ms) {
    const ret = wasm.sleep_ms(ms);
    return takeObject(ret);
}

/**
* @param {number} ms
*/
export function sleep_blocking_ms(ms) {
    wasm.sleep_blocking_ms(ms);
}

/**
* @param {number} ptr
*/
export function worker_entry_point(ptr) {
    wasm.worker_entry_point(ptr);
}

/**
* @param {number} ptr
* @returns {Promise<void>}
*/
export function async_worker_entry_point(ptr) {
    const ret = wasm.async_worker_entry_point(ptr);
    return takeObject(ret);
}

function getArrayU8FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getUint8ArrayMemory0().subarray(ptr / 1, ptr / 1 + len);
}
function __wbg_adapter_1038(arg0, arg1, arg2, arg3) {
    wasm.wasm_bindgen__convert__closures__invoke2_mut__h3057bf0a8bea131a(arg0, arg1, addHeapObject(arg2), addHeapObject(arg3));
}

/**
*/
export const FiatCurrency = Object.freeze({ USD:0,"0":"USD",EUR:1,"1":"EUR",GBP:2,"2":"GBP",AUD:3,"3":"AUD",CAD:4,"4":"CAD",CHF:5,"5":"CHF",CNY:6,"6":"CNY",JPY:7,"7":"JPY",KRW:8,"8":"KRW",RUB:9,"9":"RUB",TRY:10,"10":"TRY",INR:11,"11":"INR", });
/**
*/
export const OffchainOperationType = Object.freeze({ Send:0,"0":"Send",Receive:1,"1":"Receive",Route:2,"2":"Route", });
/**
*/
export const OffchainProtocol = Object.freeze({ Lightning:0,"0":"Lightning",Lithium:1,"1":"Lithium", });
/**
*/
export const RentalPaymentType = Object.freeze({
/**
* Pay the rental fee using on-chain funds (only native assets)
*/
OnChain:0,"0":"OnChain",
/**
* Pay the rental fee using off-chain funds (any asset)
*/
OffChain:1,"1":"OffChain", });
/**
*/
export const Layer = Object.freeze({ Onchain:0,"0":"Onchain",Offchain:1,"1":"Offchain", });
/**
*/
export const OffchainPaymentType = Object.freeze({ Send:0,"0":"Send",Receive:1,"1":"Receive",Route:2,"2":"Route",Batched:3,"3":"Batched",Private:4,"4":"Private", });
/**
*/
export const OnchainTxStatus = Object.freeze({ Completed:0,"0":"Completed",Pending:1,"1":"Pending",Failed:2,"2":"Failed", });
/**
*/
export const ChannelStatus = Object.freeze({
/**
* The peers agreed on opening the channel and the opening transaction
* isn't on the blockchain yet or it hasn't reached the required
* number of confirmations.
*/
Opening:0,"0":"Opening",
/**
* The channel is being cooperatively settled onchain.
*/
Closing:1,"1":"Closing",
/**
* The channel is being unilaterally settled onchain.
*/
ForceClosing:2,"2":"ForceClosing",
/**
* The dispute period has ended and the funds can be redeemed onchain.
*/
ClosedRedeemable:3,"3":"ClosedRedeemable",
/**
* The counterparty isn't connected.
*/
Inactive:4,"4":"Inactive",
/**
* The channel is ready to be used.
*/
Active:5,"5":"Active", });
/**
*/
export const AssetType = Object.freeze({ Native:0,"0":"Native",Token:1,"1":"Token", });
/**
*/
export const EventType = Object.freeze({ ClientSyncing:0,"0":"ClientSyncing",ClientSynced:1,"1":"ClientSynced",NodeSyncing:2,"2":"NodeSyncing",NodeSynced:3,"3":"NodeSynced",NewBlock:4,"4":"NewBlock",PeerConnected:5,"5":"PeerConnected",PeerDisconnected:6,"6":"PeerDisconnected",BalanceUpdate:7,"7":"BalanceUpdate",TransactionUpdate:8,"8":"TransactionUpdate",TransactionRemoved:9,"9":"TransactionRemoved",PaymentUpdate:10,"10":"PaymentUpdate",ChannelUpdate:11,"11":"ChannelUpdate",ChannelClosed:12,"12":"ChannelClosed",AssetChannelUpdate:13,"13":"AssetChannelUpdate",AssetChannelClosed:14,"14":"AssetChannelClosed",ChannelRentalFailed:15,"15":"ChannelRentalFailed",ChannelRented:16,"16":"ChannelRented",ChannelRentalUpdated:17,"17":"ChannelRentalUpdated", });
/**
*/
export const EvmNetwork = Object.freeze({ Ethereum:1,"1":"Ethereum",Arbitrum:42161,"42161":"Arbitrum",Optimism:10,"10":"Optimism",Bsc:56,"56":"Bsc",Polygon:137,"137":"Polygon",Avalanche:43114,"43114":"Avalanche",Fantom:250,"250":"Fantom",Gnosis:100,"100":"Gnosis",EthereumSepolia:11155111,"11155111":"EthereumSepolia",EthereumHolesky:17000,"17000":"EthereumHolesky",ArbitrumSepolia:421614,"421614":"ArbitrumSepolia",OptimismSepolia:11155420,"11155420":"OptimismSepolia",BscTestnet:97,"97":"BscTestnet",PolygonMumbai:80001,"80001":"PolygonMumbai",AvalancheFuji:43113,"43113":"AvalancheFuji",FantomTestnet:4002,"4002":"FantomTestnet",GnosisChiado:10200,"10200":"GnosisChiado",EthereumLocal:1337,"1337":"EthereumLocal", });
/**
*/
export const AssetChannelStatus = Object.freeze({
/**
* The asset channel is open but not operational
*/
Inactive:0,"0":"Inactive",
/**
* The asset channel is open and operational
*/
Active:1,"1":"Active",
/**
* The channel is in the process of being updated to either deposit or withdraw funds
*/
Updating:2,"2":"Updating",
/**
* The asset channel is in the process of being cooperatively settled
*/
Closing:3,"3":"Closing",
/**
* The asset channel is in the process of being unilaterally settled
*/
ForceClosing:4,"4":"ForceClosing",
/**
* The asset channel is settled and the funds can be withdrawn
*/
ClosedRedeemable:5,"5":"ClosedRedeemable", });
/**
*/
export const AppEventType = Object.freeze({ BalanceUpdate:0,"0":"BalanceUpdate",TransactionUpdate:1,"1":"TransactionUpdate",TransactionRemoved:2,"2":"TransactionRemoved",ChannelUpdate:3,"3":"ChannelUpdate",ChannelClosed:4,"4":"ChannelClosed",AssetChannelUpdate:5,"5":"AssetChannelUpdate",AssetChannelClosed:6,"6":"AssetChannelClosed",ChannelRentalFailed:7,"7":"ChannelRentalFailed",ChannelRented:8,"8":"ChannelRented",ChannelRentalUpdated:9,"9":"ChannelRentalUpdated", });
/**
*/
export const EvmTokenVariant = Object.freeze({ ERC20:0,"0":"ERC20",ERC721:1,"1":"ERC721",ERC1155:2,"2":"ERC1155", });
/**
*/
export const BitcoinNetwork = Object.freeze({ Mainnet:0,"0":"Mainnet",Testnet:1,"1":"Testnet",Signet:2,"2":"Signet",Regtest:3,"3":"Regtest", });
/**
*/
export const LogLevel = Object.freeze({ Error:0,"0":"Error",Warn:1,"1":"Warn",Info:2,"2":"Info",Debug:3,"3":"Debug",Trace:4,"4":"Trace", });
/**
*/
export const DeadlineType = Object.freeze({ Timestamp:0,"0":"Timestamp",BlockNumber:1,"1":"BlockNumber",BlockDelta:2,"2":"BlockDelta", });
/**
*/
export const OrderType = Object.freeze({ AddBuyLiquidity:0,"0":"AddBuyLiquidity",AddSellLiquidity:1,"1":"AddSellLiquidity",MarketBuy:2,"2":"MarketBuy",MarketSell:3,"3":"MarketSell", });
/**
*/
export const OnchainOperationType = Object.freeze({ Send:0,"0":"Send",Receive:1,"1":"Receive",SelfTransfer:2,"2":"SelfTransfer",ChannelOpening:3,"3":"ChannelOpening",ChannelDeposit:4,"4":"ChannelDeposit",ChannelWithdrawal:5,"5":"ChannelWithdrawal",ChannelClosing:6,"6":"ChannelClosing",ChannelSettlement:7,"7":"ChannelSettlement",ChannelSettlementConfirmed:8,"8":"ChannelSettlementConfirmed",SetTokenAllowance:9,"9":"SetTokenAllowance",ContractCall:10,"10":"ContractCall",ContractDeployment:11,"11":"ContractDeployment", });
/**
*/
export const TxType = Object.freeze({ Send:0,"0":"Send",Receive:1,"1":"Receive",SelfTransfer:2,"2":"SelfTransfer",Route:3,"3":"Route",ChannelOpening:4,"4":"ChannelOpening",ChannelDeposit:5,"5":"ChannelDeposit",ChannelWithdrawal:6,"6":"ChannelWithdrawal",ChannelClosing:7,"7":"ChannelClosing",ChannelSettlement:8,"8":"ChannelSettlement",ChannelSettlementConfirmed:9,"9":"ChannelSettlementConfirmed",SetTokenAllowance:10,"10":"SetTokenAllowance",ContractCall:11,"11":"ContractCall",ContractDeployment:12,"12":"ContractDeployment",Batched:13,"13":"Batched",Private:14,"14":"Private", });
/**
*/
export const OperationType = Object.freeze({ Send:0,"0":"Send",Receive:1,"1":"Receive",SelfTransfer:2,"2":"SelfTransfer",Route:3,"3":"Route",ChannelOpening:4,"4":"ChannelOpening",ChannelDeposit:5,"5":"ChannelDeposit",ChannelWithdrawal:6,"6":"ChannelWithdrawal",ChannelClosing:7,"7":"ChannelClosing",ChannelSettlement:8,"8":"ChannelSettlement",ChannelSettlementConfirmed:9,"9":"ChannelSettlementConfirmed",SetTokenAllowance:10,"10":"SetTokenAllowance",ContractCall:11,"11":"ContractCall",ContractDeployment:12,"12":"ContractDeployment", });
/**
*/
export const TxStatus = Object.freeze({ Completed:0,"0":"Completed",Pending:1,"1":"Pending",Failed:2,"2":"Failed",Expired:3,"3":"Expired", });
/**
*/
export const OnchainTxType = Object.freeze({ Send:0,"0":"Send",Receive:1,"1":"Receive",SelfTransfer:2,"2":"SelfTransfer",ChannelOpening:3,"3":"ChannelOpening",ChannelDeposit:4,"4":"ChannelDeposit",ChannelWithdrawal:5,"5":"ChannelWithdrawal",ChannelClosing:6,"6":"ChannelClosing",ChannelSettlement:7,"7":"ChannelSettlement",ChannelSettlementConfirmed:8,"8":"ChannelSettlementConfirmed",SetTokenAllowance:9,"9":"SetTokenAllowance",ContractCall:10,"10":"ContractCall",ContractDeployment:11,"11":"ContractDeployment",Batched:12,"12":"Batched",Private:13,"13":"Private", });
/**
*/
export const OffchainPaymentStatus = Object.freeze({ Completed:0,"0":"Completed",Pending:1,"1":"Pending",Failed:2,"2":"Failed",Expired:3,"3":"Expired", });
/**
*/
export const Protocol = Object.freeze({ Bitcoin:0,"0":"Bitcoin",Evm:1,"1":"Evm", });

const AllowanceAmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_allowanceamount_free(ptr >>> 0, 1));
/**
*/
export class AllowanceAmount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AllowanceAmount.prototype);
        obj.__wbg_ptr = ptr;
        AllowanceAmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AllowanceAmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_allowanceamount_free(ptr, 0);
    }
    /**
    * @returns {AllowanceAmount}
    */
    static unlimited() {
        const ret = wasm.allowanceamount_unlimited();
        return AllowanceAmount.__wrap(ret);
    }
    /**
    * @param {Amount} amount
    * @returns {AllowanceAmount}
    */
    static exact(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.allowanceamount_exact(amount.__wbg_ptr);
        return AllowanceAmount.__wrap(ret);
    }
    /**
    * @returns {AllowanceAmount}
    */
    static zero() {
        const ret = wasm.allowanceamount_zero();
        return AllowanceAmount.__wrap(ret);
    }
    /**
    * @param {number} value
    * @returns {AllowanceAmount}
    */
    static fromFloat(value) {
        const ret = wasm.allowanceamount_fromFloat(value);
        return AllowanceAmount.__wrap(ret);
    }
    /**
    * @param {U256} value
    * @param {number} decimals
    * @returns {AllowanceAmount}
    */
    static fromCommonDenomination(value, decimals) {
        _assertClass(value, U256);
        const ret = wasm.allowanceamount_fromCommonDenomination(value.__wbg_ptr, decimals);
        return AllowanceAmount.__wrap(ret);
    }
}

const AmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_amount_free(ptr >>> 0, 1));
/**
*/
export class Amount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Amount.prototype);
        obj.__wbg_ptr = ptr;
        AmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_amount_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    static zero() {
        const ret = wasm.amount_zero();
        return Amount.__wrap(ret);
    }
    /**
    * @param {number} value
    * @returns {Amount}
    */
    static fromFloat(value) {
        const ret = wasm.amount_fromFloat(value);
        return Amount.__wrap(ret);
    }
    /**
    * @param {U256} value
    * @param {number} decimals
    * @returns {Amount}
    */
    static fromCommonDenomination(value, decimals) {
        _assertClass(value, U256);
        const ret = wasm.amount_fromCommonDenomination(value.__wbg_ptr, decimals);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    asFloat() {
        const ret = wasm.amount_asFloat(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} decimals
    * @returns {U256}
    */
    asCommonDenomination(decimals) {
        const ret = wasm.amount_asCommonDenomination(this.__wbg_ptr, decimals);
        return U256.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.amount_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {Amount}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.amount_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return Amount.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const AppEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_appevent_free(ptr >>> 0, 1));
/**
*/
export class AppEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AppEvent.prototype);
        obj.__wbg_ptr = ptr;
        AppEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AppEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_appevent_free(ptr, 0);
    }
    /**
    * @returns {AppEventType}
    */
    get eventType() {
        const ret = wasm.appevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {AssetBalance | undefined}
    */
    get assetBalance() {
        const ret = wasm.appevent_assetBalance(this.__wbg_ptr);
        return ret === 0 ? undefined : AssetBalance.__wrap(ret);
    }
    /**
    * @returns {Transaction | undefined}
    */
    get transaction() {
        const ret = wasm.appevent_transaction(this.__wbg_ptr);
        return ret === 0 ? undefined : Transaction.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get transactionId() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.appevent_transactionId(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Channel | undefined}
    */
    get channel() {
        const ret = wasm.appevent_channel(this.__wbg_ptr);
        return ret === 0 ? undefined : Channel.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get channelId() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.appevent_channelId(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Asset | undefined}
    */
    get asset() {
        const ret = wasm.appevent_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : Asset.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get assetId() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.appevent_assetId(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {AssetChannel | undefined}
    */
    get assetChannel() {
        const ret = wasm.appevent_assetChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : AssetChannel.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get error() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.appevent_error(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const AssetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_asset_free(ptr >>> 0, 1));
/**
*/
export class Asset {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Asset.prototype);
        obj.__wbg_ptr = ptr;
        AssetFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof Asset)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_asset_free(ptr, 0);
    }
    /**
    * @returns {Network}
    */
    get network() {
        const ret = wasm.asset_network(this.__wbg_ptr);
        return Network.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.asset_name(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.asset_symbol(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {number}
    */
    get decimals() {
        const ret = wasm.asset_decimals(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {string | undefined}
    */
    get logoUrl() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.asset_logoUrl(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Protocol}
    */
    get type() {
        const ret = wasm.asset_type(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {BitcoinAsset | undefined}
    */
    asBitcoin() {
        const ret = wasm.asset_asBitcoin(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAsset.__wrap(ret);
    }
    /**
    * @returns {EvmAsset | undefined}
    */
    asEvm() {
        const ret = wasm.asset_asEvm(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAsset.__wrap(ret);
    }
}

const AssetBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_assetbalance_free(ptr >>> 0, 1));
/**
*/
export class AssetBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AssetBalance.prototype);
        obj.__wbg_ptr = ptr;
        AssetBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_assetbalance_free(ptr, 0);
    }
    /**
    * @returns {Asset}
    */
    get asset() {
        const ret = wasm.__wbg_get_assetbalance_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
    * @param {Asset} arg0
    */
    set asset(arg0) {
        _assertClass(arg0, Asset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {OnchainBalance}
    */
    get onchain_balance() {
        const ret = wasm.__wbg_get_assetbalance_onchain_balance(this.__wbg_ptr);
        return OnchainBalance.__wrap(ret);
    }
    /**
    * @param {OnchainBalance} arg0
    */
    set onchain_balance(arg0) {
        _assertClass(arg0, OnchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_onchain_balance(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {OffchainBalance}
    */
    get offchain_balance() {
        const ret = wasm.__wbg_get_assetbalance_offchain_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
    * @param {OffchainBalance} arg0
    */
    set offchain_balance(arg0) {
        _assertClass(arg0, OffchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_offchain_balance(this.__wbg_ptr, ptr0);
    }
}

const AssetChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_assetchannel_free(ptr >>> 0, 1));
/**
*/
export class AssetChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AssetChannel.prototype);
        obj.__wbg_ptr = ptr;
        AssetChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_assetchannel_free(ptr, 0);
    }
    /**
    * The asset of the asset channel
    * @returns {Asset}
    */
    get asset() {
        const ret = wasm.assetchannel_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
    * The status of the asset channel
    * @returns {AssetChannelStatus}
    */
    get status() {
        const ret = wasm.assetchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * The balance of the asset channel
    * @returns {OffchainBalance}
    */
    get balance() {
        const ret = wasm.assetchannel_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
    * The txid of the last onchain operation
    * @returns {string | undefined}
    */
    get lastOnchainTxid() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.assetchannel_lastOnchainTxid(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * The timestamp of the last operation
    * @returns {bigint}
    */
    get lastOperationTimestampSecs() {
        const ret = wasm.assetchannel_lastOperationTimestampSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * The timestamp of the rental expiry
    * @returns {bigint | undefined}
    */
    get rentalExpiryTimestampSecs() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.assetchannel_rentalExpiryTimestampSecs(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const AssetChannelAmountsFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_assetchannelamounts_free(ptr >>> 0, 1));
/**
*/
export class AssetChannelAmounts {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AssetChannelAmounts.prototype);
        obj.__wbg_ptr = ptr;
        AssetChannelAmountsFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof AssetChannelAmounts)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetChannelAmountsFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_assetchannelamounts_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get selfAmount() {
        const ret = wasm.assetchannelamounts_selfAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {Amount}
    */
    get counterpartyAmount() {
        const ret = wasm.assetchannelamounts_counterpartyAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const BitcoinAddressFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinaddress_free(ptr >>> 0, 1));
/**
*/
export class BitcoinAddress {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAddress.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAddressFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAddressFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinaddress_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinaddress_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {BitcoinAddress}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.bitcoinaddress_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return BitcoinAddress.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinAssetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinasset_free(ptr >>> 0, 1));
/**
*/
export class BitcoinAsset {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAsset.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAssetFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof BitcoinAsset)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAssetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinasset_free(ptr, 0);
    }
    /**
    * @returns {BitcoinAssetId}
    */
    get id() {
        const ret = wasm.bitcoinasset_id(this.__wbg_ptr);
        return BitcoinAssetId.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinasset_name(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinasset_symbol(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {number}
    */
    get decimals() {
        const ret = wasm.bitcoinasset_decimals(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {string | undefined}
    */
    get logoUrl() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinasset_logoUrl(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {AssetType}
    */
    get assetType() {
        const ret = wasm.bitcoinasset_assetType(this.__wbg_ptr);
        return ret;
    }
}

const BitcoinAssetBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinassetbalance_free(ptr >>> 0, 1));
/**
*/
export class BitcoinAssetBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAssetBalance.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAssetBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAssetBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinassetbalance_free(ptr, 0);
    }
    /**
    * @returns {BitcoinAsset}
    */
    get asset() {
        const ret = wasm.__wbg_get_bitcoinassetbalance_asset(this.__wbg_ptr);
        return BitcoinAsset.__wrap(ret);
    }
    /**
    * @param {BitcoinAsset} arg0
    */
    set asset(arg0) {
        _assertClass(arg0, BitcoinAsset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_bitcoinassetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {OnchainBalance}
    */
    get onchain_balance() {
        const ret = wasm.__wbg_get_bitcoinassetbalance_onchain_balance(this.__wbg_ptr);
        return OnchainBalance.__wrap(ret);
    }
    /**
    * @param {OnchainBalance} arg0
    */
    set onchain_balance(arg0) {
        _assertClass(arg0, OnchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_bitcoinassetbalance_onchain_balance(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {OffchainBalance}
    */
    get offchain_balance() {
        const ret = wasm.__wbg_get_bitcoinassetbalance_offchain_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
    * @param {OffchainBalance} arg0
    */
    set offchain_balance(arg0) {
        _assertClass(arg0, OffchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_bitcoinassetbalance_offchain_balance(this.__wbg_ptr, ptr0);
    }
}

const BitcoinAssetIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinassetid_free(ptr >>> 0, 1));
/**
*/
export class BitcoinAssetId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAssetId.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAssetIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAssetIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinassetid_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinassetid_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {BitcoinAssetId}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.bitcoinassetid_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return BitcoinAssetId.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinBlockHashFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinblockhash_free(ptr >>> 0, 1));
/**
*/
export class BitcoinBlockHash {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinBlockHash.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinBlockHashFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinBlockHashFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinblockhash_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinblockhash_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {BitcoinBlockHash}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.bitcoinblockhash_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return BitcoinBlockHash.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinBlockHeaderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinblockheader_free(ptr >>> 0, 1));
/**
*/
export class BitcoinBlockHeader {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinBlockHeader.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinBlockHeaderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinBlockHeaderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinblockheader_free(ptr, 0);
    }
    /**
    * @returns {bigint}
    */
    get number() {
        const ret = wasm.bitcoinblockheader_number(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {bigint}
    */
    get timestamp() {
        const ret = wasm.bitcoinblockheader_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {BitcoinBlockHash}
    */
    get hash() {
        const ret = wasm.bitcoinblockheader_hash(this.__wbg_ptr);
        return BitcoinBlockHash.__wrap(ret);
    }
}

const BitcoinBlockchainConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinblockchainconfig_free(ptr >>> 0, 1));
/**
*/
export class BitcoinBlockchainConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinBlockchainConfig.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinBlockchainConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinBlockchainConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinblockchainconfig_free(ptr, 0);
    }
    /**
    * @param {string} esplora_url
    * @returns {BitcoinBlockchainConfig}
    */
    static newEsplora(esplora_url) {
        const ptr0 = passStringToWasm0(esplora_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinblockchainconfig_newEsplora(ptr0, len0);
        return BitcoinBlockchainConfig.__wrap(ret);
    }
}

const BitcoinConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinconfig_free(ptr >>> 0, 1));
/**
*/
export class BitcoinConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinConfig.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinconfig_free(ptr, 0);
    }
    /**
    * @param {BitcoinBlockchainConfig} blockchain_config
    * @param {BitcoinWalletConfig} wallet_config
    * @returns {BitcoinConfig}
    */
    static newWatchOnlyClient(blockchain_config, wallet_config) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(wallet_config, BitcoinWalletConfig);
        const ret = wasm.bitcoinconfig_newWatchOnlyClient(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
    * @param {BitcoinBlockchainConfig} blockchain_config
    * @param {BitcoinSignerConfig} signer_config
    * @returns {BitcoinConfig}
    */
    static newClient(blockchain_config, signer_config) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(signer_config, BitcoinSignerConfig);
        const ret = wasm.bitcoinconfig_newClient(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
    * @param {BitcoinBlockchainConfig} blockchain_config
    * @param {BitcoinWalletConfig} wallet_config
    * @param {BitcoinPublicKey} node_id
    * @returns {BitcoinConfig}
    */
    static newWatchOnlyNode(blockchain_config, wallet_config, node_id) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(wallet_config, BitcoinWalletConfig);
        _assertClass(node_id, BitcoinPublicKey);
        const ret = wasm.bitcoinconfig_newWatchOnlyNode(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr, node_id.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
    * @param {BitcoinBlockchainConfig} blockchain_config
    * @param {BitcoinSignerConfig} signer_config
    * @param {BitcoinPublicKey} node_id
    * @returns {BitcoinConfig}
    */
    static newWatchOnlyNodeWithSigner(blockchain_config, signer_config, node_id) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(signer_config, BitcoinSignerConfig);
        _assertClass(node_id, BitcoinPublicKey);
        const ret = wasm.bitcoinconfig_newWatchOnlyNodeWithSigner(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_id.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
    * @param {BitcoinBlockchainConfig} blockchain_config
    * @param {BitcoinSignerConfig} signer_config
    * @param {BitcoinLightningNodeConfig} node_config
    * @returns {BitcoinConfig}
    */
    static newNode(blockchain_config, signer_config, node_config) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(signer_config, BitcoinSignerConfig);
        _assertClass(node_config, BitcoinLightningNodeConfig);
        const ret = wasm.bitcoinconfig_newNode(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_config.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
}

const BitcoinEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinevent_free(ptr >>> 0, 1));
/**
*/
export class BitcoinEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinEvent.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinevent_free(ptr, 0);
    }
    /**
    * @returns {BitcoinNetwork}
    */
    get network() {
        const ret = wasm.bitcoinevent_network(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {EventType}
    */
    get eventType() {
        const ret = wasm.bitcoinevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {BitcoinBlockHeader | undefined}
    */
    get blockHeader() {
        const ret = wasm.bitcoinevent_blockHeader(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinBlockHeader.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey | undefined}
    */
    get peerId() {
        const ret = wasm.bitcoinevent_peerId(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {BitcoinAssetBalance | undefined}
    */
    get balanceUpdate() {
        const ret = wasm.bitcoinevent_balanceUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAssetBalance.__wrap(ret);
    }
    /**
    * @returns {BitcoinTransaction | undefined}
    */
    get transactionUpdate() {
        const ret = wasm.bitcoinevent_transactionUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTransaction.__wrap(ret);
    }
    /**
    * @returns {BitcoinTxid | undefined}
    */
    get transactionRemoved() {
        const ret = wasm.bitcoinevent_transactionRemoved(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTxid.__wrap(ret);
    }
    /**
    * @returns {BitcoinLightningChannel | undefined}
    */
    get channelUpdate() {
        const ret = wasm.bitcoinevent_channelUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningChannel.__wrap(ret);
    }
    /**
    * @returns {LightningChannelId | undefined}
    */
    get channelId() {
        const ret = wasm.bitcoinevent_channelId(this.__wbg_ptr);
        return ret === 0 ? undefined : LightningChannelId.__wrap(ret);
    }
    /**
    * @returns {BitcoinTxid | undefined}
    */
    get txid() {
        const ret = wasm.bitcoinevent_txid(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTxid.__wrap(ret);
    }
    /**
    * @returns {BitcoinAsset | undefined}
    */
    get asset() {
        const ret = wasm.bitcoinevent_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAsset.__wrap(ret);
    }
    /**
    * @returns {BitcoinAssetId | undefined}
    */
    get assetId() {
        const ret = wasm.bitcoinevent_assetId(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAssetId.__wrap(ret);
    }
    /**
    * @returns {BitcoinLightningAssetChannel | undefined}
    */
    get assetChannel() {
        const ret = wasm.bitcoinevent_assetChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningAssetChannel.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get error() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinevent_error(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinFeeFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinfee_free(ptr >>> 0, 1));
/**
*/
export class BitcoinFee {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinFee.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinFeeFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinFeeFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinfee_free(ptr, 0);
    }
    /**
    * @param {bigint} sat_per_kwu
    */
    constructor(sat_per_kwu) {
        const ret = wasm.bitcoinfee_new(sat_per_kwu);
        this.__wbg_ptr = ret >>> 0;
        BitcoinFeeFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
    * @returns {bigint}
    */
    get satPerKwu() {
        const ret = wasm.bitcoinfee_satPerKwu(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
}

const BitcoinFeeEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinfeeestimate_free(ptr >>> 0, 1));
/**
*/
export class BitcoinFeeEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinFeeEstimate.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinFeeEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinFeeEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinfeeestimate_free(ptr, 0);
    }
    /**
    * @returns {BitcoinFee}
    */
    get low() {
        const ret = wasm.bitcoinfeeestimate_low(this.__wbg_ptr);
        return BitcoinFee.__wrap(ret);
    }
    /**
    * @returns {BitcoinFee}
    */
    get medium() {
        const ret = wasm.bitcoinfeeestimate_medium(this.__wbg_ptr);
        return BitcoinFee.__wrap(ret);
    }
    /**
    * @returns {BitcoinFee}
    */
    get high() {
        const ret = wasm.bitcoinfeeestimate_high(this.__wbg_ptr);
        return BitcoinFee.__wrap(ret);
    }
}

const BitcoinLightningAssetChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningassetchannel_free(ptr >>> 0, 1));
/**
*/
export class BitcoinLightningAssetChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningAssetChannel.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningAssetChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningAssetChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningassetchannel_free(ptr, 0);
    }
    /**
    * @returns {BitcoinAsset}
    */
    get asset() {
        const ret = wasm.bitcoinlightningassetchannel_asset(this.__wbg_ptr);
        return BitcoinAsset.__wrap(ret);
    }
    /**
    * @returns {AssetChannelStatus}
    */
    get status() {
        const ret = wasm.bitcoinlightningassetchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {OffchainBalance}
    */
    get balance() {
        const ret = wasm.bitcoinlightningassetchannel_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
    * @returns {BitcoinTxid | undefined}
    */
    get lastOnchainTxid() {
        const ret = wasm.bitcoinlightningassetchannel_lastOnchainTxid(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTxid.__wrap(ret);
    }
    /**
    * @returns {bigint}
    */
    get lastOperationTimestampSecs() {
        const ret = wasm.bitcoinlightningassetchannel_lastOperationTimestampSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {bigint | undefined}
    */
    get rentalExpiryTimestampSecs() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinlightningassetchannel_rentalExpiryTimestampSecs(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinLightningChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningchannel_free(ptr >>> 0, 1));
/**
*/
export class BitcoinLightningChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningChannel.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningchannel_free(ptr, 0);
    }
    /**
    * @returns {LightningChannelId}
    */
    get id() {
        const ret = wasm.bitcoinlightningchannel_id(this.__wbg_ptr);
        return LightningChannelId.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey}
    */
    get counterparty() {
        const ret = wasm.bitcoinlightningchannel_counterparty(this.__wbg_ptr);
        return BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {bigint}
    */
    get lastOperationConfirmations() {
        const ret = wasm.bitcoinlightningchannel_lastOperationConfirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {ChannelStatus}
    */
    get status() {
        const ret = wasm.bitcoinlightningchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {(BitcoinLightningAssetChannel)[]}
    */
    get assetChannels() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinlightningchannel_assetChannels(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinLightningInvoiceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightninginvoice_free(ptr >>> 0, 1));
/**
*/
export class BitcoinLightningInvoice {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningInvoice.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningInvoiceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningInvoiceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightninginvoice_free(ptr, 0);
    }
    /**
    * @returns {BitcoinNetwork}
    */
    get network() {
        const ret = wasm.bitcoinlightninginvoice_network(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {PaymentHash}
    */
    get paymentHash() {
        const ret = wasm.bitcoinlightninginvoice_paymentHash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
    /**
    * @returns {BitcoinAssetId}
    */
    get assetId() {
        const ret = wasm.bitcoinlightninginvoice_assetId(this.__wbg_ptr);
        return BitcoinAssetId.__wrap(ret);
    }
    /**
    * @returns {U256 | undefined}
    */
    get amount() {
        const ret = wasm.bitcoinlightninginvoice_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : U256.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey}
    */
    get recipient() {
        const ret = wasm.bitcoinlightninginvoice_recipient(this.__wbg_ptr);
        return BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {bigint | undefined}
    */
    get expiryTimestamp() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinlightninginvoice_expiryTimestamp(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinlightninginvoice_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {BitcoinLightningInvoice}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.bitcoinlightninginvoice_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return BitcoinLightningInvoice.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinLightningNodeConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningnodeconfig_free(ptr >>> 0, 1));
/**
*/
export class BitcoinLightningNodeConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningNodeConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningnodeconfig_free(ptr, 0);
    }
    /**
    * @param {string} lightning_gossip_sync_url
    * @param {string} websocket_proxy_url
    */
    constructor(lightning_gossip_sync_url, websocket_proxy_url) {
        const ptr0 = passStringToWasm0(lightning_gossip_sync_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ptr1 = passStringToWasm0(websocket_proxy_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinlightningnodeconfig_new(ptr0, len0, ptr1, len1);
        this.__wbg_ptr = ret >>> 0;
        BitcoinLightningNodeConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const BitcoinLightningPaymentFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningpayment_free(ptr >>> 0, 1));
/**
*/
export class BitcoinLightningPayment {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningPayment.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningPaymentFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningPaymentFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningpayment_free(ptr, 0);
    }
    /**
    * @returns {LightningPaymentId}
    */
    get id() {
        const ret = wasm.bitcoinlightningpayment_id(this.__wbg_ptr);
        return LightningPaymentId.__wrap(ret);
    }
    /**
    * @returns {PaymentHash | undefined}
    */
    get hash() {
        const ret = wasm.bitcoinlightningpayment_hash(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentHash.__wrap(ret);
    }
    /**
    * @returns {PaymentPreimage | undefined}
    */
    get preimage() {
        const ret = wasm.bitcoinlightningpayment_preimage(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentPreimage.__wrap(ret);
    }
    /**
    * @returns {OffchainPaymentStatus}
    */
    get status() {
        const ret = wasm.bitcoinlightningpayment_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {OffchainPaymentType}
    */
    get paymentType() {
        const ret = wasm.bitcoinlightningpayment_paymentType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {bigint}
    */
    get timestamp() {
        const ret = wasm.bitcoinlightningpayment_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {(BitcoinOffchainOperation)[]}
    */
    get operations() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinlightningpayment_operations(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Map<BitcoinAsset, Amount>}
    */
    get spent() {
        const ret = wasm.bitcoinlightningpayment_spent(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<BitcoinAsset, Amount>}
    */
    get received() {
        const ret = wasm.bitcoinlightningpayment_received(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<BitcoinAsset, Amount>}
    */
    get balanceChanges() {
        const ret = wasm.bitcoinlightningpayment_balanceChanges(this.__wbg_ptr);
        return takeObject(ret);
    }
}

const BitcoinLightningPeerFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningpeer_free(ptr >>> 0, 1));
/**
*/
export class BitcoinLightningPeer {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningPeer.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningPeerFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningPeerFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningpeer_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinlightningpeer_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {BitcoinLightningPeer}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.bitcoinlightningpeer_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return BitcoinLightningPeer.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinOffchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinoffchainoperation_free(ptr >>> 0, 1));
/**
*/
export class BitcoinOffchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinOffchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinOffchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinOffchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinoffchainoperation_free(ptr, 0);
    }
    /**
    * @returns {OffchainOperationType}
    */
    get operationType() {
        const ret = wasm.bitcoinoffchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {BitcoinAsset}
    */
    get asset() {
        const ret = wasm.bitcoinoffchainoperation_asset(this.__wbg_ptr);
        return BitcoinAsset.__wrap(ret);
    }
    /**
    * @returns {Amount}
    */
    get amount() {
        const ret = wasm.bitcoinoffchainoperation_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey | undefined}
    */
    get from() {
        const ret = wasm.bitcoinoffchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey | undefined}
    */
    get to() {
        const ret = wasm.bitcoinoffchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey | undefined}
    */
    get routedFrom() {
        const ret = wasm.bitcoinoffchainoperation_routedFrom(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey | undefined}
    */
    get routedTo() {
        const ret = wasm.bitcoinoffchainoperation_routedTo(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {Amount | undefined}
    */
    get earnedFee() {
        const ret = wasm.bitcoinoffchainoperation_earnedFee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
}

const BitcoinOnchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinonchainoperation_free(ptr >>> 0, 1));
/**
*/
export class BitcoinOnchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinOnchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinOnchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinOnchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinonchainoperation_free(ptr, 0);
    }
    /**
    * @returns {OnchainOperationType}
    */
    get operationType() {
        const ret = wasm.bitcoinonchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {BitcoinAsset | undefined}
    */
    get asset() {
        const ret = wasm.bitcoinonchainoperation_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAsset.__wrap(ret);
    }
    /**
    * @returns {Amount | undefined}
    */
    get amount() {
        const ret = wasm.bitcoinonchainoperation_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
    * @returns {BitcoinAddress | undefined}
    */
    get to() {
        const ret = wasm.bitcoinonchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAddress.__wrap(ret);
    }
    /**
    * @returns {BitcoinAddress | undefined}
    */
    get from() {
        const ret = wasm.bitcoinonchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAddress.__wrap(ret);
    }
    /**
    * @returns {LightningChannelId | undefined}
    */
    get channel_id() {
        const ret = wasm.bitcoinonchainoperation_channel_id(this.__wbg_ptr);
        return ret === 0 ? undefined : LightningChannelId.__wrap(ret);
    }
    /**
    * @returns {BitcoinPublicKey | undefined}
    */
    get counterparty() {
        const ret = wasm.bitcoinonchainoperation_counterparty(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
    * @returns {Map<BitcoinAsset, AssetChannelAmounts> | undefined}
    */
    get assetChannelAmounts() {
        const ret = wasm.bitcoinonchainoperation_assetChannelAmounts(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Deadline | undefined}
    */
    get disputeDeadline() {
        const ret = wasm.bitcoinonchainoperation_disputeDeadline(this.__wbg_ptr);
        return ret === 0 ? undefined : Deadline.__wrap(ret);
    }
}

const BitcoinPublicKeyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinpublickey_free(ptr >>> 0, 1));
/**
*/
export class BitcoinPublicKey {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinPublicKey.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinPublicKeyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinPublicKeyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinpublickey_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcoinpublickey_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {BitcoinPublicKey}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.bitcoinpublickey_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return BitcoinPublicKey.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinSignedTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinsignedtransactionrequest_free(ptr >>> 0, 1));
/**
*/
export class BitcoinSignedTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinSignedTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinSignedTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinSignedTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinsignedtransactionrequest_free(ptr, 0);
    }
}

const BitcoinSignerConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinsignerconfig_free(ptr >>> 0, 1));
/**
*/
export class BitcoinSignerConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinSignerConfig.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinSignerConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinSignerConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinsignerconfig_free(ptr, 0);
    }
    /**
    * @param {Mnemonic} mnemonic
    * @param {string} password
    * @returns {BitcoinSignerConfig}
    */
    static newHot(mnemonic, password) {
        _assertClass(mnemonic, Mnemonic);
        const ptr0 = passStringToWasm0(password, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinsignerconfig_newHot(mnemonic.__wbg_ptr, ptr0, len0);
        return BitcoinSignerConfig.__wrap(ret);
    }
}

const BitcoinTransactionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcointransaction_free(ptr >>> 0, 1));
/**
*/
export class BitcoinTransaction {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinTransaction.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinTransactionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinTransactionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcointransaction_free(ptr, 0);
    }
    /**
    * @returns {BitcoinTxid}
    */
    get id() {
        const ret = wasm.bitcointransaction_id(this.__wbg_ptr);
        return BitcoinTxid.__wrap(ret);
    }
    /**
    * @returns {OnchainTxStatus}
    */
    get status() {
        const ret = wasm.bitcointransaction_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {bigint | undefined}
    */
    get blockHeight() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcointransaction_blockHeight(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint | undefined}
    */
    get timestamp() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcointransaction_timestamp(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint}
    */
    get confirmations() {
        const ret = wasm.bitcointransaction_confirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {Amount}
    */
    get fee() {
        const ret = wasm.bitcointransaction_fee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {Map<BitcoinAsset, Amount>}
    */
    get spent() {
        const ret = wasm.bitcointransaction_spent(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<BitcoinAsset, Amount>}
    */
    get received() {
        const ret = wasm.bitcointransaction_received(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {(BitcoinOnchainOperation)[]}
    */
    get operations() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcointransaction_operations(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Map<BitcoinAsset, Amount>}
    */
    get balanceChanges() {
        const ret = wasm.bitcointransaction_balanceChanges(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {OnchainTxType}
    */
    get transactionType() {
        const ret = wasm.bitcointransaction_transactionType(this.__wbg_ptr);
        return ret;
    }
}

const BitcoinTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcointransactionrequest_free(ptr >>> 0, 1));
/**
*/
export class BitcoinTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcointransactionrequest_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get fee() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcointransactionrequest_fee(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return Amount.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinTxidFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcointxid_free(ptr >>> 0, 1));
/**
*/
export class BitcoinTxid {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinTxid.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinTxidFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinTxidFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcointxid_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.bitcointxid_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {BitcoinTxid}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.bitcointxid_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return BitcoinTxid.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const BitcoinWalletConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinwalletconfig_free(ptr >>> 0, 1));
/**
*/
export class BitcoinWalletConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinWalletConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinwalletconfig_free(ptr, 0);
    }
    /**
    * @param {ExtendedPubKey} xpub
    * @param {Fingerprint} master_fingerprint
    */
    constructor(xpub, master_fingerprint) {
        _assertClass(xpub, ExtendedPubKey);
        _assertClass(master_fingerprint, Fingerprint);
        const ret = wasm.bitcoinwalletconfig_new(xpub.__wbg_ptr, master_fingerprint.__wbg_ptr);
        this.__wbg_ptr = ret >>> 0;
        BitcoinWalletConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const ChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_channel_free(ptr >>> 0, 1));
/**
*/
export class Channel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Channel.prototype);
        obj.__wbg_ptr = ptr;
        ChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_channel_free(ptr, 0);
    }
    /**
    * The protocol of the channel
    * @returns {Protocol}
    */
    get protocol() {
        const ret = wasm.channel_offchainProtocol(this.__wbg_ptr);
        return ret;
    }
    /**
    * The offchain protocol of the channel
    * @returns {OffchainProtocol}
    */
    get offchainProtocol() {
        const ret = wasm.channel_offchainProtocol(this.__wbg_ptr);
        return ret;
    }
    /**
    * The network of the channel
    * @returns {Network}
    */
    get network() {
        const ret = wasm.channel_network(this.__wbg_ptr);
        return Network.__wrap(ret);
    }
    /**
    * The id of the channel
    * @returns {string}
    */
    get id() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.channel_id(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * The counterparty of the channel
    * @returns {string}
    */
    get counterparty() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.channel_counterparty(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * The number of confirmations since the last onchain operation
    * @returns {bigint}
    */
    get lastOperationConfirmations() {
        const ret = wasm.channel_lastOperationConfirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * The status of the channel
    * @returns {ChannelStatus}
    */
    get status() {
        const ret = wasm.channel_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * The asset channels of the channel
    * @returns {(AssetChannel)[]}
    */
    get assetChannels() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.channel_assetChannels(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {BitcoinLightningChannel | undefined}
    */
    asBitcoinLightningChannel() {
        const ret = wasm.channel_asBitcoinLightningChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningChannel.__wrap(ret);
    }
    /**
    * @returns {EvmLithiumChannel | undefined}
    */
    asEvmLithiumChannel() {
        const ret = wasm.channel_asEvmLithiumChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumChannel.__wrap(ret);
    }
}

const ConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_config_free(ptr >>> 0, 1));
/**
*/
export class Config {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_config_free(ptr, 0);
    }
    /**
    * @param {string} db_name
    * @param {string | undefined} cmc_url
    * @param {string | undefined} cmc_api_key
    * @param {Array<FiatCurrency> | undefined} fiat_currencies
    * @param {string | undefined} rental_url
    * @param {string | undefined} orderbook_url
    * @param {LogLevel} log_level
    */
    constructor(db_name, cmc_url, cmc_api_key, fiat_currencies, rental_url, orderbook_url, log_level) {
        const ptr0 = passStringToWasm0(db_name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        var ptr1 = isLikeNone(cmc_url) ? 0 : passStringToWasm0(cmc_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        var ptr2 = isLikeNone(cmc_api_key) ? 0 : passStringToWasm0(cmc_api_key, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len2 = WASM_VECTOR_LEN;
        var ptr3 = isLikeNone(rental_url) ? 0 : passStringToWasm0(rental_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len3 = WASM_VECTOR_LEN;
        var ptr4 = isLikeNone(orderbook_url) ? 0 : passStringToWasm0(orderbook_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len4 = WASM_VECTOR_LEN;
        const ret = wasm.config_new(ptr0, len0, ptr1, len1, ptr2, len2, isLikeNone(fiat_currencies) ? 0 : addHeapObject(fiat_currencies), ptr3, len3, ptr4, len4, log_level);
        this.__wbg_ptr = ret >>> 0;
        ConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const CurrencyInfoFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_currencyinfo_free(ptr >>> 0, 1));
/**
*/
export class CurrencyInfo {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(CurrencyInfo.prototype);
        obj.__wbg_ptr = ptr;
        CurrencyInfoFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CurrencyInfoFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_currencyinfo_free(ptr, 0);
    }
    /**
    * @returns {Protocol}
    */
    get protocol() {
        const ret = wasm.currencyinfo_protocol(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {string}
    */
    get networkId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.currencyinfo_networkId(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get assetId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.currencyinfo_assetId(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const DeadlineFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_deadline_free(ptr >>> 0, 1));
/**
*/
export class Deadline {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Deadline.prototype);
        obj.__wbg_ptr = ptr;
        DeadlineFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        DeadlineFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_deadline_free(ptr, 0);
    }
    /**
    * @returns {DeadlineType}
    */
    get eventType() {
        const ret = wasm.deadline_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {bigint | undefined}
    */
    get timestampSecs() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.deadline_timestampSecs(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint | undefined}
    */
    get blockNumber() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.deadline_blockNumber(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint | undefined}
    */
    get blockDelta() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.deadline_blockDelta(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmAddressFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmaddress_free(ptr >>> 0, 1));
/**
*/
export class EvmAddress {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmAddress.prototype);
        obj.__wbg_ptr = ptr;
        EvmAddressFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmAddressFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmaddress_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmaddress_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {EvmAddress}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.evmaddress_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return EvmAddress.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmAssetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmasset_free(ptr >>> 0, 1));
/**
*/
export class EvmAsset {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmAsset.prototype);
        obj.__wbg_ptr = ptr;
        EvmAssetFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof EvmAsset)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmAssetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmasset_free(ptr, 0);
    }
    /**
    * @returns {EvmAddress}
    */
    get id() {
        const ret = wasm.evmasset_id(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmasset_name(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmasset_symbol(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {number}
    */
    get decimals() {
        const ret = wasm.evmasset_decimals(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {string | undefined}
    */
    get logoUrl() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmasset_logoUrl(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {AssetType}
    */
    get assetType() {
        const ret = wasm.evmasset_assetType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {EvmToken | undefined}
    */
    get token() {
        const ret = wasm.evmasset_token(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmToken.__wrap(ret);
    }
}

const EvmAssetBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmassetbalance_free(ptr >>> 0, 1));
/**
*/
export class EvmAssetBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmAssetBalance.prototype);
        obj.__wbg_ptr = ptr;
        EvmAssetBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmAssetBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmassetbalance_free(ptr, 0);
    }
    /**
    * @returns {EvmAsset}
    */
    get asset() {
        const ret = wasm.__wbg_get_evmassetbalance_asset(this.__wbg_ptr);
        return EvmAsset.__wrap(ret);
    }
    /**
    * @param {EvmAsset} arg0
    */
    set asset(arg0) {
        _assertClass(arg0, EvmAsset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_evmassetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {OnchainBalance}
    */
    get onchain_balance() {
        const ret = wasm.__wbg_get_evmassetbalance_onchain_balance(this.__wbg_ptr);
        return OnchainBalance.__wrap(ret);
    }
    /**
    * @param {OnchainBalance} arg0
    */
    set onchain_balance(arg0) {
        _assertClass(arg0, OnchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_evmassetbalance_onchain_balance(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {OffchainBalance}
    */
    get offchain_balance() {
        const ret = wasm.__wbg_get_evmassetbalance_offchain_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
    * @param {OffchainBalance} arg0
    */
    set offchain_balance(arg0) {
        _assertClass(arg0, OffchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_evmassetbalance_offchain_balance(this.__wbg_ptr, ptr0);
    }
}

const EvmBlockHashFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmblockhash_free(ptr >>> 0, 1));
/**
*/
export class EvmBlockHash {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmBlockHash.prototype);
        obj.__wbg_ptr = ptr;
        EvmBlockHashFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmBlockHashFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmblockhash_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmblockhash_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {EvmBlockHash}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.evmblockhash_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return EvmBlockHash.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmBlockHeaderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmblockheader_free(ptr >>> 0, 1));
/**
*/
export class EvmBlockHeader {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmBlockHeader.prototype);
        obj.__wbg_ptr = ptr;
        EvmBlockHeaderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmBlockHeaderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmblockheader_free(ptr, 0);
    }
    /**
    * @returns {bigint}
    */
    get number() {
        const ret = wasm.evmblockheader_number(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {bigint}
    */
    get timestamp() {
        const ret = wasm.evmblockheader_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {EvmBlockHash}
    */
    get hash() {
        const ret = wasm.evmblockheader_hash(this.__wbg_ptr);
        return EvmBlockHash.__wrap(ret);
    }
}

const EvmBlockchainConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmblockchainconfig_free(ptr >>> 0, 1));
/**
*/
export class EvmBlockchainConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmBlockchainConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmblockchainconfig_free(ptr, 0);
    }
    /**
    * @param {string} provider_url
    * @param {string | undefined} [covalent_url]
    * @param {string | undefined} [covalent_api_key]
    * @param {string | undefined} [alchemy_ws_url]
    */
    constructor(provider_url, covalent_url, covalent_api_key, alchemy_ws_url) {
        const ptr0 = passStringToWasm0(provider_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        var ptr1 = isLikeNone(covalent_url) ? 0 : passStringToWasm0(covalent_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        var ptr2 = isLikeNone(covalent_api_key) ? 0 : passStringToWasm0(covalent_api_key, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len2 = WASM_VECTOR_LEN;
        var ptr3 = isLikeNone(alchemy_ws_url) ? 0 : passStringToWasm0(alchemy_ws_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len3 = WASM_VECTOR_LEN;
        const ret = wasm.evmblockchainconfig_new(ptr0, len0, ptr1, len1, ptr2, len2, ptr3, len3);
        this.__wbg_ptr = ret >>> 0;
        EvmBlockchainConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const EvmConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmconfig_free(ptr >>> 0, 1));
/**
*/
export class EvmConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmConfig.prototype);
        obj.__wbg_ptr = ptr;
        EvmConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmconfig_free(ptr, 0);
    }
    /**
    * @param {EvmBlockchainConfig} blockchain_config
    * @param {EvmWalletConfig} wallet_config
    * @returns {EvmConfig}
    */
    static newWatchOnlyClient(blockchain_config, wallet_config) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(wallet_config, EvmWalletConfig);
        const ret = wasm.evmconfig_newWatchOnlyClient(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
    * @param {EvmBlockchainConfig} blockchain_config
    * @param {EvmSignerConfig} signer_config
    * @returns {EvmConfig}
    */
    static newClient(blockchain_config, signer_config) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(signer_config, EvmSignerConfig);
        const ret = wasm.evmconfig_newClient(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
    * @param {EvmBlockchainConfig} blockchain_config
    * @param {EvmWalletConfig} wallet_config
    * @param {EvmAddress} node_id
    * @returns {EvmConfig}
    */
    static newWatchOnlyNode(blockchain_config, wallet_config, node_id) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(wallet_config, EvmWalletConfig);
        _assertClass(node_id, EvmAddress);
        const ret = wasm.evmconfig_newWatchOnlyNode(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr, node_id.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
    * @param {EvmBlockchainConfig} blockchain_config
    * @param {EvmSignerConfig} signer_config
    * @param {EvmAddress} node_id
    * @returns {EvmConfig}
    */
    static newWatchOnlyNodeWithSigner(blockchain_config, signer_config, node_id) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(signer_config, EvmSignerConfig);
        _assertClass(node_id, EvmAddress);
        const ret = wasm.evmconfig_newWatchOnlyNodeWithSigner(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_id.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
    * @param {EvmBlockchainConfig} blockchain_config
    * @param {EvmSignerConfig} signer_config
    * @param {EvmLithiumNodeConfig} node_config
    * @returns {EvmConfig}
    */
    static newNode(blockchain_config, signer_config, node_config) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(signer_config, EvmSignerConfig);
        _assertClass(node_config, EvmLithiumNodeConfig);
        const ret = wasm.evmconfig_newNode(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_config.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
}

const EvmEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmevent_free(ptr >>> 0, 1));
/**
*/
export class EvmEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmEvent.prototype);
        obj.__wbg_ptr = ptr;
        EvmEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmevent_free(ptr, 0);
    }
    /**
    * @returns {EvmNetwork}
    */
    get network() {
        const ret = wasm.evmevent_network(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {EventType}
    */
    get eventType() {
        const ret = wasm.evmevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {EvmBlockHeader | undefined}
    */
    get blockHeader() {
        const ret = wasm.evmevent_blockHeader(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmBlockHeader.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get peerId() {
        const ret = wasm.evmevent_peerId(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmAssetBalance | undefined}
    */
    get balanceUpdate() {
        const ret = wasm.evmevent_balanceUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAssetBalance.__wrap(ret);
    }
    /**
    * @returns {EvmTransaction | undefined}
    */
    get transactionUpdate() {
        const ret = wasm.evmevent_transactionUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTransaction.__wrap(ret);
    }
    /**
    * @returns {EvmTxid | undefined}
    */
    get transactionRemoved() {
        const ret = wasm.evmevent_transactionRemoved(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTxid.__wrap(ret);
    }
    /**
    * @returns {EvmLithiumChannel | undefined}
    */
    get channelUpdate() {
        const ret = wasm.evmevent_channelUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumChannel.__wrap(ret);
    }
    /**
    * @returns {LithiumChannelId | undefined}
    */
    get channelId() {
        const ret = wasm.evmevent_channelId(this.__wbg_ptr);
        return ret === 0 ? undefined : LithiumChannelId.__wrap(ret);
    }
    /**
    * @returns {EvmTxid | undefined}
    */
    get txid() {
        const ret = wasm.evmevent_txid(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTxid.__wrap(ret);
    }
    /**
    * @returns {EvmAsset | undefined}
    */
    get asset() {
        const ret = wasm.evmevent_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAsset.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get assetId() {
        const ret = wasm.evmevent_assetId(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmLithiumAssetChannel | undefined}
    */
    get assetChannel() {
        const ret = wasm.evmevent_assetChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumAssetChannel.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get error() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmevent_error(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmFeeFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmfee_free(ptr >>> 0, 1));
/**
*/
export class EvmFee {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmFee.prototype);
        obj.__wbg_ptr = ptr;
        EvmFeeFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmFeeFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmfee_free(ptr, 0);
    }
    /**
    * @param {U256} gas_price
    * @param {U256} max_gas_price
    */
    constructor(gas_price, max_gas_price) {
        _assertClass(gas_price, U256);
        _assertClass(max_gas_price, U256);
        const ret = wasm.evmfee_new(gas_price.__wbg_ptr, max_gas_price.__wbg_ptr);
        this.__wbg_ptr = ret >>> 0;
        EvmFeeFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
    * @returns {U256}
    */
    get gasPrice() {
        const ret = wasm.evmfee_gasPrice(this.__wbg_ptr);
        return U256.__wrap(ret);
    }
    /**
    * @returns {U256}
    */
    get maxGasPrice() {
        const ret = wasm.evmfee_maxGasPrice(this.__wbg_ptr);
        return U256.__wrap(ret);
    }
}

const EvmFeeEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmfeeestimate_free(ptr >>> 0, 1));
/**
*/
export class EvmFeeEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmFeeEstimate.prototype);
        obj.__wbg_ptr = ptr;
        EvmFeeEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmFeeEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmfeeestimate_free(ptr, 0);
    }
    /**
    * @returns {EvmFee}
    */
    get low() {
        const ret = wasm.evmfeeestimate_low(this.__wbg_ptr);
        return EvmFee.__wrap(ret);
    }
    /**
    * @returns {EvmFee}
    */
    get medium() {
        const ret = wasm.evmfeeestimate_medium(this.__wbg_ptr);
        return EvmFee.__wrap(ret);
    }
    /**
    * @returns {EvmFee}
    */
    get high() {
        const ret = wasm.evmfeeestimate_high(this.__wbg_ptr);
        return EvmFee.__wrap(ret);
    }
}

const EvmLithiumAssetChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumassetchannel_free(ptr >>> 0, 1));
/**
*/
export class EvmLithiumAssetChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumAssetChannel.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumAssetChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumAssetChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumassetchannel_free(ptr, 0);
    }
    /**
    * @returns {EvmAsset}
    */
    get asset() {
        const ret = wasm.evmlithiumassetchannel_asset(this.__wbg_ptr);
        return EvmAsset.__wrap(ret);
    }
    /**
    * @returns {AssetChannelStatus}
    */
    get status() {
        const ret = wasm.evmlithiumassetchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {OffchainBalance}
    */
    get balance() {
        const ret = wasm.evmlithiumassetchannel_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
    * @returns {EvmTxid | undefined}
    */
    get lastOnchainTxid() {
        const ret = wasm.evmlithiumassetchannel_lastOnchainTxid(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTxid.__wrap(ret);
    }
    /**
    * @returns {bigint}
    */
    get lastOperationTimestampSecs() {
        const ret = wasm.evmlithiumassetchannel_lastOperationTimestampSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {bigint | undefined}
    */
    get rentalExpiryTimestampSecs() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmlithiumassetchannel_rentalExpiryTimestampSecs(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmLithiumChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumchannel_free(ptr >>> 0, 1));
/**
*/
export class EvmLithiumChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumChannel.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumchannel_free(ptr, 0);
    }
    /**
    * @returns {LithiumChannelId}
    */
    get id() {
        const ret = wasm.evmlithiumchannel_id(this.__wbg_ptr);
        return LithiumChannelId.__wrap(ret);
    }
    /**
    * @returns {EvmAddress}
    */
    get counterparty() {
        const ret = wasm.evmlithiumchannel_counterparty(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
    * @returns {bigint}
    */
    get lastOperationConfirmations() {
        const ret = wasm.evmlithiumchannel_lastOperationConfirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {ChannelStatus}
    */
    get status() {
        const ret = wasm.evmlithiumchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {(EvmLithiumAssetChannel)[]}
    */
    get assetChannels() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmlithiumchannel_assetChannels(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmLithiumInvoiceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiuminvoice_free(ptr >>> 0, 1));
/**
*/
export class EvmLithiumInvoice {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumInvoice.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumInvoiceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumInvoiceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiuminvoice_free(ptr, 0);
    }
    /**
    * @returns {EvmNetwork}
    */
    get network() {
        const ret = wasm.evmlithiuminvoice_network(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {PaymentHash}
    */
    get paymentHash() {
        const ret = wasm.evmlithiuminvoice_paymentHash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
    /**
    * @returns {EvmAddress}
    */
    get assetId() {
        const ret = wasm.evmlithiuminvoice_assetId(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
    * @returns {U256 | undefined}
    */
    get amount() {
        const ret = wasm.evmlithiuminvoice_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : U256.__wrap(ret);
    }
    /**
    * @returns {EvmAddress}
    */
    get recipient() {
        const ret = wasm.evmlithiuminvoice_recipient(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
    * @returns {bigint | undefined}
    */
    get expiryTimestamp() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmlithiuminvoice_expiryTimestamp(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmlithiuminvoice_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {EvmLithiumInvoice}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.evmlithiuminvoice_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return EvmLithiumInvoice.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmLithiumNodeConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumnodeconfig_free(ptr >>> 0, 1));
/**
*/
export class EvmLithiumNodeConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumNodeConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumnodeconfig_free(ptr, 0);
    }
    /**
    * @param {EvmAddress} contract_address
    * @param {string} lithium_subgraph_url
    * @param {string} websocket_proxy_url
    */
    constructor(contract_address, lithium_subgraph_url, websocket_proxy_url) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            _assertClass(contract_address, EvmAddress);
            const ptr0 = passStringToWasm0(lithium_subgraph_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(websocket_proxy_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            wasm.evmlithiumnodeconfig_new(retptr, contract_address.__wbg_ptr, ptr0, len0, ptr1, len1);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            this.__wbg_ptr = r0 >>> 0;
            EvmLithiumNodeConfigFinalization.register(this, this.__wbg_ptr, this);
            return this;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmLithiumPaymentFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumpayment_free(ptr >>> 0, 1));
/**
*/
export class EvmLithiumPayment {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumPayment.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumPaymentFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumPaymentFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumpayment_free(ptr, 0);
    }
    /**
    * @returns {LithiumPaymentId}
    */
    get id() {
        const ret = wasm.evmlithiumpayment_id(this.__wbg_ptr);
        return LithiumPaymentId.__wrap(ret);
    }
    /**
    * @returns {PaymentHash | undefined}
    */
    get hash() {
        const ret = wasm.evmlithiumpayment_hash(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentHash.__wrap(ret);
    }
    /**
    * @returns {PaymentPreimage | undefined}
    */
    get preimage() {
        const ret = wasm.evmlithiumpayment_preimage(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentPreimage.__wrap(ret);
    }
    /**
    * @returns {OffchainPaymentStatus}
    */
    get status() {
        const ret = wasm.evmlithiumpayment_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {OffchainPaymentType}
    */
    get paymentType() {
        const ret = wasm.evmlithiumpayment_paymentType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {bigint}
    */
    get timestamp() {
        const ret = wasm.evmlithiumpayment_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {(EvmOffchainOperation)[]}
    */
    get operations() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmlithiumpayment_operations(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Map<EvmAsset, Amount>}
    */
    get spent() {
        const ret = wasm.evmlithiumpayment_spent(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<EvmAsset, Amount>}
    */
    get received() {
        const ret = wasm.evmlithiumpayment_received(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<EvmAsset, Amount>}
    */
    get balanceChanges() {
        const ret = wasm.evmlithiumpayment_balanceChanges(this.__wbg_ptr);
        return takeObject(ret);
    }
}

const EvmLithiumPeerFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumpeer_free(ptr >>> 0, 1));
/**
*/
export class EvmLithiumPeer {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumPeer.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumPeerFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumPeerFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumpeer_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmlithiumpeer_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {EvmLithiumPeer}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.evmlithiumpeer_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return EvmLithiumPeer.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmOffchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmoffchainoperation_free(ptr >>> 0, 1));
/**
*/
export class EvmOffchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmOffchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        EvmOffchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmOffchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmoffchainoperation_free(ptr, 0);
    }
    /**
    * @returns {OffchainOperationType}
    */
    get operationType() {
        const ret = wasm.evmoffchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {EvmAsset}
    */
    get asset() {
        const ret = wasm.evmoffchainoperation_asset(this.__wbg_ptr);
        return EvmAsset.__wrap(ret);
    }
    /**
    * @returns {Amount}
    */
    get amount() {
        const ret = wasm.evmoffchainoperation_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get from() {
        const ret = wasm.evmoffchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get to() {
        const ret = wasm.evmoffchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get routedFrom() {
        const ret = wasm.evmoffchainoperation_routedFrom(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get routedTo() {
        const ret = wasm.evmoffchainoperation_routedTo(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {Amount | undefined}
    */
    get earnedFee() {
        const ret = wasm.evmoffchainoperation_earnedFee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
}

const EvmOnchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmonchainoperation_free(ptr >>> 0, 1));
/**
*/
export class EvmOnchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmOnchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        EvmOnchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmOnchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmonchainoperation_free(ptr, 0);
    }
    /**
    * @returns {OnchainOperationType}
    */
    get operationType() {
        const ret = wasm.evmonchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {EvmAsset | undefined}
    */
    get asset() {
        const ret = wasm.evmonchainoperation_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAsset.__wrap(ret);
    }
    /**
    * @returns {Amount | undefined}
    */
    get amount() {
        const ret = wasm.evmonchainoperation_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get to() {
        const ret = wasm.evmonchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get from() {
        const ret = wasm.evmonchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get spender() {
        const ret = wasm.evmonchainoperation_spender(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get address() {
        const ret = wasm.evmonchainoperation_address(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {LithiumChannelId | undefined}
    */
    get channel_id() {
        const ret = wasm.evmonchainoperation_channel_id(this.__wbg_ptr);
        return ret === 0 ? undefined : LithiumChannelId.__wrap(ret);
    }
    /**
    * @returns {EvmAddress | undefined}
    */
    get counterparty() {
        const ret = wasm.evmonchainoperation_counterparty(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
    * @returns {Map<EvmAsset, AssetChannelAmounts> | undefined}
    */
    get assetChannelAmounts() {
        const ret = wasm.evmonchainoperation_assetChannelAmounts(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Deadline | undefined}
    */
    get disputeDeadline() {
        const ret = wasm.evmonchainoperation_disputeDeadline(this.__wbg_ptr);
        return ret === 0 ? undefined : Deadline.__wrap(ret);
    }
}

const EvmSignedTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmsignedtransactionrequest_free(ptr >>> 0, 1));
/**
*/
export class EvmSignedTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmSignedTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        EvmSignedTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmSignedTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmsignedtransactionrequest_free(ptr, 0);
    }
}

const EvmSignerConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmsignerconfig_free(ptr >>> 0, 1));
/**
*/
export class EvmSignerConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmSignerConfig.prototype);
        obj.__wbg_ptr = ptr;
        EvmSignerConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmSignerConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmsignerconfig_free(ptr, 0);
    }
    /**
    * @param {Mnemonic} mnemonic
    * @param {string} password
    * @returns {EvmSignerConfig}
    */
    static newHot(mnemonic, password) {
        _assertClass(mnemonic, Mnemonic);
        const ptr0 = passStringToWasm0(password, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinsignerconfig_newHot(mnemonic.__wbg_ptr, ptr0, len0);
        return EvmSignerConfig.__wrap(ret);
    }
}

const EvmTokenFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtoken_free(ptr >>> 0, 1));
/**
*/
export class EvmToken {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmToken.prototype);
        obj.__wbg_ptr = ptr;
        EvmTokenFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTokenFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtoken_free(ptr, 0);
    }
    /**
    * @returns {EvmTokenVariant}
    */
    get variant() {
        const ret = wasm.evmtoken_variant(this.__wbg_ptr);
        return ret;
    }
}

const EvmTransactionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtransaction_free(ptr >>> 0, 1));
/**
*/
export class EvmTransaction {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmTransaction.prototype);
        obj.__wbg_ptr = ptr;
        EvmTransactionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTransactionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtransaction_free(ptr, 0);
    }
    /**
    * @returns {EvmTxid}
    */
    get id() {
        const ret = wasm.evmtransaction_id(this.__wbg_ptr);
        return EvmTxid.__wrap(ret);
    }
    /**
    * @returns {OnchainTxStatus}
    */
    get status() {
        const ret = wasm.evmtransaction_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {bigint | undefined}
    */
    get blockHeight() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmtransaction_blockHeight(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint | undefined}
    */
    get timestamp() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmtransaction_timestamp(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint}
    */
    get confirmations() {
        const ret = wasm.evmtransaction_confirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {Amount}
    */
    get fee() {
        const ret = wasm.evmtransaction_fee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {Map<EvmAsset, Amount>}
    */
    get spent() {
        const ret = wasm.evmtransaction_spent(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<EvmAsset, Amount>}
    */
    get received() {
        const ret = wasm.evmtransaction_received(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {(EvmOnchainOperation)[]}
    */
    get operations() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmtransaction_operations(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Map<EvmAsset, Amount>}
    */
    get balanceChanges() {
        const ret = wasm.evmtransaction_balanceChanges(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {OnchainTxType}
    */
    get transactionType() {
        const ret = wasm.evmtransaction_transactionType(this.__wbg_ptr);
        return ret;
    }
}

const EvmTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtransactionrequest_free(ptr >>> 0, 1));
/**
*/
export class EvmTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        EvmTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtransactionrequest_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get fee() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmtransactionrequest_fee(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return Amount.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {FeeRate} fee_rate
    */
    changeFeeRate(fee_rate) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            _assertClass(fee_rate, FeeRate);
            wasm.evmtransactionrequest_changeFeeRate(retptr, this.__wbg_ptr, fee_rate.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            if (r1) {
                throw takeObject(r0);
            }
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmTxidFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtxid_free(ptr >>> 0, 1));
/**
*/
export class EvmTxid {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmTxid.prototype);
        obj.__wbg_ptr = ptr;
        EvmTxidFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTxidFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtxid_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.evmtxid_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {EvmTxid}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.evmtxid_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return EvmTxid.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const EvmWalletConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmwalletconfig_free(ptr >>> 0, 1));
/**
*/
export class EvmWalletConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmWalletConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmwalletconfig_free(ptr, 0);
    }
    /**
    * @param {EvmAddress} address
    */
    constructor(address) {
        _assertClass(address, EvmAddress);
        const ret = wasm.evmwalletconfig_new(address.__wbg_ptr);
        this.__wbg_ptr = ret >>> 0;
        EvmWalletConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const ExtendedPubKeyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_extendedpubkey_free(ptr >>> 0, 1));
/**
*/
export class ExtendedPubKey {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ExtendedPubKey.prototype);
        obj.__wbg_ptr = ptr;
        ExtendedPubKeyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ExtendedPubKeyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_extendedpubkey_free(ptr, 0);
    }
    /**
    * @param {string} value
    * @returns {ExtendedPubKey}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.extendedpubkey_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return ExtendedPubKey.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toMainnetString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.extendedpubkey_toMainnetString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    toTestnetString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.extendedpubkey_toTestnetString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const FeeRateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_feerate_free(ptr >>> 0, 1));
/**
*/
export class FeeRate {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        FeeRateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_feerate_free(ptr, 0);
    }
    /**
    * @param {U256} price_per_unit
    * @param {U256} max_price_per_unit
    */
    constructor(price_per_unit, max_price_per_unit) {
        _assertClass(price_per_unit, U256);
        _assertClass(max_price_per_unit, U256);
        const ret = wasm.feerate_new(price_per_unit.__wbg_ptr, max_price_per_unit.__wbg_ptr);
        this.__wbg_ptr = ret >>> 0;
        FeeRateFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
    * @returns {U256}
    */
    get pricePerUnit() {
        const ret = wasm.evmfee_gasPrice(this.__wbg_ptr);
        return U256.__wrap(ret);
    }
    /**
    * @returns {U256}
    */
    get maxPricePerUnit() {
        const ret = wasm.feerate_maxPricePerUnit(this.__wbg_ptr);
        return U256.__wrap(ret);
    }
}

const FiatBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_fiatbalance_free(ptr >>> 0, 1));
/**
*/
export class FiatBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(FiatBalance.prototype);
        obj.__wbg_ptr = ptr;
        FiatBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        FiatBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_fiatbalance_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get onchain() {
        const ret = wasm.__wbg_get_fiatbalance_onchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set onchain(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_onchain(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get offchain() {
        const ret = wasm.__wbg_get_fiatbalance_offchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set offchain(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_offchain(this.__wbg_ptr, ptr0);
    }
}

const FingerprintFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_fingerprint_free(ptr >>> 0, 1));
/**
*/
export class Fingerprint {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Fingerprint.prototype);
        obj.__wbg_ptr = ptr;
        FingerprintFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        FingerprintFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_fingerprint_free(ptr, 0);
    }
    /**
    * @param {string} value
    * @returns {Fingerprint}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.fingerprint_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return Fingerprint.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.fingerprint_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const HashlockFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_hashlock_free(ptr >>> 0, 1));
/**
*/
export class Hashlock {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Hashlock.prototype);
        obj.__wbg_ptr = ptr;
        HashlockFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        HashlockFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_hashlock_free(ptr, 0);
    }
    /**
    * @param {PaymentPreimage} payment_preimage
    * @returns {Hashlock}
    */
    static newKnown(payment_preimage) {
        _assertClass(payment_preimage, PaymentPreimage);
        const ret = wasm.hashlock_newKnown(payment_preimage.__wbg_ptr);
        return Hashlock.__wrap(ret);
    }
    /**
    * @param {PaymentHash} payment_hash
    * @returns {Hashlock}
    */
    static newUnknown(payment_hash) {
        _assertClass(payment_hash, PaymentHash);
        const ret = wasm.hashlock_newUnknown(payment_hash.__wbg_ptr);
        return Hashlock.__wrap(ret);
    }
    /**
    * @returns {PaymentHash}
    */
    get hash() {
        const ret = wasm.hashlock_hash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
}

const IntoUnderlyingByteSourceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_intounderlyingbytesource_free(ptr >>> 0, 1));
/**
*/
export class IntoUnderlyingByteSource {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        IntoUnderlyingByteSourceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_intounderlyingbytesource_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    get type() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.intounderlyingbytesource_type(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {number}
    */
    get autoAllocateChunkSize() {
        const ret = wasm.intounderlyingbytesource_autoAllocateChunkSize(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @param {ReadableByteStreamController} controller
    */
    start(controller) {
        wasm.intounderlyingbytesource_start(this.__wbg_ptr, addHeapObject(controller));
    }
    /**
    * @param {ReadableByteStreamController} controller
    * @returns {Promise<any>}
    */
    pull(controller) {
        const ret = wasm.intounderlyingbytesource_pull(this.__wbg_ptr, addHeapObject(controller));
        return takeObject(ret);
    }
    /**
    */
    cancel() {
        const ptr = this.__destroy_into_raw();
        wasm.intounderlyingbytesource_cancel(ptr);
    }
}

const IntoUnderlyingSinkFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_intounderlyingsink_free(ptr >>> 0, 1));
/**
*/
export class IntoUnderlyingSink {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        IntoUnderlyingSinkFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_intounderlyingsink_free(ptr, 0);
    }
    /**
    * @param {any} chunk
    * @returns {Promise<any>}
    */
    write(chunk) {
        const ret = wasm.intounderlyingsink_write(this.__wbg_ptr, addHeapObject(chunk));
        return takeObject(ret);
    }
    /**
    * @returns {Promise<any>}
    */
    close() {
        const ptr = this.__destroy_into_raw();
        const ret = wasm.intounderlyingsink_close(ptr);
        return takeObject(ret);
    }
    /**
    * @param {any} reason
    * @returns {Promise<any>}
    */
    abort(reason) {
        const ptr = this.__destroy_into_raw();
        const ret = wasm.intounderlyingsink_abort(ptr, addHeapObject(reason));
        return takeObject(ret);
    }
}

const IntoUnderlyingSourceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_intounderlyingsource_free(ptr >>> 0, 1));
/**
*/
export class IntoUnderlyingSource {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        IntoUnderlyingSourceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_intounderlyingsource_free(ptr, 0);
    }
    /**
    * @param {ReadableStreamDefaultController} controller
    * @returns {Promise<any>}
    */
    pull(controller) {
        const ret = wasm.intounderlyingsource_pull(this.__wbg_ptr, addHeapObject(controller));
        return takeObject(ret);
    }
    /**
    */
    cancel() {
        const ptr = this.__destroy_into_raw();
        wasm.intounderlyingsource_cancel(ptr);
    }
}

const LightningChannelIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lightningchannelid_free(ptr >>> 0, 1));
/**
*/
export class LightningChannelId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LightningChannelId.prototype);
        obj.__wbg_ptr = ptr;
        LightningChannelIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LightningChannelIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lightningchannelid_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.lightningchannelid_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {LightningChannelId}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.lightningchannelid_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return LightningChannelId.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const LightningPaymentIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lightningpaymentid_free(ptr >>> 0, 1));
/**
*/
export class LightningPaymentId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LightningPaymentId.prototype);
        obj.__wbg_ptr = ptr;
        LightningPaymentIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LightningPaymentIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lightningpaymentid_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.lightningpaymentid_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {LightningPaymentId}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.lightningpaymentid_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return LightningPaymentId.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const LiquidityFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_liquidity_free(ptr >>> 0, 1));
/**
*/
export class Liquidity {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Liquidity.prototype);
        obj.__wbg_ptr = ptr;
        LiquidityFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof Liquidity)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LiquidityFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_liquidity_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get amount() {
        const ret = wasm.liquidity_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {Amount}
    */
    get minPrice() {
        const ret = wasm.liquidity_minPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {Amount}
    */
    get maxPrice() {
        const ret = wasm.liquidity_maxPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const LithiumChannelIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lithiumchannelid_free(ptr >>> 0, 1));
/**
*/
export class LithiumChannelId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LithiumChannelId.prototype);
        obj.__wbg_ptr = ptr;
        LithiumChannelIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LithiumChannelIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lithiumchannelid_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.lithiumchannelid_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {LithiumChannelId}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.lithiumchannelid_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return LithiumChannelId.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const LithiumPaymentIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lithiumpaymentid_free(ptr >>> 0, 1));
/**
*/
export class LithiumPaymentId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LithiumPaymentId.prototype);
        obj.__wbg_ptr = ptr;
        LithiumPaymentIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LithiumPaymentIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lithiumpaymentid_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.lithiumpaymentid_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {string} value
    * @returns {LithiumPaymentId}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.lithiumpaymentid_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return LithiumPaymentId.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const MarketInfoFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_marketinfo_free(ptr >>> 0, 1));
/**
*/
export class MarketInfo {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(MarketInfo.prototype);
        obj.__wbg_ptr = ptr;
        MarketInfoFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MarketInfoFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_marketinfo_free(ptr, 0);
    }
    /**
    * @returns {CurrencyInfo}
    */
    get base() {
        const ret = wasm.__wbg_get_marketinfo_base(this.__wbg_ptr);
        return CurrencyInfo.__wrap(ret);
    }
    /**
    * @param {CurrencyInfo} arg0
    */
    set base(arg0) {
        _assertClass(arg0, CurrencyInfo);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_marketinfo_base(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {CurrencyInfo}
    */
    get quote() {
        const ret = wasm.__wbg_get_marketinfo_quote(this.__wbg_ptr);
        return CurrencyInfo.__wrap(ret);
    }
    /**
    * @param {CurrencyInfo} arg0
    */
    set quote(arg0) {
        _assertClass(arg0, CurrencyInfo);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_marketinfo_quote(this.__wbg_ptr, ptr0);
    }
}

const MnemonicFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_mnemonic_free(ptr >>> 0, 1));
/**
*/
export class Mnemonic {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Mnemonic.prototype);
        obj.__wbg_ptr = ptr;
        MnemonicFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MnemonicFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_mnemonic_free(ptr, 0);
    }
    /**
    * @param {number} num_words
    * @returns {Mnemonic}
    */
    static generateNew(num_words) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.mnemonic_generateNew(retptr, num_words);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return Mnemonic.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {string} value
    * @returns {Mnemonic}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.mnemonic_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return Mnemonic.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.mnemonic_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const NetworkFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_network_free(ptr >>> 0, 1));
/**
*/
export class Network {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Network.prototype);
        obj.__wbg_ptr = ptr;
        NetworkFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        NetworkFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_network_free(ptr, 0);
    }
    /**
    * @returns {string}
    */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.network_name(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.network_symbol(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get logoUrl() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.network_logoUrl(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get coinSymbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.network_coinSymbol(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get coinName() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.network_coinName(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    get explorerUrl() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.network_explorerUrl(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {number}
    */
    get requiredConfirmations() {
        const ret = wasm.network_requiredConfirmations(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @returns {Protocol}
    */
    get type() {
        const ret = wasm.network_type(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {BitcoinNetwork | undefined}
    */
    asBitcoin() {
        const ret = wasm.network_asBitcoin(this.__wbg_ptr);
        return ret === 4 ? undefined : ret;
    }
    /**
    * @returns {EvmNetwork | undefined}
    */
    asEvm() {
        const ret = wasm.network_asEvm(this.__wbg_ptr);
        return ret === 2 ? undefined : ret;
    }
}

const OffchainBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_offchainbalance_free(ptr >>> 0, 1));
/**
*/
export class OffchainBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(OffchainBalance.prototype);
        obj.__wbg_ptr = ptr;
        OffchainBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OffchainBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_offchainbalance_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get free_local() {
        const ret = wasm.__wbg_get_fiatbalance_onchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set free_local(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_onchain(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get free_remote() {
        const ret = wasm.__wbg_get_fiatbalance_offchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set free_remote(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_offchain(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get unavailable_local() {
        const ret = wasm.__wbg_get_offchainbalance_unavailable_local(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set unavailable_local(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unavailable_local(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get unavailable_remote() {
        const ret = wasm.__wbg_get_offchainbalance_unavailable_remote(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set unavailable_remote(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unavailable_remote(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get paying_local() {
        const ret = wasm.__wbg_get_offchainbalance_paying_local(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set paying_local(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_paying_local(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get paying_remote() {
        const ret = wasm.__wbg_get_offchainbalance_paying_remote(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set paying_remote(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_paying_remote(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get unspendable_local_reserve() {
        const ret = wasm.__wbg_get_offchainbalance_unspendable_local_reserve(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set unspendable_local_reserve(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unspendable_local_reserve(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get unspendable_remote_reserve() {
        const ret = wasm.__wbg_get_offchainbalance_unspendable_remote_reserve(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set unspendable_remote_reserve(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unspendable_remote_reserve(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    localBalance() {
        const ret = wasm.offchainbalance_localBalance(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {Amount}
    */
    remoteBalance() {
        const ret = wasm.offchainbalance_remoteBalance(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const OnchainBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_onchainbalance_free(ptr >>> 0, 1));
/**
*/
export class OnchainBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(OnchainBalance.prototype);
        obj.__wbg_ptr = ptr;
        OnchainBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OnchainBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_onchainbalance_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get usable() {
        const ret = wasm.__wbg_get_fiatbalance_onchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set usable(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_onchain(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {Amount}
    */
    get pending() {
        const ret = wasm.__wbg_get_fiatbalance_offchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set pending(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_offchain(this.__wbg_ptr, ptr0);
    }
}

const OperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_operation_free(ptr >>> 0, 1));
/**
*/
export class Operation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Operation.prototype);
        obj.__wbg_ptr = ptr;
        OperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_operation_free(ptr, 0);
    }
    /**
    * @returns {OperationType}
    */
    get operationType() {
        const ret = wasm.operation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {Asset | undefined}
    */
    get asset() {
        const ret = wasm.operation_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : Asset.__wrap(ret);
    }
    /**
    * @returns {Amount | undefined}
    */
    get amount() {
        const ret = wasm.operation_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get to() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_to(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string | undefined}
    */
    get from() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_from(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string | undefined}
    */
    get spender() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_spender(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string | undefined}
    */
    get address() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_address(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string | undefined}
    */
    get channel_id() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_channel_id(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string | undefined}
    */
    get counterparty() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_counterparty(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Map<Asset, AssetChannelAmounts> | undefined}
    */
    get assetChannelAmounts() {
        const ret = wasm.operation_assetChannelAmounts(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Deadline | undefined}
    */
    get disputeDeadline() {
        const ret = wasm.operation_disputeDeadline(this.__wbg_ptr);
        return ret === 0 ? undefined : Deadline.__wrap(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get routed_from() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_routed_from(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string | undefined}
    */
    get routed_to() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.operation_routed_to(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Amount | undefined}
    */
    get earned_fee() {
        const ret = wasm.operation_earned_fee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
}

const OrderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_order_free(ptr >>> 0, 1));
/**
*/
export class Order {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Order.prototype);
        obj.__wbg_ptr = ptr;
        OrderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OrderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_order_free(ptr, 0);
    }
    /**
    * @param {Amount} amount
    * @param {Amount} min_price
    * @param {Amount | undefined} max_price
    * @param {boolean} remove_on_fill
    * @returns {Order}
    */
    static newAddBuyLiquidity(amount, min_price, max_price, remove_on_fill) {
        _assertClass(amount, Amount);
        var ptr0 = amount.__destroy_into_raw();
        _assertClass(min_price, Amount);
        var ptr1 = min_price.__destroy_into_raw();
        let ptr2 = 0;
        if (!isLikeNone(max_price)) {
            _assertClass(max_price, Amount);
            ptr2 = max_price.__destroy_into_raw();
        }
        const ret = wasm.order_newAddBuyLiquidity(ptr0, ptr1, ptr2, remove_on_fill);
        return Order.__wrap(ret);
    }
    /**
    * @param {Amount} amount
    * @param {Amount | undefined} min_price
    * @param {Amount} max_price
    * @param {boolean} remove_on_fill
    * @returns {Order}
    */
    static newAddSellLiquidity(amount, min_price, max_price, remove_on_fill) {
        _assertClass(amount, Amount);
        var ptr0 = amount.__destroy_into_raw();
        let ptr1 = 0;
        if (!isLikeNone(min_price)) {
            _assertClass(min_price, Amount);
            ptr1 = min_price.__destroy_into_raw();
        }
        _assertClass(max_price, Amount);
        var ptr2 = max_price.__destroy_into_raw();
        const ret = wasm.order_newAddSellLiquidity(ptr0, ptr1, ptr2, remove_on_fill);
        return Order.__wrap(ret);
    }
    /**
    * @param {Amount} amount
    * @returns {Order}
    */
    static newMarketBuy(amount) {
        _assertClass(amount, Amount);
        var ptr0 = amount.__destroy_into_raw();
        const ret = wasm.order_newMarketBuy(ptr0);
        return Order.__wrap(ret);
    }
    /**
    * @param {Amount} amount
    * @returns {Order}
    */
    static newMarketSell(amount) {
        _assertClass(amount, Amount);
        var ptr0 = amount.__destroy_into_raw();
        const ret = wasm.order_newMarketSell(ptr0);
        return Order.__wrap(ret);
    }
    /**
    * @returns {OrderType}
    */
    get order_type() {
        const ret = wasm.order_order_type(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {Amount | undefined}
    */
    get minPrice() {
        const ret = wasm.order_minPrice(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
    * @returns {Amount | undefined}
    */
    get maxPrice() {
        const ret = wasm.order_maxPrice(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
    * @returns {Amount}
    */
    get amount() {
        const ret = wasm.order_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @returns {boolean}
    */
    get removeOnFill() {
        const ret = wasm.order_removeOnFill(this.__wbg_ptr);
        return ret !== 0;
    }
}

const OrderbookFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_orderbook_free(ptr >>> 0, 1));
/**
*/
export class Orderbook {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Orderbook.prototype);
        obj.__wbg_ptr = ptr;
        OrderbookFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OrderbookFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_orderbook_free(ptr, 0);
    }
    /**
    * @returns {Amount}
    */
    get price() {
        const ret = wasm.__wbg_get_orderbook_price(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
    * @param {Amount} arg0
    */
    set price(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_orderbook_price(this.__wbg_ptr, ptr0);
    }
    /**
    * @returns {(Liquidity)[]}
    */
    get asks() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_orderbook_asks(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {(Liquidity)[]} arg0
    */
    set asks(arg0) {
        const ptr0 = passArrayJsValueToWasm0(arg0, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.__wbg_set_orderbook_asks(this.__wbg_ptr, ptr0, len0);
    }
    /**
    * @returns {(Liquidity)[]}
    */
    get bids() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_orderbook_bids(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {(Liquidity)[]} arg0
    */
    set bids(arg0) {
        const ptr0 = passArrayJsValueToWasm0(arg0, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.__wbg_set_orderbook_bids(this.__wbg_ptr, ptr0, len0);
    }
}

const PaymentHashFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_paymenthash_free(ptr >>> 0, 1));
/**
*/
export class PaymentHash {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(PaymentHash.prototype);
        obj.__wbg_ptr = ptr;
        PaymentHashFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        PaymentHashFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_paymenthash_free(ptr, 0);
    }
    /**
    * @param {string} value
    * @returns {PaymentHash}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.paymenthash_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return PaymentHash.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.paymenthash_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const PaymentPreimageFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_paymentpreimage_free(ptr >>> 0, 1));
/**
*/
export class PaymentPreimage {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(PaymentPreimage.prototype);
        obj.__wbg_ptr = ptr;
        PaymentPreimageFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        PaymentPreimageFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_paymentpreimage_free(ptr, 0);
    }
    /**
    * @returns {PaymentPreimage}
    */
    static generateNew() {
        const ret = wasm.paymentpreimage_generateNew();
        return PaymentPreimage.__wrap(ret);
    }
    /**
    * @returns {PaymentHash}
    */
    hash() {
        const ret = wasm.paymentpreimage_hash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
    /**
    * @param {string} value
    * @returns {PaymentPreimage}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.paymentpreimage_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return PaymentPreimage.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.paymentpreimage_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const RentalAssetConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rentalassetconfig_free(ptr >>> 0, 1));
/**
* Supported rental asset and its rental configuration
*/
export class RentalAssetConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RentalAssetConfig.prototype);
        obj.__wbg_ptr = ptr;
        RentalAssetConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RentalAssetConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rentalassetconfig_free(ptr, 0);
    }
    /**
    * The rental asset
    * @returns {Asset}
    */
    get asset() {
        const ret = wasm.rentalassetconfig_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
    * The rental fee percentage
    * @returns {number}
    */
    get feePercentage() {
        const ret = wasm.rentalassetconfig_feePercentage(this.__wbg_ptr);
        return ret;
    }
}

const RentalNodeInfoFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rentalnodeinfo_free(ptr >>> 0, 1));
/**
* Information about the channel rental duration and capacity limits of a rental node
*/
export class RentalNodeInfo {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RentalNodeInfo.prototype);
        obj.__wbg_ptr = ptr;
        RentalNodeInfoFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RentalNodeInfoFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rentalnodeinfo_free(ptr, 0);
    }
    /**
    * Minimum rental duration in seconds
    * @returns {bigint}
    */
    get minDurationSecs() {
        const ret = wasm.rentalnodeinfo_minDurationSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * Maximum rental duration in seconds
    * @returns {bigint}
    */
    get maxDurationSecs() {
        const ret = wasm.rentalnodeinfo_maxDurationSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * Minimum channel capacity in USD
    * @returns {number}
    */
    get minCapacityUsd() {
        const ret = wasm.rentalnodeinfo_minCapacityUsd(this.__wbg_ptr);
        return ret;
    }
    /**
    * Maximum channel capacity in USD
    * @returns {number}
    */
    get maxCapacityUsd() {
        const ret = wasm.rentalnodeinfo_maxCapacityUsd(this.__wbg_ptr);
        return ret;
    }
    /**
    * Supported rental assets and their rental configurations
    * @returns {Array<RentalAssetConfig>}
    */
    get rentalAssets() {
        const ret = wasm.rentalnodeinfo_rentalAssets(this.__wbg_ptr);
        return takeObject(ret);
    }
}

const RentalOptionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rentaloption_free(ptr >>> 0, 1));
/**
* Option to specify the rental payment method
*/
export class RentalOption {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RentalOption.prototype);
        obj.__wbg_ptr = ptr;
        RentalOptionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RentalOptionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rentaloption_free(ptr, 0);
    }
    /**
    * Option to rent the channel by paying with a Bitcoin asset
    *
    * # Arguments
    *
    * * `paying_network` - The Bitcoin network to pay the rental fee with
    * * `paying_asset` - The Bitcoin asset to pay the rental fee with
    * * `fee_rate` - The fee rate that the rental node will use to open the channel
    * * `payment_type` - Whether to pay the rental fee using on-chain or off-chain funds
    * @param {BitcoinNetwork} paying_network
    * @param {BitcoinAsset} paying_asset
    * @param {FeeRate} fee_rate
    * @param {RentalPaymentType} payment_type
    * @returns {RentalOption}
    */
    static newBitcoinPayment(paying_network, paying_asset, fee_rate, payment_type) {
        _assertClass(paying_asset, BitcoinAsset);
        _assertClass(fee_rate, FeeRate);
        const ret = wasm.rentaloption_newBitcoinPayment(paying_network, paying_asset.__wbg_ptr, fee_rate.__wbg_ptr, payment_type);
        return RentalOption.__wrap(ret);
    }
    /**
    * Option to rent the channel by paying with an Evm asset
    *
    * # Arguments
    *
    * * `paying_network` - The Evm network to pay the rental fee with
    * * `paying_asset` - The Evm asset to pay the rental fee with
    * * `fee_rate` - The fee rate that the rental node will use to open the channel
    * * `payment_type` - Whether to pay the rental fee using on-chain or off-chain funds
    * @param {EvmNetwork} paying_network
    * @param {EvmAsset} paying_asset
    * @param {FeeRate} fee_rate
    * @param {RentalPaymentType} payment_type
    * @returns {RentalOption}
    */
    static newEvmPayment(paying_network, paying_asset, fee_rate, payment_type) {
        _assertClass(paying_asset, EvmAsset);
        _assertClass(fee_rate, FeeRate);
        const ret = wasm.rentaloption_newEvmPayment(paying_network, paying_asset.__wbg_ptr, fee_rate.__wbg_ptr, payment_type);
        return RentalOption.__wrap(ret);
    }
    /**
    * Option to rent the channel by dual funding
    *
    * # Arguments
    *
    * * `self_amount` - The amount to be deposited on the local side of the channel
    * @param {Amount} self_amount
    * @returns {RentalOption}
    */
    static newDualFund(self_amount) {
        _assertClass(self_amount, Amount);
        const ret = wasm.rentaloption_newDualFund(self_amount.__wbg_ptr);
        return RentalOption.__wrap(ret);
    }
}

const SendAmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_sendamount_free(ptr >>> 0, 1));
/**
*/
export class SendAmount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SendAmount.prototype);
        obj.__wbg_ptr = ptr;
        SendAmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof SendAmount)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SendAmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_sendamount_free(ptr, 0);
    }
    /**
    * @returns {SendAmount}
    */
    static all() {
        const ret = wasm.allowanceamount_unlimited();
        return SendAmount.__wrap(ret);
    }
    /**
    * @param {Amount} amount
    * @returns {SendAmount}
    */
    static exact(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.allowanceamount_exact(amount.__wbg_ptr);
        return SendAmount.__wrap(ret);
    }
    /**
    * @returns {SendAmount}
    */
    static zero() {
        const ret = wasm.allowanceamount_zero();
        return SendAmount.__wrap(ret);
    }
    /**
    * @param {number} value
    * @returns {SendAmount}
    */
    static fromFloat(value) {
        const ret = wasm.allowanceamount_fromFloat(value);
        return SendAmount.__wrap(ret);
    }
    /**
    * @param {U256} value
    * @param {number} decimals
    * @returns {SendAmount}
    */
    static fromCommonDenomination(value, decimals) {
        _assertClass(value, U256);
        const ret = wasm.allowanceamount_fromCommonDenomination(value.__wbg_ptr, decimals);
        return SendAmount.__wrap(ret);
    }
}

const TransactionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_transaction_free(ptr >>> 0, 1));
/**
*/
export class Transaction {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Transaction.prototype);
        obj.__wbg_ptr = ptr;
        TransactionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        TransactionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_transaction_free(ptr, 0);
    }
    /**
    * @returns {Network}
    */
    get network() {
        const ret = wasm.transaction_network(this.__wbg_ptr);
        return Network.__wrap(ret);
    }
    /**
    * @returns {Layer}
    */
    get layer() {
        const ret = wasm.transaction_layer(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {Protocol}
    */
    get protocol() {
        const ret = wasm.transaction_protocol(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {TxType}
    */
    get type() {
        const ret = wasm.transaction_type(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {string}
    */
    get id() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.transaction_id(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {TxStatus}
    */
    get status() {
        const ret = wasm.transaction_status(this.__wbg_ptr);
        return ret;
    }
    /**
    * @returns {bigint | undefined}
    */
    get blockHeight() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.transaction_blockHeight(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint | undefined}
    */
    get timestamp() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.transaction_timestamp(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {bigint | undefined}
    */
    get confirmations() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.transaction_confirmations(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r2 = getDataViewMemory0().getBigInt64(retptr + 8 * 1, true);
            return r0 === 0 ? undefined : BigInt.asUintN(64, r2);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Amount | undefined}
    */
    get fee() {
        const ret = wasm.transaction_fee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
    * @returns {Map<Asset, Amount>}
    */
    get spent() {
        const ret = wasm.transaction_spent(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<Asset, Amount>}
    */
    get received() {
        const ret = wasm.transaction_received(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {Map<Asset, Amount>}
    */
    get balanceChanges() {
        const ret = wasm.transaction_balanceChanges(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {(Operation)[]}
    */
    get operations() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.transaction_operations(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {Map<Asset, Amount>}
    */
    get assetsAmountsToShow() {
        const ret = wasm.transaction_assetsAmountsToShow(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @returns {string | undefined}
    */
    get explorerLink() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.transaction_explorerLink(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {BitcoinTransaction | undefined}
    */
    asBitcoinTransaction() {
        const ret = wasm.transaction_asBitcoinTransaction(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTransaction.__wrap(ret);
    }
    /**
    * @returns {BitcoinLightningPayment | undefined}
    */
    asBitcoinLightningPayment() {
        const ret = wasm.transaction_asBitcoinLightningPayment(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningPayment.__wrap(ret);
    }
    /**
    * @returns {EvmTransaction | undefined}
    */
    asEvmTransaction() {
        const ret = wasm.transaction_asEvmTransaction(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTransaction.__wrap(ret);
    }
    /**
    * @returns {EvmLithiumPayment | undefined}
    */
    asEvmLithiumPayment() {
        const ret = wasm.transaction_asEvmLithiumPayment(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumPayment.__wrap(ret);
    }
}

const U256Finalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_u256_free(ptr >>> 0, 1));
/**
*/
export class U256 {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(U256.prototype);
        obj.__wbg_ptr = ptr;
        U256Finalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        U256Finalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_u256_free(ptr, 0);
    }
    /**
    * @returns {U256}
    */
    static zero() {
        const ret = wasm.u256_zero();
        return U256.__wrap(ret);
    }
    /**
    * @returns {boolean}
    */
    isZero() {
        const ret = wasm.u256_isZero(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
    * @param {string} value
    * @returns {U256}
    */
    static fromString(value) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.u256_fromString(retptr, ptr0, len0);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var r2 = getDataViewMemory0().getInt32(retptr + 4 * 2, true);
            if (r2) {
                throw takeObject(r1);
            }
            return U256.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.u256_toString(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbindgen_number_new = function(arg0) {
        const ret = arg0;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_operation_new = function(arg0) {
        const ret = Operation.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinevent_new = function(arg0) {
        const ret = BitcoinEvent.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_appevent_new = function(arg0) {
        const ret = AppEvent.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_transaction_new = function(arg0) {
        const ret = Transaction.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmlithiuminvoice_new = function(arg0) {
        const ret = EvmLithiumInvoice.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinblockheader_new = function(arg0) {
        const ret = BitcoinBlockHeader.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmsignedtransactionrequest_new = function(arg0) {
        const ret = EvmSignedTransactionRequest.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmlithiumassetchannel_new = function(arg0) {
        const ret = EvmLithiumAssetChannel.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinpublickey_new = function(arg0) {
        const ret = BitcoinPublicKey.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmfeeestimate_new = function(arg0) {
        const ret = EvmFeeEstimate.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_channel_new = function(arg0) {
        const ret = Channel.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinfeeestimate_new = function(arg0) {
        const ret = BitcoinFeeEstimate.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmtxid_new = function(arg0) {
        const ret = EvmTxid.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_rentalassetconfig_new = function(arg0) {
        const ret = RentalAssetConfig.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinlightningassetchannel_new = function(arg0) {
        const ret = BitcoinLightningAssetChannel.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmtransactionrequest_new = function(arg0) {
        const ret = EvmTransactionRequest.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_assetchannel_new = function(arg0) {
        const ret = AssetChannel.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmonchainoperation_new = function(arg0) {
        const ret = EvmOnchainOperation.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcointxid_new = function(arg0) {
        const ret = BitcoinTxid.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmevent_new = function(arg0) {
        const ret = EvmEvent.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinonchainoperation_new = function(arg0) {
        const ret = BitcoinOnchainOperation.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmblockheader_new = function(arg0) {
        const ret = EvmBlockHeader.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_rentalnodeinfo_new = function(arg0) {
        const ret = RentalNodeInfo.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmaddress_new = function(arg0) {
        const ret = EvmAddress.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinlightninginvoice_new = function(arg0) {
        const ret = BitcoinLightningInvoice.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinsignedtransactionrequest_new = function(arg0) {
        const ret = BitcoinSignedTransactionRequest.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_orderbook_new = function(arg0) {
        const ret = Orderbook.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmoffchainoperation_new = function(arg0) {
        const ret = EvmOffchainOperation.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_marketinfo_new = function(arg0) {
        const ret = MarketInfo.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinasset_new = function(arg0) {
        const ret = BitcoinAsset.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_asset_new = function(arg0) {
        const ret = Asset.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_evmasset_new = function(arg0) {
        const ret = EvmAsset.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinoffchainoperation_new = function(arg0) {
        const ret = BitcoinOffchainOperation.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcointransactionrequest_new = function(arg0) {
        const ret = BitcoinTransactionRequest.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_order_new = function(arg0) {
        const ret = Order.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_liquidity_new = function(arg0) {
        const ret = Liquidity.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_assetbalance_new = function(arg0) {
        const ret = AssetBalance.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_fiatbalance_new = function(arg0) {
        const ret = FiatBalance.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_bitcoinaddress_new = function(arg0) {
        const ret = BitcoinAddress.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbindgen_array_new = function() {
        const ret = [];
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_array_push = function(arg0, arg1) {
        getObject(arg0).push(takeObject(arg1));
    };
    imports.wbg.__wbg_assetchannelamounts_new = function(arg0) {
        const ret = AssetChannelAmounts.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_amount_new = function(arg0) {
        const ret = Amount.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_network_new = function(arg0) {
        const ret = Network.__wrap(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_error_new = function(arg0, arg1) {
        const ret = new Error(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_TAURIINVOKE_d2d85fa716bd6034 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = window.__TAURI_INVOKE__(getStringFromWasm0(arg0, arg1), takeObject(arg2));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbindgen_string_get = function(arg0, arg1) {
        const obj = getObject(arg1);
        const ret = typeof(obj) === 'string' ? obj : undefined;
        var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_is_undefined = function(arg0) {
        const ret = getObject(arg0) === undefined;
        return ret;
    };
    imports.wbg.__wbindgen_in = function(arg0, arg1) {
        const ret = getObject(arg0) in getObject(arg1);
        return ret;
    };
    imports.wbg.__wbindgen_boolean_get = function(arg0) {
        const v = getObject(arg0);
        const ret = typeof(v) === 'boolean' ? (v ? 1 : 0) : 2;
        return ret;
    };
    imports.wbg.__wbindgen_is_bigint = function(arg0) {
        const ret = typeof(getObject(arg0)) === 'bigint';
        return ret;
    };
    imports.wbg.__wbindgen_number_get = function(arg0, arg1) {
        const obj = getObject(arg1);
        const ret = typeof(obj) === 'number' ? obj : undefined;
        getDataViewMemory0().setFloat64(arg0 + 8 * 1, isLikeNone(ret) ? 0 : ret, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, !isLikeNone(ret), true);
    };
    imports.wbg.__wbindgen_bigint_from_i64 = function(arg0) {
        const ret = arg0;
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_jsval_eq = function(arg0, arg1) {
        const ret = getObject(arg0) === getObject(arg1);
        return ret;
    };
    imports.wbg.__wbindgen_is_object = function(arg0) {
        const val = getObject(arg0);
        const ret = typeof(val) === 'object' && val !== null;
        return ret;
    };
    imports.wbg.__wbindgen_bigint_from_u64 = function(arg0) {
        const ret = BigInt.asUintN(64, arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_is_string = function(arg0) {
        const ret = typeof(getObject(arg0)) === 'string';
        return ret;
    };
    imports.wbg.__wbindgen_as_number = function(arg0) {
        const ret = +getObject(arg0);
        return ret;
    };
    imports.wbg.__wbindgen_object_clone_ref = function(arg0) {
        const ret = getObject(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_listen_566a928760ed906a = function(arg0, arg1, arg2) {
        const ret = window.__TAURI__.event.listen(getStringFromWasm0(arg0, arg1), getObject(arg2));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_sendamount_unwrap = function(arg0) {
        const ret = SendAmount.__unwrap(takeObject(arg0));
        return ret;
    };
    imports.wbg.__wbg_assetchannelamounts_unwrap = function(arg0) {
        const ret = AssetChannelAmounts.__unwrap(takeObject(arg0));
        return ret;
    };
    imports.wbg.__wbg_asset_unwrap = function(arg0) {
        const ret = Asset.__unwrap(takeObject(arg0));
        return ret;
    };
    imports.wbg.__wbg_bitcoinasset_unwrap = function(arg0) {
        const ret = BitcoinAsset.__unwrap(takeObject(arg0));
        return ret;
    };
    imports.wbg.__wbindgen_try_into_number = function(arg0) {
        let result;
    try { result = +getObject(arg0) } catch (e) { result = e }
    const ret = result;
    return addHeapObject(ret);
};
imports.wbg.__wbg_evmasset_unwrap = function(arg0) {
    const ret = EvmAsset.__unwrap(takeObject(arg0));
    return ret;
};
imports.wbg.__wbg_liquidity_unwrap = function(arg0) {
    const ret = Liquidity.__unwrap(takeObject(arg0));
    return ret;
};
imports.wbg.__wbg_new_abda76e883ba8a5f = function() {
    const ret = new Error();
    return addHeapObject(ret);
};
imports.wbg.__wbg_stack_658279fe44541cf6 = function(arg0, arg1) {
    const ret = getObject(arg1).stack;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};
imports.wbg.__wbg_error_f851667af71bcfc6 = function(arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
        deferred0_0 = arg0;
        deferred0_1 = arg1;
        console.error(getStringFromWasm0(arg0, arg1));
    } finally {
        wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
    }
};
imports.wbg.__wbindgen_jsval_loose_eq = function(arg0, arg1) {
    const ret = getObject(arg0) == getObject(arg1);
    return ret;
};
imports.wbg.__wbg_getwithrefkey_edc2c8960f0f1191 = function(arg0, arg1) {
    const ret = getObject(arg0)[getObject(arg1)];
    return addHeapObject(ret);
};
imports.wbg.__wbg_set_f975102236d3c502 = function(arg0, arg1, arg2) {
    getObject(arg0)[takeObject(arg1)] = takeObject(arg2);
};
imports.wbg.__wbg_fetch_38acb46318f7afdb = function(arg0, arg1) {
    const ret = fetch(getObject(arg0), getObject(arg1));
    return addHeapObject(ret);
};
imports.wbg.__wbg_getReader_ab94afcb5cb7689a = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).getReader();
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_done_2ffa852272310e47 = function(arg0) {
    const ret = getObject(arg0).done;
    return ret;
};
imports.wbg.__wbg_value_9f6eeb1e2aab8d96 = function(arg0) {
    const ret = getObject(arg0).value;
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_cb_drop = function(arg0) {
    const obj = takeObject(arg0).original;
    if (obj.cnt-- == 1) {
        obj.a = 0;
        return true;
    }
    const ret = false;
    return ret;
};
imports.wbg.__wbg_clearTimeout_76877dbc010e786d = function(arg0) {
    const ret = clearTimeout(takeObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_setTimeout_75cb9b6991a4031d = function() { return handleError(function (arg0, arg1) {
    const ret = setTimeout(getObject(arg0), arg1);
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_clearTimeout_541ac0980ffcef74 = function(arg0) {
    const ret = clearTimeout(takeObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_setTimeout_7d81d052875b0f4f = function() { return handleError(function (arg0, arg1) {
    const ret = setTimeout(getObject(arg0), arg1);
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_performance_a1b8bde2ee512264 = function(arg0) {
    const ret = getObject(arg0).performance;
    return addHeapObject(ret);
};
imports.wbg.__wbg_now_abd80e969af37148 = function(arg0) {
    const ret = getObject(arg0).now();
    return ret;
};
imports.wbg.__wbg_fetch_bc7c8e27076a5c84 = function(arg0) {
    const ret = fetch(getObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_crypto_1d1f22824a6a080c = function(arg0) {
    const ret = getObject(arg0).crypto;
    return addHeapObject(ret);
};
imports.wbg.__wbg_process_4a72847cc503995b = function(arg0) {
    const ret = getObject(arg0).process;
    return addHeapObject(ret);
};
imports.wbg.__wbg_versions_f686565e586dd935 = function(arg0) {
    const ret = getObject(arg0).versions;
    return addHeapObject(ret);
};
imports.wbg.__wbg_node_104a2ff8d6ea03a2 = function(arg0) {
    const ret = getObject(arg0).node;
    return addHeapObject(ret);
};
imports.wbg.__wbg_require_cca90b1a94a0255b = function() { return handleError(function () {
    const ret = module.require;
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_msCrypto_eb05e62b530a1508 = function(arg0) {
    const ret = getObject(arg0).msCrypto;
    return addHeapObject(ret);
};
imports.wbg.__wbg_getRandomValues_3aa56aa6edec874c = function() { return handleError(function (arg0, arg1) {
    getObject(arg0).getRandomValues(getObject(arg1));
}, arguments) };
imports.wbg.__wbg_randomFillSync_5c9c955aa56b6049 = function() { return handleError(function (arg0, arg1) {
    getObject(arg0).randomFillSync(takeObject(arg1));
}, arguments) };
imports.wbg.__wbg_fetch_25e3a297f7b04639 = function(arg0) {
    const ret = fetch(getObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_queueMicrotask_48421b3cc9052b68 = function(arg0) {
    const ret = getObject(arg0).queueMicrotask;
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_is_function = function(arg0) {
    const ret = typeof(getObject(arg0)) === 'function';
    return ret;
};
imports.wbg.__wbg_queueMicrotask_12a30234db4045d3 = function(arg0) {
    queueMicrotask(getObject(arg0));
};
imports.wbg.__wbg_instanceof_Window_5012736c80a01584 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof Window;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_setonce_06b35a72a3fafc15 = function(arg0, arg1) {
    getObject(arg0).once = arg1 !== 0;
};
imports.wbg.__wbg_settype_b6ab7b74bd1908a1 = function(arg0, arg1, arg2) {
    getObject(arg0).type = getStringFromWasm0(arg1, arg2);
};
imports.wbg.__wbg_setcode_031a166e87b02684 = function(arg0, arg1) {
    getObject(arg0).code = arg1;
};
imports.wbg.__wbg_setreason_e5db4c3ffeb5419e = function(arg0, arg1, arg2) {
    getObject(arg0).reason = getStringFromWasm0(arg1, arg2);
};
imports.wbg.__wbg_setdetail_a38b239c5694cd1f = function(arg0, arg1) {
    getObject(arg0).detail = getObject(arg1);
};
imports.wbg.__wbg_setbody_734cb3d7ee8e6e96 = function(arg0, arg1) {
    getObject(arg0).body = getObject(arg1);
};
imports.wbg.__wbg_setcache_c75a0b02602d486b = function(arg0, arg1) {
    getObject(arg0).cache = ["default","no-store","reload","no-cache","force-cache","only-if-cached",][arg1];
};
imports.wbg.__wbg_setcredentials_2b67800db3f7b621 = function(arg0, arg1) {
    getObject(arg0).credentials = ["omit","same-origin","include",][arg1];
};
imports.wbg.__wbg_setheaders_be10a5ab566fd06f = function(arg0, arg1) {
    getObject(arg0).headers = getObject(arg1);
};
imports.wbg.__wbg_setintegrity_54bb7edb99d51873 = function(arg0, arg1, arg2) {
    getObject(arg0).integrity = getStringFromWasm0(arg1, arg2);
};
imports.wbg.__wbg_setmethod_dc68a742c2db5c6a = function(arg0, arg1, arg2) {
    getObject(arg0).method = getStringFromWasm0(arg1, arg2);
};
imports.wbg.__wbg_setmode_a781aae2bd3df202 = function(arg0, arg1) {
    getObject(arg0).mode = ["same-origin","no-cors","cors","navigate",][arg1];
};
imports.wbg.__wbg_setredirect_e33e7153977bbd2b = function(arg0, arg1) {
    getObject(arg0).redirect = ["follow","error","manual",][arg1];
};
imports.wbg.__wbg_setreferrer_f7fd8739611aba35 = function(arg0, arg1, arg2) {
    getObject(arg0).referrer = getStringFromWasm0(arg1, arg2);
};
imports.wbg.__wbg_setreferrerpolicy_b3db9b9b13557412 = function(arg0, arg1) {
    getObject(arg0).referrerPolicy = ["","no-referrer","no-referrer-when-downgrade","origin","origin-when-cross-origin","unsafe-url","same-origin","strict-origin","strict-origin-when-cross-origin",][arg1];
};
imports.wbg.__wbg_setsignal_91c4e8ebd04eb935 = function(arg0, arg1) {
    getObject(arg0).signal = getObject(arg1);
};
imports.wbg.__wbg_settype_4c4ad7b3603563e6 = function(arg0, arg1) {
    getObject(arg0).type = ["classic","module",][arg1];
};
imports.wbg.__wbg_signal_41e46ccad44bb5e2 = function(arg0) {
    const ret = getObject(arg0).signal;
    return addHeapObject(ret);
};
imports.wbg.__wbg_new_ebf2727385ee825c = function() { return handleError(function () {
    const ret = new AbortController();
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_abort_8659d889a7877ae3 = function(arg0) {
    getObject(arg0).abort();
};
imports.wbg.__wbg_instanceof_Blob_a959e04f44007d16 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof Blob;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_newwithstrsequenceandoptions_f700d764298e22da = function() { return handleError(function (arg0, arg1) {
    const ret = new Blob(getObject(arg0), getObject(arg1));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_wasClean_e83dfad67198c1c4 = function(arg0) {
    const ret = getObject(arg0).wasClean;
    return ret;
};
imports.wbg.__wbg_code_eae09136895f8ffa = function(arg0) {
    const ret = getObject(arg0).code;
    return ret;
};
imports.wbg.__wbg_reason_8a32e6ed703d6382 = function(arg0, arg1) {
    const ret = getObject(arg1).reason;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};
imports.wbg.__wbg_newwitheventinitdict_f3607d9dd19c79bd = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = new CloseEvent(getStringFromWasm0(arg0, arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_newwitheventinitdict_cc3bd444d90732bb = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = new CustomEvent(getStringFromWasm0(arg0, arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_instanceof_DomException_1bbe86882eadb549 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof DOMException;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_name_fe926223443dc728 = function(arg0, arg1) {
    const ret = getObject(arg1).name;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};
imports.wbg.__wbg_code_4a4b2516783729c7 = function(arg0) {
    const ret = getObject(arg0).code;
    return ret;
};
imports.wbg.__wbg_length_82021578cc4f0d2c = function(arg0) {
    const ret = getObject(arg0).length;
    return ret;
};
imports.wbg.__wbg_get_913f8df8566b2d82 = function(arg0, arg1, arg2) {
    const ret = getObject(arg1)[arg2 >>> 0];
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};
imports.wbg.__wbg_target_b7cb1739bee70928 = function(arg0) {
    const ret = getObject(arg0).target;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};
imports.wbg.__wbg_preventDefault_c55d86c27b2dfa6e = function(arg0) {
    getObject(arg0).preventDefault();
};
imports.wbg.__wbg_addEventListener_e167f012cbedfa4e = function() { return handleError(function (arg0, arg1, arg2, arg3) {
    getObject(arg0).addEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3));
}, arguments) };
imports.wbg.__wbg_addEventListener_14b036ff7cb8747c = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).addEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3), getObject(arg4));
}, arguments) };
imports.wbg.__wbg_dispatchEvent_190760297f28fb3d = function() { return handleError(function (arg0, arg1) {
    const ret = getObject(arg0).dispatchEvent(getObject(arg1));
    return ret;
}, arguments) };
imports.wbg.__wbg_removeEventListener_b6cef5ad085bea8f = function() { return handleError(function (arg0, arg1, arg2, arg3) {
    getObject(arg0).removeEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3));
}, arguments) };
imports.wbg.__wbg_new_e27c93803e1acc42 = function() { return handleError(function () {
    const ret = new Headers();
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_append_f3a4426bb50622c5 = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).append(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
}, arguments) };
imports.wbg.__wbg_instanceof_IdbDatabase_2c9f91b2db322a72 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof IDBDatabase;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_version_85aa50b42026873e = function(arg0) {
    const ret = getObject(arg0).version;
    return ret;
};
imports.wbg.__wbg_objectStoreNames_2fc72464aff4baed = function(arg0) {
    const ret = getObject(arg0).objectStoreNames;
    return addHeapObject(ret);
};
imports.wbg.__wbg_close_7bef29d1d5feecdb = function(arg0) {
    getObject(arg0).close();
};
imports.wbg.__wbg_createObjectStore_cfb780710dbc3ad2 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
    const ret = getObject(arg0).createObjectStore(getStringFromWasm0(arg1, arg2), getObject(arg3));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_deleteObjectStore_745da9b507613eca = function() { return handleError(function (arg0, arg1, arg2) {
    getObject(arg0).deleteObjectStore(getStringFromWasm0(arg1, arg2));
}, arguments) };
imports.wbg.__wbg_transaction_66168ca19ab39a78 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).transaction(getObject(arg1), ["readonly","readwrite","versionchange","readwriteflush","cleanup",][arg2]);
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_deleteDatabase_78cc29fc7dccc638 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).deleteDatabase(getStringFromWasm0(arg1, arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_open_a89af1720976a433 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).open(getStringFromWasm0(arg1, arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_open_e8f45f3526088828 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
    const ret = getObject(arg0).open(getStringFromWasm0(arg1, arg2), arg3 >>> 0);
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_delete_34764ece57bdc720 = function() { return handleError(function (arg0, arg1) {
    const ret = getObject(arg0).delete(getObject(arg1));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_get_88b5e79e9daccb9f = function() { return handleError(function (arg0, arg1) {
    const ret = getObject(arg0).get(getObject(arg1));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_getAllKeys_404d5487a041555d = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).getAllKeys();
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_put_f83d95662936dee7 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).put(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_instanceof_IdbOpenDbRequest_c0d2e9c902441588 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof IDBOpenDBRequest;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_setonupgradeneeded_8f3f0ac5d7130a6f = function(arg0, arg1) {
    getObject(arg0).onupgradeneeded = getObject(arg1);
};
imports.wbg.__wbg_instanceof_IdbRequest_44d99b46adafe829 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof IDBRequest;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_result_fd2dae625828961d = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).result;
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_error_1221bc1f1d0b14d3 = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).error;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_transaction_0549f2d854da77a6 = function(arg0) {
    const ret = getObject(arg0).transaction;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};
imports.wbg.__wbg_setonsuccess_962c293b6e38a5d5 = function(arg0, arg1) {
    getObject(arg0).onsuccess = getObject(arg1);
};
imports.wbg.__wbg_setonerror_bd61d0a61808ca40 = function(arg0, arg1) {
    getObject(arg0).onerror = getObject(arg1);
};
imports.wbg.__wbg_abort_91c8863e70a93d96 = function() { return handleError(function (arg0) {
    getObject(arg0).abort();
}, arguments) };
imports.wbg.__wbg_objectStore_80724f9f6d33ab5b = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).objectStore(getStringFromWasm0(arg1, arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_data_5c47a6985fefc490 = function(arg0) {
    const ret = getObject(arg0).data;
    return addHeapObject(ret);
};
imports.wbg.__wbg_now_a69647afb1f66247 = function(arg0) {
    const ret = getObject(arg0).now();
    return ret;
};
imports.wbg.__wbg_byobRequest_b32c77640da946ac = function(arg0) {
    const ret = getObject(arg0).byobRequest;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};
imports.wbg.__wbg_close_aca7442e6619206b = function() { return handleError(function (arg0) {
    getObject(arg0).close();
}, arguments) };
imports.wbg.__wbg_view_2a901bda0727aeb3 = function(arg0) {
    const ret = getObject(arg0).view;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};
imports.wbg.__wbg_respond_a799bab31a44f2d7 = function() { return handleError(function (arg0, arg1) {
    getObject(arg0).respond(arg1 >>> 0);
}, arguments) };
imports.wbg.__wbg_close_cef2400b120c9c73 = function() { return handleError(function (arg0) {
    getObject(arg0).close();
}, arguments) };
imports.wbg.__wbg_enqueue_6f3d433b5e457aea = function() { return handleError(function (arg0, arg1) {
    getObject(arg0).enqueue(getObject(arg1));
}, arguments) };
imports.wbg.__wbg_read_e48a676fb81ea800 = function(arg0) {
    const ret = getObject(arg0).read();
    return addHeapObject(ret);
};
imports.wbg.__wbg_releaseLock_1d2d93e9dc8d76e2 = function(arg0) {
    getObject(arg0).releaseLock();
};
imports.wbg.__wbg_cancel_97a2795574a4f522 = function(arg0) {
    const ret = getObject(arg0).cancel();
    return addHeapObject(ret);
};
imports.wbg.__wbg_newwithstrandinit_a31c69e4cc337183 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = new Request(getStringFromWasm0(arg0, arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_instanceof_Response_e91b7eb7c611a9ae = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof Response;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_url_1bf85c8abeb8c92d = function(arg0, arg1) {
    const ret = getObject(arg1).url;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};
imports.wbg.__wbg_status_ae8de515694c5c7c = function(arg0) {
    const ret = getObject(arg0).status;
    return ret;
};
imports.wbg.__wbg_headers_5e283e8345689121 = function(arg0) {
    const ret = getObject(arg0).headers;
    return addHeapObject(ret);
};
imports.wbg.__wbg_body_40b0ed27714d00ce = function(arg0) {
    const ret = getObject(arg0).body;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};
imports.wbg.__wbg_arrayBuffer_a5fbad63cc7e663b = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).arrayBuffer();
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_text_a94b91ea8700357a = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).text();
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_createObjectURL_ca544150f40fb1bf = function() { return handleError(function (arg0, arg1) {
    const ret = URL.createObjectURL(getObject(arg1));
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
}, arguments) };
imports.wbg.__wbg_url_b978bb23c341ca85 = function(arg0, arg1) {
    const ret = getObject(arg1).url;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};
imports.wbg.__wbg_readyState_7237e2b1adac03a6 = function(arg0) {
    const ret = getObject(arg0).readyState;
    return ret;
};
imports.wbg.__wbg_setonopen_7e770c87269cae90 = function(arg0, arg1) {
    getObject(arg0).onopen = getObject(arg1);
};
imports.wbg.__wbg_setonerror_5ec4625df3060159 = function(arg0, arg1) {
    getObject(arg0).onerror = getObject(arg1);
};
imports.wbg.__wbg_setonclose_40f935717ad6ffcd = function(arg0, arg1) {
    getObject(arg0).onclose = getObject(arg1);
};
imports.wbg.__wbg_setonmessage_b670c12ea34acd8b = function(arg0, arg1) {
    getObject(arg0).onmessage = getObject(arg1);
};
imports.wbg.__wbg_setbinaryType_d164a0be4c212c9c = function(arg0, arg1) {
    getObject(arg0).binaryType = ["blob","arraybuffer",][arg1];
};
imports.wbg.__wbg_new_0bf4a5b0632517ed = function() { return handleError(function (arg0, arg1) {
    const ret = new WebSocket(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_newwithstrsequence_95750c7542ecfdb3 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = new WebSocket(getStringFromWasm0(arg0, arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_close_99bb12a22f16f79c = function() { return handleError(function (arg0) {
    getObject(arg0).close();
}, arguments) };
imports.wbg.__wbg_send_82b52e2f9f8946d9 = function() { return handleError(function (arg0, arg1, arg2) {
    getObject(arg0).send(getStringFromWasm0(arg1, arg2));
}, arguments) };
imports.wbg.__wbg_send_1b333b26681a902d = function() { return handleError(function (arg0, arg1, arg2) {
    getObject(arg0).send(getArrayU8FromWasm0(arg1, arg2));
}, arguments) };
imports.wbg.__wbg_indexedDB_1f9ee79bddf7d011 = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).indexedDB;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_get_8917711b0035218d = function(arg0, arg1, arg2) {
    const ret = getObject(arg0)[getStringFromWasm0(arg1, arg2)];
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};
imports.wbg.__wbg_setTimeout_73b734ca971c19f4 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).setTimeout(getObject(arg1), arg2);
    return ret;
}, arguments) };
imports.wbg.__wbg_newwithoptions_1d8b2a34000b563b = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = new Worker(getStringFromWasm0(arg0, arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_postMessage_37faac1bc005e5c0 = function() { return handleError(function (arg0, arg1) {
    getObject(arg0).postMessage(getObject(arg1));
}, arguments) };
imports.wbg.__wbg_instanceof_WorkerGlobalScope_e34c8a505711a78e = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof WorkerGlobalScope;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_indexedDB_9d299adf9543d0c3 = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).indexedDB;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_fetch_ba7fe179e527d942 = function(arg0, arg1) {
    const ret = getObject(arg0).fetch(getObject(arg1));
    return addHeapObject(ret);
};
imports.wbg.__wbg_fetch_cb9aad23a79a40a1 = function(arg0, arg1, arg2) {
    const ret = getObject(arg0).fetch(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
};
imports.wbg.__wbg_setTimeout_36657c408b0951b6 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).setTimeout(getObject(arg1), arg2);
    return ret;
}, arguments) };
imports.wbg.__wbg_debug_5a33c41aeac15ee6 = function(arg0) {
    console.debug(getObject(arg0));
};
imports.wbg.__wbg_debug_d7780810b3a93632 = function(arg0, arg1, arg2, arg3) {
    console.debug(getObject(arg0), getObject(arg1), getObject(arg2), getObject(arg3));
};
imports.wbg.__wbg_error_09480e4aadca50ad = function(arg0) {
    console.error(getObject(arg0));
};
imports.wbg.__wbg_error_f02f3d66b42c6251 = function(arg0, arg1, arg2, arg3) {
    console.error(getObject(arg0), getObject(arg1), getObject(arg2), getObject(arg3));
};
imports.wbg.__wbg_info_c261acb2deacd903 = function(arg0) {
    console.info(getObject(arg0));
};
imports.wbg.__wbg_info_123d8c35ec14384a = function(arg0, arg1, arg2, arg3) {
    console.info(getObject(arg0), getObject(arg1), getObject(arg2), getObject(arg3));
};
imports.wbg.__wbg_warn_2b3adb99ce26c314 = function(arg0) {
    console.warn(getObject(arg0));
};
imports.wbg.__wbg_warn_60924fcf321399f0 = function(arg0, arg1, arg2, arg3) {
    console.warn(getObject(arg0), getObject(arg1), getObject(arg2), getObject(arg3));
};
imports.wbg.__wbg_self_3093d5d1f7bcb682 = function() { return handleError(function () {
    const ret = self.self;
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_window_3bcfc4d31bc012f8 = function() { return handleError(function () {
    const ret = window.window;
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_globalThis_86b222e13bdf32ed = function() { return handleError(function () {
    const ret = globalThis.globalThis;
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_global_e5a3fe56f8be9485 = function() { return handleError(function () {
    const ret = global.global;
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_newnoargs_76313bd6ff35d0f2 = function(arg0, arg1) {
    const ret = new Function(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};
imports.wbg.__wbg_call_1084a111329e68ce = function() { return handleError(function (arg0, arg1) {
    const ret = getObject(arg0).call(getObject(arg1));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_get_3baa728f9d58d3f6 = function(arg0, arg1) {
    const ret = getObject(arg0)[arg1 >>> 0];
    return addHeapObject(ret);
};
imports.wbg.__wbg_length_ae22078168b726f5 = function(arg0) {
    const ret = getObject(arg0).length;
    return ret;
};
imports.wbg.__wbg_new_a220cf903aa02ca2 = function() {
    const ret = new Array();
    return addHeapObject(ret);
};
imports.wbg.__wbg_new_8608a2b51a5f6737 = function() {
    const ret = new Map();
    return addHeapObject(ret);
};
imports.wbg.__wbg_next_de3e9db4440638b2 = function(arg0) {
    const ret = getObject(arg0).next;
    return addHeapObject(ret);
};
imports.wbg.__wbg_next_f9cb570345655b9a = function() { return handleError(function (arg0) {
    const ret = getObject(arg0).next();
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_done_bfda7aa8f252b39f = function(arg0) {
    const ret = getObject(arg0).done;
    return ret;
};
imports.wbg.__wbg_value_6d39332ab4788d86 = function(arg0) {
    const ret = getObject(arg0).value;
    return addHeapObject(ret);
};
imports.wbg.__wbg_iterator_888179a48810a9fe = function() {
    const ret = Symbol.iterator;
    return addHeapObject(ret);
};
imports.wbg.__wbg_get_224d16597dbbfd96 = function() { return handleError(function (arg0, arg1) {
    const ret = Reflect.get(getObject(arg0), getObject(arg1));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_new_525245e2b9901204 = function() {
    const ret = new Object();
    return addHeapObject(ret);
};
imports.wbg.__wbg_eval_6e4fc17d87772f52 = function() { return handleError(function (arg0, arg1) {
    const ret = eval(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_newwithlength_b5660ad84eb3e8a9 = function(arg0) {
    const ret = new Array(arg0 >>> 0);
    return addHeapObject(ret);
};
imports.wbg.__wbg_set_673dda6c73d19609 = function(arg0, arg1, arg2) {
    getObject(arg0)[arg1 >>> 0] = takeObject(arg2);
};
imports.wbg.__wbg_from_0791d740a9d37830 = function(arg0) {
    const ret = Array.from(getObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_isArray_8364a5371e9737d8 = function(arg0) {
    const ret = Array.isArray(getObject(arg0));
    return ret;
};
imports.wbg.__wbg_of_4a1c869ef05b4b73 = function(arg0) {
    const ret = Array.of(getObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_push_37c89022f34c01ca = function(arg0, arg1) {
    const ret = getObject(arg0).push(getObject(arg1));
    return ret;
};
imports.wbg.__wbg_instanceof_ArrayBuffer_61dfc3198373c902 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof ArrayBuffer;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_instanceof_Error_69bde193b0cc95e3 = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof Error;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_new_796382978dfd4fb0 = function(arg0, arg1) {
    const ret = new Error(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};
imports.wbg.__wbg_message_e18bae0a0e2c097a = function(arg0) {
    const ret = getObject(arg0).message;
    return addHeapObject(ret);
};
imports.wbg.__wbg_name_ac78212e803c7941 = function(arg0) {
    const ret = getObject(arg0).name;
    return addHeapObject(ret);
};
imports.wbg.__wbg_toString_9d18e102ca933e68 = function(arg0) {
    const ret = getObject(arg0).toString();
    return addHeapObject(ret);
};
imports.wbg.__wbg_call_89af060b4e1523f2 = function() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_instanceof_Map_763ce0e95960d55e = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof Map;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_forEach_7a5ec5c2efb50a6d = function(arg0, arg1, arg2) {
    try {
        var state0 = {a: arg1, b: arg2};
        var cb0 = (arg0, arg1) => {
            const a = state0.a;
            state0.a = 0;
            try {
                return __wbg_adapter_1038(a, state0.b, arg0, arg1);
            } finally {
                state0.a = a;
            }
        };
        getObject(arg0).forEach(cb0);
    } finally {
        state0.a = state0.b = 0;
    }
};
imports.wbg.__wbg_set_49185437f0ab06f8 = function(arg0, arg1, arg2) {
    const ret = getObject(arg0).set(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
};
imports.wbg.__wbg_isSafeInteger_7f1ed56200d90674 = function(arg0) {
    const ret = Number.isSafeInteger(getObject(arg0));
    return ret;
};
imports.wbg.__wbg_getTime_91058879093a1589 = function(arg0) {
    const ret = getObject(arg0).getTime();
    return ret;
};
imports.wbg.__wbg_new0_65387337a95cf44d = function() {
    const ret = new Date();
    return addHeapObject(ret);
};
imports.wbg.__wbg_now_b7a162010a9e75b4 = function() {
    const ret = Date.now();
    return ret;
};
imports.wbg.__wbg_entries_7a0e06255456ebcd = function(arg0) {
    const ret = Object.entries(getObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_toString_e17a6671146f47c1 = function(arg0) {
    const ret = getObject(arg0).toString();
    return addHeapObject(ret);
};
imports.wbg.__wbg_has_4bfbc01db38743f7 = function() { return handleError(function (arg0, arg1) {
    const ret = Reflect.has(getObject(arg0), getObject(arg1));
    return ret;
}, arguments) };
imports.wbg.__wbg_buffer_b7b08af79b0b0974 = function(arg0) {
    const ret = getObject(arg0).buffer;
    return addHeapObject(ret);
};
imports.wbg.__wbg_stringify_bbf45426c92a6bf5 = function() { return handleError(function (arg0) {
    const ret = JSON.stringify(getObject(arg0));
    return addHeapObject(ret);
}, arguments) };
imports.wbg.__wbg_new_b85e72ed1bfd57f9 = function(arg0, arg1) {
    try {
        var state0 = {a: arg0, b: arg1};
        var cb0 = (arg0, arg1) => {
            const a = state0.a;
            state0.a = 0;
            try {
                return __wbg_adapter_1038(a, state0.b, arg0, arg1);
            } finally {
                state0.a = a;
            }
        };
        const ret = new Promise(cb0);
        return addHeapObject(ret);
    } finally {
        state0.a = state0.b = 0;
    }
};
imports.wbg.__wbg_resolve_570458cb99d56a43 = function(arg0) {
    const ret = Promise.resolve(getObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_catch_a279b1da46d132d8 = function(arg0, arg1) {
    const ret = getObject(arg0).catch(getObject(arg1));
    return addHeapObject(ret);
};
imports.wbg.__wbg_then_95e6edc0f89b73b1 = function(arg0, arg1) {
    const ret = getObject(arg0).then(getObject(arg1));
    return addHeapObject(ret);
};
imports.wbg.__wbg_then_876bb3c633745cc6 = function(arg0, arg1, arg2) {
    const ret = getObject(arg0).then(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
};
imports.wbg.__wbg_newwithbyteoffsetandlength_8a2cb9ca96b27ec9 = function(arg0, arg1, arg2) {
    const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
};
imports.wbg.__wbg_new_ea1883e1e5e86686 = function(arg0) {
    const ret = new Uint8Array(getObject(arg0));
    return addHeapObject(ret);
};
imports.wbg.__wbg_set_d1e79e2388520f18 = function(arg0, arg1, arg2) {
    getObject(arg0).set(getObject(arg1), arg2 >>> 0);
};
imports.wbg.__wbg_length_8339fcf5d8ecd12e = function(arg0) {
    const ret = getObject(arg0).length;
    return ret;
};
imports.wbg.__wbg_instanceof_Uint8Array_247a91427532499e = function(arg0) {
    let result;
    try {
        result = getObject(arg0) instanceof Uint8Array;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};
imports.wbg.__wbg_newwithlength_ec548f448387c968 = function(arg0) {
    const ret = new Uint8Array(arg0 >>> 0);
    return addHeapObject(ret);
};
imports.wbg.__wbg_buffer_0710d1b9dbe2eea6 = function(arg0) {
    const ret = getObject(arg0).buffer;
    return addHeapObject(ret);
};
imports.wbg.__wbg_subarray_7c2e3576afe181d1 = function(arg0, arg1, arg2) {
    const ret = getObject(arg0).subarray(arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
};
imports.wbg.__wbg_byteLength_850664ef28f3e42f = function(arg0) {
    const ret = getObject(arg0).byteLength;
    return ret;
};
imports.wbg.__wbg_byteOffset_ea14c35fa6de38cc = function(arg0) {
    const ret = getObject(arg0).byteOffset;
    return ret;
};
imports.wbg.__wbindgen_bigint_get_as_i64 = function(arg0, arg1) {
    const v = getObject(arg1);
    const ret = typeof(v) === 'bigint' ? v : undefined;
    getDataViewMemory0().setBigInt64(arg0 + 8 * 1, isLikeNone(ret) ? BigInt(0) : ret, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, !isLikeNone(ret), true);
};
imports.wbg.__wbindgen_debug_string = function(arg0, arg1) {
    const ret = debugString(getObject(arg1));
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};
imports.wbg.__wbindgen_throw = function(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};
imports.wbg.__wbindgen_module = function() {
    const ret = __wbg_init.__wbindgen_wasm_module;
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_memory = function() {
    const ret = wasm.memory;
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper18441 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 4478, __wbg_adapter_58);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper18891 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 4598, __wbg_adapter_61);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper18952 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 4610, __wbg_adapter_64);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper29195 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6248, __wbg_adapter_67);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper29197 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6248, __wbg_adapter_70);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper29199 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6248, __wbg_adapter_67);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper29201 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6248, __wbg_adapter_67);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper30726 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6486, __wbg_adapter_77);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper31433 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6594, __wbg_adapter_80);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper31464 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6594, __wbg_adapter_83);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper31633 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 6655, __wbg_adapter_86);
    return addHeapObject(ret);
};
imports.wbg.__wbindgen_closure_wrapper35480 = function(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 7078, __wbg_adapter_89);
    return addHeapObject(ret);
};

return imports;
}

function __wbg_init_memory(imports, memory) {

}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedDataViewMemory0 = null;
    cachedUint8ArrayMemory0 = null;


    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;


    if (typeof module !== 'undefined' && Object.getPrototypeOf(module) === Object.prototype)
    ({module} = module)
    else
    console.warn('using deprecated parameters for `initSync()`; pass a single object instead')

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
    if (wasm !== undefined) return wasm;


    if (typeof module_or_path !== 'undefined' && Object.getPrototypeOf(module_or_path) === Object.prototype)
    ({module_or_path} = module_or_path)
    else
    console.warn('using deprecated parameters for the initialization function; pass a single object instead')

    if (typeof module_or_path === 'undefined') {
        module_or_path = new URL('hydra_node_bg.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (typeof module_or_path === 'string' || (typeof Request === 'function' && module_or_path instanceof Request) || (typeof URL === 'function' && module_or_path instanceof URL)) {
        module_or_path = fetch(module_or_path);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(await module_or_path, imports);

    return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
