import type { RouterConfig } from '@nuxt/schema';
import type { RouteRecordRaw } from '#vue-router';
import { PRO_APP_ROUTES } from '~/constants';

export default {
  routes: (_routes) => {
    const additionalRoutes: RouteRecordRaw[] = [];
    if (!import.meta.env.PROD) {
      const styleguideRoutes = _routes.filter(route => route.path.startsWith('/styleguide'));
      additionalRoutes.push(...styleguideRoutes);
    }

    return [
      ...additionalRoutes,
      {
        ...PRO_APP_ROUTES.wallet,
        component: () => import('~/pages/(pro)/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.channels,
        component: () => import('~/pages/(pro)/channels/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.channels.children.peer.network,
        component: () => import('~/pages/(pro)/channels/[peer]/[network]/[id]/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.channels.children.peer.asset,
        component: () => import('~/pages/(pro)/channels/[peer]/[network]/[id]/[symbol]/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.transactions,
        component: () => import('~/pages/(pro)/transactions/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.transactions.children.details,
        component: () => import('~/pages/(pro)/transactions/details/[id]/index.vue').then(r => r.default || r)
      }
    ];
  }
} satisfies RouterConfig;
