import Big from 'big.js';
import { U256 } from 'hydra-node';
import { SATOSHIS_PER_BITCOIN } from '~/constants';

export const convertSafelyBigIntToNumber = (bigInt: bigint) => {
  const asNumber = Number(bigInt); // Convert BigInt to number

  if (Number.isSafeInteger(asNumber)) {
    return asNumber;
  } else {
    console.error('BigInt value is too large to safely convert to a number');
    return -1;
  }
};

export function satsToBitcoin (sats?: bigint | U256): number {
  const _sats = typeof sats === 'bigint' ? sats : BigInt(sats?.toString() || 0);

  const big = new Big(_sats);
  return Number(big.div(SATOSHIS_PER_BITCOIN));
}

export function bitcoinToSats (btc:number|string) {
  const _btc = typeof btc === 'string' ? Number(btc) : btc;
  const bigBtc = new Big(_btc);
  return Number(bigBtc.times(SATOSHIS_PER_BITCOIN));
}
