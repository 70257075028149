import { defineStore } from 'pinia';
import { useCurrencyStore } from './shared/currency';
import init, {
  init as hydraNodeInit,
  BitcoinNetwork,
  Mnemonic,
  EvmNetwork,
  EvmAddress,
  BitcoinBlockchainConfig,
  BitcoinSignerConfig,
  BitcoinLightningNodeConfig,
  BitcoinConfig,
  EvmBlockchainConfig,
  EvmSignerConfig,
  EvmLithiumNodeConfig,
  EvmConfig,
  bitcoinInit,
  evmInit,
  Config,
  changeConfig
  , FiatCurrency,
  LogLevel
} from 'hydra-node';
import { FetchFiatPriceError } from '~/types/Error';
import { useSettingsStore } from '~/stores/shared/settings';
import { PiniaStoresId } from '~/enums';

export const useHydraNodeStore = defineStore(PiniaStoresId.HydraNodeStore, () => {
  const hasError = ref<boolean>(false);
  const hasCoreNodeInitialized = ref<boolean>(false);
  const isPackageInitialized = ref<boolean>(false);
  const mnemonic = ref<Mnemonic | null>(null);
  const btcLastSynced = ref<number>(0);
  const evmLastSynced = ref<number>(0);

  const { fetchCurrencies } = useCurrencyStore();
  const toast = useToast();
  const { t } = useI18n();
  const { preferences } = useSettingsStore();

  const hasNodeLostConnection = computed(() => hasError.value && hasCoreNodeInitialized.value);

  const hasNodeInitializationError = computed(() => hasError.value && !hasCoreNodeInitialized.value);

  const isNodeInitializing = computed(() => !hasError.value &&
    !hasCoreNodeInitialized.value &&
    btcLastSynced.value === 0 &&
    evmLastSynced.value === 0);

  const isNodeFullySynced = computed(() =>
    !hasError.value &&
    hasCoreNodeInitialized.value &&
    btcLastSynced.value > 0 &&
    evmLastSynced.value > 0);

  async function initializeHydraNode (): Promise<boolean> {
    if (!isPackageInitialized) {
      // TODO throw error instead and handle it in the ui boundary
      createError('Package not initialized');
      return false;
    }
    const {
      // EVM
      VITE_LITHIUM_CONTRACT_ETH_HOLESKY,
      VITE_WEB3_PROVIDER_URL,
      VITE_LITHIUM_SUBGRAPH_ETHEREUM_HOLESKY_URL,
      VITE_LITHIUM_PROXY,
      VITE_COVALENT_API_KEY,
      VITE_COVALENT_API_URL,
      VITE_ALCHEMY_WS_URL,
      // BTC
      VITE_ESPLORA_TESTNET_URL,
      VITE_RAPID_GOSSIP_SYNC_URL,
      VITE_WS_PROXY,
      VITE_CMC_URL,
      VITE_CMC_API_KEY,
      VITE_RENTAL_MANAGER_URL
    } = import.meta.env;

    const currencyPreference = preferences.currency?.enumCodeReference || FiatCurrency.USD;

    const config = new Config('hydranet-app', VITE_CMC_URL, VITE_CMC_API_KEY, [currencyPreference], VITE_RENTAL_MANAGER_URL, undefined, LogLevel.Warn);

    try {
      await hydraNodeInit(config).then(() => {
        const bitcoinConfig = getBitcoinNodeConfig(VITE_ESPLORA_TESTNET_URL, mnemonic.value!, VITE_RAPID_GOSSIP_SYNC_URL, VITE_WS_PROXY);
        const evmConfig = getEvmNodeConfig(VITE_WEB3_PROVIDER_URL, VITE_COVALENT_API_KEY, VITE_COVALENT_API_URL, VITE_ALCHEMY_WS_URL, mnemonic.value!, VITE_LITHIUM_CONTRACT_ETH_HOLESKY, VITE_LITHIUM_SUBGRAPH_ETHEREUM_HOLESKY_URL, VITE_LITHIUM_PROXY);
        bitcoinInit(BitcoinNetwork.Signet, bitcoinConfig);
        evmInit(EvmNetwork.EthereumHolesky, evmConfig);
      });
      hasCoreNodeInitialized.value = true;
      await fetchCurrencies(currencyPreference);
      return true;
    } catch (err) {
      if (err instanceof FetchFiatPriceError) {
        toast.add({
          id: `fetch-currency-error-${err.assetSymbol}`,
          title: t('node.fetching-currencies.error.title', { asset: err.assetSymbol }),
          description: t('node.fetching-currencies.error.description'),
          color: 'red',
          icon: 'i-heroicons-exclamation-triangle',
          closeButton: {
            icon: ''
          }
        });
        return true;
      } else {
        console.debug('Error while initializing hydra node', err);
        hasError.value = true;
        hasCoreNodeInitialized.value = false;
        return false;
      }
    }
  }

  async function initializePackage () {
    try {
      const hydraResponse = await fetch(
        new URL('hydra-node/hydra_node_bg.wasm', import.meta.url)
      );
      const hydraBuffer = await hydraResponse.arrayBuffer();
      await init(new Uint8Array(hydraBuffer));
      isPackageInitialized.value = true;
    } catch (error) {
      hasError.value = true;
      isPackageInitialized.value = false;
      return false;
    }
  }

  async function changeCurrency (currency: FiatCurrency) {
    const {
      VITE_CMC_URL,
      VITE_CMC_API_KEY,
      VITE_RENTAL_MANAGER_URL
    } = import.meta.env;
    const config = new Config('hydranet-app', VITE_CMC_URL, VITE_CMC_API_KEY, [currency], VITE_RENTAL_MANAGER_URL, LogLevel.Warn);
    await changeConfig(config);
  }

  async function resetNode () {
    hasError.value = false;
    hasCoreNodeInitialized.value = false;
    btcLastSynced.value = 0;
    evmLastSynced.value = 0;
    await initializeHydraNode();
  }

  // For now only newNode, as we do not support external signer yet
  // newWatchOnlyClient() if he inputs only the xpub and fingerprint of his wallet
  // newWatchOnlyNode() if he inputs the xpub and fingerprint of his wallet and the lightning node id

  // In case the user has a signer extension:
  // newClient() if he has an extension that can sign only onchain txns and not lightning messages and doesn't input the lightning node id manually
  // newWatchOnlyNodeWithSigner() if he has an extension that can sign only onchain txns and not lightning messages but inputs his node id manually
  // newNode() if he has an extension that can sign also lightning messages

  function getBitcoinNodeConfig (esploraUrl: string, mnemonic: Mnemonic, rapidGossipUrl: string, wsProxyUrl: string) {
    const blockchainConfig = BitcoinBlockchainConfig.newEsplora(esploraUrl);
    const signerConfig = BitcoinSignerConfig.newHot(mnemonic, '');
    const bitcoinNodeConfig = new BitcoinLightningNodeConfig(rapidGossipUrl, wsProxyUrl);
    return BitcoinConfig.newNode(blockchainConfig, signerConfig, bitcoinNodeConfig);
  }

  function getEvmNodeConfig (web3providerUrl: string, covalentApiKey: string, covalentApiUrl:string, alchemyWsUrl: string, mnemonic: Mnemonic, contractAddress: string, subgraph: string, proxy: string) {
    const blockchainConfig = new EvmBlockchainConfig(web3providerUrl, covalentApiUrl, covalentApiKey, alchemyWsUrl);
    const signerConfig = EvmSignerConfig.newHot(mnemonic, '');
    const evmLithiumChannelConfig = new EvmLithiumNodeConfig(EvmAddress.fromString(contractAddress), subgraph, proxy);
    return EvmConfig.newNode(blockchainConfig, signerConfig, evmLithiumChannelConfig);
  }

  return {
    isPackageInitialized,
    initializeHydraNode,
    hasCoreNodeInitialized,
    initializePackage,
    resetNode,
    mnemonic,
    changeCurrency,
    hasError,
    hasNodeLostConnection,
    evmLastSynced,
    btcLastSynced,
    hasNodeInitializationError,
    isNodeInitializing,
    isNodeFullySynced
  };
});
