import { z } from 'zod';
import type { InvoiceZodContext, SendZodContext, Translator } from '~/types';

export const AddressSchemaObject = z.object({
  address: z.string().nonempty('form-schemas.errors.address.required_error').min(25, 'form-schemas.errors.address.required_error')
});

export const AmountSchemaObject = z.object({
  amount: z.number({
    required_error: 'form-schemas.errors.amount.required_error',
    invalid_type_error: 'form-schemas.errors.amount.invalid_type_error'
  }).positive({
    message: 'form-schemas.errors.amount.positive_error'
  }).finite()
});

export const ValiditySchemaObject = z.object({
  validity: z.number({
    required_error: 'form-schemas.errors.validity.required',
    invalid_type_error: 'form-schemas.errors.validity.invalid_type_error'
  }).positive({
    message: 'form-schemas.errors.validity.positive_error'
  }).finite()
});

export const SendAmountSchema = (context: SendZodContext) => z.object({
  amount: z.number({
    required_error: 'form-schemas.errors.amount.required_error',
    invalid_type_error: 'form-schemas.errors.amount.invalid_type_error'
  }).positive({
    message: 'form-schemas.errors.amount.positive_error'
  }).refine((val) => {
    return hasEnoughBalance(context.layer, context.assetBalance!, val);
  }, {
    message: 'generics.not-enough-balance'
  })
});

export const InvoiceAmountSchema = (context: InvoiceZodContext) => z.object({
  amount: z.number({
    required_error: 'form-schemas.errors.amount.required_error',
    invalid_type_error: 'form-schemas.errors.amount.invalid_type_error'
  }).finite().refine(val => context.isSend ? val > 0 : val >= 0, {
    // Only require positive value if isSend is true
    message: 'form-schemas.errors.amount.positive_error'
  }).refine((val) => {
    // Additional validation for balance
    return context.isSend ? hasEnoughBalance(context.layer, context.invoiceAssetBalance!, val) : true;
  }, {
    message: 'generics.not-enough-balance'
  })
});

export const InvoiceAddressSchema = (context: InvoiceZodContext) => z.object({
  invoice: context.isSend
    ? z.string()
      .nonempty('form-schemas.errors.address.required_error')
      .min(25, 'form-schemas.errors.address.required_error')
    : z.string().optional()
});

export const baseAmountSchema = (translator: Translator) => z.number({
  required_error: translator('form-schemas.errors.amount.required_error'),
  invalid_type_error: translator('form-schemas.errors.amount.invalid_type_error')
}).positive({
  message: translator('form-schemas.errors.amount.positive_error')
}).finite();
