import { defineStore } from 'pinia';
import { useColorMode, useLocalStorage } from '@vueuse/core';
import colors from 'tailwindcss/colors';
import cloneDeep from 'lodash/cloneDeep';
import type { ColorOption, Theme, ThemeOption } from '~/types/theme';
import currencies from '~/config/supported-currencies.json';
import timezones from '~/config/supported-timezones.json';
import { i18n } from '~/config/i18n';
import { FiatCurrency } from 'hydra-node';
import { PiniaStoresId } from '~/enums';

export const useSettingsStore = defineStore(PiniaStoresId.SettingsStore, () => {
  const DEFAULT_CURRENCY_USD = cloneDeep(currencies).find(currency => currency.enumCodeReference === FiatCurrency.USD);
  const DEFAULT_TIMEZONE_UTC = cloneDeep(timezones).find(timezone => timezone.city === 'Berlin');
  const DEFAULT_PRIMARY_COLOR = 'indigo';
  const DEFAULT_GRAY_TONE = 'slate';
  const DEFAULT_THEME = 'auto';

  const appConfig = useAppConfig();
  const colorMode = useColorMode({
    storageKey: 'nuxt-color-mode'
  });
  const _primaryColor = useLocalStorage('nuxt-ui-primary', '');
  const _grayTone = useLocalStorage('nuxt-ui-gray', '');

  onMounted(() => {
    if (!_primaryColor.value) {
      primaryColor.value = getColorOptionFromString(DEFAULT_PRIMARY_COLOR);
    }
    if (!_grayTone.value) {
      grayTone.value = getColorOptionFromString(DEFAULT_GRAY_TONE);
    }

    // fix legacy compatibility where system was set instead of auto
    if (colorMode.value === 'system') {
      colorMode.value = 'auto';
    }
  });

  const theme = computed({
    get () {
      return getThemeOptionFromString(colorMode.value);
    },
    set (option: ThemeOption) {
      colorMode.value = option.value;
    }
  });

  const primaryColor = computed({
    get () {
      return getColorOptionFromString(_primaryColor.value);
    },
    set (option: ColorOption) {
      if (!option.value) {
        option.value = DEFAULT_PRIMARY_COLOR;
      }
      _primaryColor.value = option.value;
      appConfig.ui.primary = option.value;
    }
  });

  const grayTone = computed({
    get () {
      return getColorOptionFromString(_grayTone.value);
    },
    set (option: ColorOption) {
      if (!option.value) {
        option.value = DEFAULT_GRAY_TONE;
      }
      _grayTone.value = option.value;
      appConfig.ui.gray = option.value;
    }
  });

  const preferences = reactive({
    persistMnemonic: false,
    currency: DEFAULT_CURRENCY_USD,
    timezone: DEFAULT_TIMEZONE_UTC,
    is12Hour: false
  });

  const primaryColors = computed<ColorOption[]>(() => appConfig.ui.colors.filter(color => color !== 'primary')
    .map(color => getColorOptionFromString(color))
  );

  const grayTones = computed<ColorOption[]>(() => ['slate', 'coolGray', 'zinc', 'neutral', 'stone'].map(color =>
    getColorOptionFromString(color)
  ));

  const themeOptions = computed<ThemeOption[]>(() => ['light', 'dark'].map(theme => getThemeOptionFromString(theme))
  );

  function resetToDefaults () {
    primaryColor.value = getColorOptionFromString(DEFAULT_PRIMARY_COLOR);
    grayTone.value = getColorOptionFromString(DEFAULT_GRAY_TONE);
    colorMode.value = DEFAULT_THEME;
    preferences.currency = DEFAULT_CURRENCY_USD;
    preferences.timezone = DEFAULT_TIMEZONE_UTC;
    preferences.is12Hour = false;
  }

  function getColorOptionFromString (color: string): ColorOption {
    const _color = colors[color as keyof typeof colors][colorMode.value === 'dark' ? 400 : 500];

    return {
      value: color,
      label: i18n.t('dynamic.settings.modal.preferences.color.' + color),
      chip: _color.substring(1)
    };
  }

  function getThemeOptionFromString (theme: Theme): ThemeOption & { click:Function } {
    const sunIcon = 'i-heroicons-sun';
    const moonIcon = 'i-heroicons-moon';
    return {
      value: theme,
      label: i18n.t('dynamic.settings.modal.preferences.' + theme),
      icon: theme === 'light' ? sunIcon : moonIcon,
      click: () => {
        colorMode.value = theme;
      }
    };
  }

  return {
    DEFAULT_CURRENCY_USD,
    DEFAULT_TIMEZONE_UTC,
    colorMode,
    theme,
    primaryColor,
    grayTone,
    preferences,
    timezones,
    currencies,
    primaryColors,
    grayTones,
    themeOptions,
    resetToDefaults
  };
},
{
  persist: {
    storage: localStorage,
    paths: ['preferences']
  }
}
);
