import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/build/repo/app/router.options.ts";
import routerOptions2 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_qrcode@1.5.4_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
...routerOptions2,
}