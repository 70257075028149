import { defineStore } from 'pinia';
import { useFuse } from '@vueuse/integrations/useFuse';
import { useCurrencyStore } from './currency';
import { AssetBalance, getBalances } from 'hydra-node';
import type { TableFilters } from '~/types';
import { filterFns, getNetworkName } from '~/utils';

import type { MappedAssetBalance } from '~/types/WalletAsset';
interface State {
  assetsBalances: MappedAssetBalance[];
  selectedAsset: AssetBalance | null;
  isAssetDetailsSliderOpen: boolean;
  filters: TableFilters;
  hasFetched: boolean;
  lastFetched: number;
}
export const useWalletStore = defineStore('wallet', {
  state: (): State => ({
    assetsBalances: [],
    selectedAsset: null,
    hasFetched: false,
    isAssetDetailsSliderOpen: false,
    lastFetched: 0,
    filters: {
      networks: [],
      globalSearch: ''
    }
  }),
  getters: {
    hasActiveFilters: (state) => {
      return Object.values(state.filters).some(x => x?.length);
    },
    getFilteredAssets: (state): MappedAssetBalance[] => {
      // not really optimal to do all of these mapping ton the data
      let dataToFilter = state.assetsBalances;

      if (state.filters.globalSearch.length) {
        const { results } = useFuse(state.filters.globalSearch, dataToFilter, {
          fuseOptions: {
            keys: ['asset.symbol', 'asset.name', 'fiatTotal'],
            threshold: 0.3
          }
        });

        dataToFilter = results.value.map(x => x.item);
      }

      return dataToFilter
        .map((row) => {
          let isMatchingNetwork = true;
          if (state.filters.networks && state.filters.networks.length > 0) {
            isMatchingNetwork = filterFns.arrIncludesSome(
              state.filters.networks.map(x => x.label),
              [getNetworkName(row.asset.network)]
            );
          }

          if (isMatchingNetwork) {
            return row;
          }
          return null;
        })
        .filter(Boolean) as MappedAssetBalance[];
    }
  },
  actions: {
    findAssetBalanceBySymbol (symbol: string): AssetBalance | undefined {
      return this.assetsBalances.find(x => x.asset.symbol === symbol);
    },
    showAssetDetails (asset: AssetBalance) {
      if (asset) {
        this.selectedAsset = asset;
        this.isAssetDetailsSliderOpen = true;
      }
    },
    resetFilters () {
      this.$patch({
        filters: {
          networks: [],
          globalSearch: ''
        }
      });
    },
    async fetchWalletAssets () {
      const assetsBalances = await getBalances();
      this.hasFetched = true;
      this.lastFetched = Date.now();
      this.assetsBalances = this.mapWalletAssets(assetsBalances);
    },

    mapWalletAssets (assetBalances : AssetBalance[]): MappedAssetBalance[] {
      const { resolveFiatValue } = useCurrencyStore();
      return assetBalances.map((row) => {
        const fiatOnchain = resolveFiatValue(row.onchain_balance.usable.asFloat() + row.onchain_balance.pending.asFloat(), row.asset);
        const fiatOffchain = resolveFiatValue(row.offchain_balance.localBalance().asFloat(), row.asset);
        const fiatTotal = fiatOnchain + fiatOffchain;
        return {
          asset: row.asset,
          onchain_balance: row.onchain_balance,
          offchain_balance: row.offchain_balance,
          fiatTotal,
          fiatOnchain,
          fiatOffchain,
          free: row.free
        };
      });
    }
  }
});
