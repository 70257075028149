import revive_payload_client_uish6g2TOt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KismuMyMP6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RLMAAUfE6H from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_z5UUMZLf17 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ZqOcMbluOi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yZ3PNVRvDo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_h21jldNqVb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9W8KeV6QDN from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_sJYZZOHTbO from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_3jtWL3kRzw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.19.45_eslint@8.57.0_rollup@4.21.0_sass@1.77.8_stylelint@15.11.0_ty_f4btn4v6kxmscuisr773ctwjiy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_hTDTojzWLC from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.12.0_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_LyA9UjIAuA from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.12.0_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_UY5fBSHk2g from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_tPXVQA2tqx from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import i18n_pPgjKLMFme from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_fw2NCd7RJP from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_wKz300FoG6 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_L8pNr3xztO from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xkGf2LM3JS from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_rollup@4.21.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_S2n6wGmjKi from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.4.10_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_0NkKykRvFM from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_qrcode@1.5.4_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import plugin_pbAZl2bhlS from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_pinia@2.2.2_rollup@4.21.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import presets_VEsr3j3SfC from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_qrcode@1.5.4_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_auEnexG7DJ from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_qrcode@1.5.4_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import ui_rjNXKDZLQ9 from "/opt/build/repo/plugins/ui.ts";
export default [
  revive_payload_client_uish6g2TOt,
  unhead_KismuMyMP6,
  router_RLMAAUfE6H,
  _0_siteConfig_z5UUMZLf17,
  payload_client_ZqOcMbluOi,
  navigation_repaint_client_yZ3PNVRvDo,
  check_outdated_build_client_h21jldNqVb,
  chunk_reload_client_9W8KeV6QDN,
  plugin_vue3_sJYZZOHTbO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3jtWL3kRzw,
  titles_hTDTojzWLC,
  defaults_LyA9UjIAuA,
  siteConfig_UY5fBSHk2g,
  inferSeoMetaPlugin_tPXVQA2tqx,
  i18n_pPgjKLMFme,
  slideovers_fw2NCd7RJP,
  modals_wKz300FoG6,
  colors_L8pNr3xztO,
  plugin_client_xkGf2LM3JS,
  plugin_S2n6wGmjKi,
  scrollbars_client_0NkKykRvFM,
  plugin_pbAZl2bhlS,
  presets_VEsr3j3SfC,
  variables_auEnexG7DJ,
  i18n_VfGcjrvSkj,
  ui_rjNXKDZLQ9
]