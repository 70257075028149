import { BitcoinLightningInvoice, EvmLithiumInvoice } from 'hydra-node';
import { getBitcoinNetworkName, getEvmNetworkName } from '~/utils';

export function decodeInvoice (invoiceString:string, isEvm?:boolean) {
  try {
    if (!invoiceString) {
      return null;
    }
    if (isEvm) {
      return EvmLithiumInvoice.fromString(invoiceString);
    }
    return BitcoinLightningInvoice.fromString(invoiceString);
  } catch (err) {
    console.error('Error decoding invoice', err);
    return null;
  }
}

export function mapInvoiceString (invoiceString:string, isEvm?:boolean) {
  const decoded = decodeInvoice(invoiceString, isEvm);
  if (!decoded) {
    return null;
  }

  const networkName = isEvm ? getEvmNetworkName(decoded.network) : getBitcoinNetworkName(decoded?.network);
  return {
    amount: decoded.amount,
    assetId: decoded.assetId.toString(),
    expiryDate: new Date(convertSafelyBigIntToNumber(decoded.expiryTimestamp) * 1000),
    networkName,
    paymentHash: decoded.paymentHash,
    recipient: decoded.recipient.toString()
  };
}
