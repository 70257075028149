const includesString = (input: string, data: string) => {
  return Boolean(
    data?.toString()?.toLowerCase()?.includes(input.toLowerCase())
  );
};

const includesStringSensitive = (input: string, data: string) => {
  return Boolean(data.toString()?.includes(input));
};

const equalsString = (input: string, data: string) => {
  return data?.toString()?.toLowerCase() === input?.toLowerCase();
};

const arrIncludes = (input: unknown, data: any[]) => {
  return data?.includes(input);
};

const arrIncludesAll = (input: unknown[], data: any[]) => {
  return !input.some(val => !data?.includes(val));
};

const arrIncludesSome = (input: unknown[], data: any[]) => {
  return input.some(val => data?.includes(val));
};

const equals = (input: unknown, data: unknown) => {
  return data === input;
};

const weakEquals = (input: unknown, data: unknown) => {
  // eslint-disable-next-line eqeqeq
  return input == data;
};

const inNumberRange = (value: number, range: [number, number]) => {
  const [min, max] = range;
  return value >= min && value <= max;
};

// Export
export const filterFns = {
  includesString,
  includesStringSensitive,
  equalsString,
  arrIncludes,
  arrIncludesAll,
  arrIncludesSome,
  equals,
  weakEquals,
  inNumberRange
};
