<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useEventListener } from '@vueuse/core';
import { EventType, BitcoinEvent, EvmEvent, AppEventType } from 'hydra-node';
import { useTransactionsHistoryStore } from '~/stores/shared/transactions-history';
import { useChannelsStore } from '~/stores/shared/channels';
import { useWalletStore } from '~/stores/shared/wallet';
import { usePeerStore } from '~/stores/peers';
import { convertSafelySecondsToMilliseconds, convertSafelyBigIntToNumber } from '~/utils';

const peersStore = usePeerStore();
const { fetchChannels } = useChannelsStore();
const transactionHistoryStore = useTransactionsHistoryStore();
const walletStore = useWalletStore();
const toast = useToast();
const { t } = useI18n();

const { evmLastSynced, btcLastSynced } = storeToRefs(peersStore);
const { fetchWalletAssets } = walletStore;
const { fetchTransactions } = transactionHistoryStore;

// Add Eventlistener for node-event at onMounted to make
// sure, that the package is initialized
// Register all Listeners
onMounted(() => {
  useEventListener(window, 'evm-event', (evt: any) => {
    handleEvmEvent(evt);
  });
  useEventListener(window, 'bitcoin-event', (evt: any) => {
    handleBitcoinEvent(evt);
  });
  useEventListener(window, 'app-event', (evt: any) => {
    handleAppEvent(evt);
  });
});

function handleEvmEvent (evt: any) {
  const now = new Date();
  const evmEvent: EvmEvent = evt.detail;
  console.debug('NodeEventHelper received an EVM Event from node: ', EventType[evmEvent.eventType], '=>', (evmEvent.eventType));

  switch (evmEvent.eventType) {
    case EventType.BalanceUpdate:
      fetchWalletAssets();
      break;
    case EventType.NodeSynced:
      peersStore.connectToEvmPeer();
      fetchData();
      break;
    case EventType.ClientSynced:
      fetchData();
      evmLastSynced.value = now.getTime();
      break;
    case EventType.ClientSyncing:
      evmLastSynced.value = 0;
      break;
    case EventType.NewBlock:
      evmLastSynced.value = convertSafelySecondsToMilliseconds(
        convertSafelyBigIntToNumber(evmEvent.blockHeader!.timestamp)
      );
      break;
    case EventType.TransactionUpdate:
      fetchChannels();
      fetchTransactions();
      break;
    case EventType.ChannelUpdate || EventType.PaymentUpdate:
      fetchData();
      break;
    case EventType.ChannelRented:
      fetchData();
      addSuccessfulRent();
      break;
    case EventType.ChannelRentalFailed:
      fetchData();
      addFailedRentToast();
      break;
    case EventType.PeerConnected:
      fetchChannels();
      peersStore.addEvmPeer(evmEvent.peerId!);
      break;
    case EventType.PeerDisconnected:
      peersStore.removeEvmPeer();
      break;
    default:
      console.warn('Unknown EVM NodeEvent:', evmEvent);
  }
}

function handleBitcoinEvent (evt: any) {
  const now = new Date();
  const btcEvent: BitcoinEvent = evt.detail;
  console.debug('NodeEventHelper received Bitcoin Event from node: ', EventType[btcEvent.eventType], '=>', (btcEvent.eventType));

  switch (btcEvent.eventType) {
    case EventType.BalanceUpdate:
      fetchWalletAssets();
      break;
    case EventType.ChannelRented:
      fetchData();
      addSuccessfulRent();
      break;
    case EventType.ChannelRentalFailed:
      fetchData();
      addFailedRentToast();
      break;
    case EventType.NodeSynced:
      peersStore.connectToBitcoinPeer();
      fetchData();
      break;
    case EventType.ClientSyncing:
      btcLastSynced.value = 0;
      break;
    case EventType.ClientSynced:
      fetchData();
      btcLastSynced.value = now.getTime();
      break;
    case EventType.ChannelUpdate:
    case EventType.PaymentUpdate:
      fetchData();
      break;
    case EventType.TransactionUpdate:
      fetchChannels();
      fetchTransactions();
      break;
    case EventType.NewBlock:
      btcLastSynced.value = convertSafelySecondsToMilliseconds(
        convertSafelyBigIntToNumber(btcEvent.blockHeader!.timestamp)
      );
      break;
    case EventType.PeerConnected:
      fetchChannels();
      peersStore.addBitcoinPeer(btcEvent.peerId!);
      break;
    case EventType.PeerDisconnected:
      peersStore.removeBitcoinPeer();
      break;
    default:
      console.warn('Unknown Bitcoin NodeEvent:', btcEvent);
  }
}

function handleAppEvent (evt: { eventType: AppEventType }) {
  switch (evt.eventType) {
    case AppEventType.BalanceUpdate:
    case AppEventType.TransactionUpdate:
    case AppEventType.ChannelUpdate:
    case AppEventType.ChannelClosed:
    case AppEventType.ChannelRentalUpdated:
      fetchData();
      break;
    default:
      console.warn('Unknown App Event:', evt);
  }
}
function fetchData () {
  fetchWalletAssets();
  fetchTransactions();
  fetchChannels();
}

function addFailedRentToast () {
  toast.add({
    id: 'channel-rental-failed',
    title: t('notifications.channel-rental-failed.title'),
    description: t('notifications.channel-rental-failed.description'),
    icon: 'i-heroicons-exclamation-triangle',
    color: 'red',
    timeout: 10000
  });
}

function addSuccessfulRent () {
  toast.add({
    id: 'channel-rented',
    title: t('notifications.channel-rented.title'),
    description: t('notifications.channel-rented.description'),
    icon: 'i-heroicons-check-circle',
    color: 'green',
    timeout: 10000
  });
}

</script>
<template>
  <div />
</template>
