import { BitcoinNetwork, EvmNetwork, Network, Protocol } from 'hydra-node';

export const humanizeEnumValue = (value = ''):string => {
  return value.replace(/(.)([A-Z])/g, '$1 $2');
};

export const getBitcoinNetworkName = (networkId: number | string): string => {
  const existingNetwork = BitcoinNetwork[Number(networkId)];

  if (!existingNetwork) {
    console.warn(`Unknown network id: ${networkId}`);
    return '';
  }
  return humanizeEnumValue(existingNetwork);
};

export const getEvmNetworkName = (networkId: number | string): string => {
  const existingNetwork = EvmNetwork[Number(networkId)];
  if (!existingNetwork) {
    console.warn(`Unknown network id: ${networkId}`);
    return '';
  }
  return humanizeEnumValue(existingNetwork);
};

export const getNetworkName = (network: Network) => {
  if (!network) { return ''; }
  if (network.type === Protocol.Evm) {
    return getEvmNetworkName(network.asEvm()!);
  } else if (network.type === Protocol.Bitcoin) {
    return getBitcoinNetworkName(network.asBitcoin()!);
  }
};
