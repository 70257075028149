import { defineStore } from 'pinia';
import {
  Asset,
  Amount,
  BitcoinAsset,
  BitcoinPublicKey,
  Channel,
  EvmAddress,
  EvmAsset,
  FeeRate,
  bitcoinEstimateCloseChannelFee,
  evmEstimateOpenChannelFee,
  evmEstimateDepositChannelFee,
  evmEstimateWithdrawChannelFee,
  evmEstimateCloseChannelFee,
  bitcoinEstimateOpenChannelFee,
  ChannelStatus,
  Network,
  getChannels,
  AssetChannel,
  Protocol,
  SendAmount
} from 'hydra-node';
import { TransferTxType } from '~/enums/transaction';
import { PiniaStoresId } from '~/enums';

export const useChannelsStore = defineStore(PiniaStoresId.ChannelsStore, () => {
  const channels = ref<Channel[] | null>([]);
  const hasFetched = ref<boolean>(false);
  const lastFetched = ref<number>(0);
  const runtimeConfig = useRuntimeConfig();

  function getChannelsByPeer (peer: string, protocol?: Protocol): Channel[] {
    const groupedChannels = channels.value?.filter(channel => channel.counterparty === peer) || [];
    if (protocol) {
      return groupedChannels?.filter((channel) => {
        return Protocol[channel.protocol as unknown as keyof typeof Protocol] === protocol;
      });
    }
    return groupedChannels;
  }

  function getAssetChannelByAsset (asset: Asset): Channel | undefined {
    const filteredChannelsByProtocol = channels.value?.filter(channel => channel.protocol === asset.type) || [];
    if (filteredChannelsByProtocol.length === 0) {
      return undefined;
    }
    for (const channel of filteredChannelsByProtocol) {
      for (const assetChannel of channel.assetChannels) {
        if (assetChannel.asset.network.name === asset.network.name && assetChannel.asset.symbol === asset.symbol) {
          return channel;
        }
      }
    }
    return undefined;
  }

  function getAssetChannelByAssetAndPeer (asset: Asset, peer: string | null): Channel | undefined {
    if (!peer || !asset) {
      return undefined;
    }
    const channelsByPeer = getChannelsByPeer(peer, asset.type);
    if (channelsByPeer.length === 0) {
      return undefined;
    }
    for (const channel of channelsByPeer) {
      for (const assetChannel of channel.assetChannels) {
        if (assetChannel.asset.network.name === asset.network.name && assetChannel.asset.symbol === asset.symbol) {
          return channel;
        }
      }
    }
    return undefined;
  }

  function findNetworkChannelById (channelId: string): Channel | undefined {
    return (channels.value as unknown as Channel[])?.find((channel: Channel) => channel.id?.toString() === channelId) || null;
  }

  function findAssetChannelFromChannelId (channelParentId: string, assetChannelSymbol: string): AssetChannel {
    const channel = findNetworkChannelById(channelParentId);
    return channel?.assetChannels?.find(assetChannel => assetChannel.asset.symbol === assetChannelSymbol);
  }
  function findAssetChannelFromChannel (channel: Channel, assetChannelSymbol: string): AssetChannel|undefined {
    return channel?.assetChannels?.find(assetChannel => assetChannel.asset.symbol === assetChannelSymbol);
  }

  async function fetchChannels () {
    try {
      const fetchedChannels = await getChannels();
      channels.value = fetchedChannels || [];
      hasFetched.value = true;
      lastFetched.value = Date.now();
    } catch (error) {
      console.error('Error while fetching channels', error);
    }
  }

  function isChannelReadyForOperation (txType: TransferTxType | null, channel: Channel | null, asset: Asset | undefined): boolean {
    if (txType === null || !channel || !asset) {
      return false;
    }
    return channel.status === ChannelStatus.Active;
  }

  /**
 * This function will take the TransferTxType
 * After that it will check wether all required parameters are present
 * @param txType The Transaction Type
 * @param asset To be transferred asset
 * @param amount To be transferred amount
 * @param feeRate The selected fee rate
 * @param channel The to be used Channel for TxType
 * @param network The to be used network for opening transactions
 * @returns {Promise<Amount | null>} The Promise of an Amount or null if error or parameters are not present
 */
  async function getChannelFeeEstimation (txType: TransferTxType | null, asset: Asset| null, amount: SendAmount | null, feeRate: FeeRate | null, channel: Channel | null, network: Network | undefined) : Promise<Amount | null> {
    let txFeeAmount = null;
    if (txType === null || !feeRate) {
      return null;
    }
    const evmAssetMap = new Map<EvmAsset, SendAmount>();
    const btcAssetMap = new Map<BitcoinAsset, SendAmount>();

    // Opening a, Deposit to or Withdrawing from EVM Channel required asset, as it will be batched Tx at backend
    if ([TransferTxType.EVM_OPEN, TransferTxType.EVM_DEPOSIT, TransferTxType.EVM_WITHDRAW].includes(txType)) {
      const evmAsset = asset?.asEvm();
      if (!evmAsset || !amount) {
        return null;
      }
      if (txType === TransferTxType.EVM_OPEN && !network) {
        return null;
      }
      evmAssetMap.set(evmAsset, amount);
    }
    // Opening/Closing a, Deposit to or Withdrawing from EVM/BTC Channel requires channel
    if ([TransferTxType.EVM_DEPOSIT, TransferTxType.EVM_WITHDRAW, TransferTxType.BTC_CLOSE, TransferTxType.EVM_CLOSE].includes(txType)) {
      if (!channel) {
        return null;
      }
    }
    if (TransferTxType.BTC_OPEN === txType) {
      const btcAsset = asset?.asBitcoin();
      if (!btcAsset || !amount || !network) {
        return null;
      }
      btcAssetMap.set(btcAsset, amount);
    }

    switch (txType as TransferTxType) {
      case TransferTxType.EVM_OPEN:
        // @ts-ignore
        txFeeAmount = await evmEstimateOpenChannelFee(network.asEvm(), EvmAddress.fromString(runtimeConfig.public.EVM_PEER), evmAssetMap, feeRate, false);
        return txFeeAmount;
      case TransferTxType.EVM_DEPOSIT:
        // @ts-ignore
        txFeeAmount = await evmEstimateDepositChannelFee(channel?.network?.asEvm(), channel?.asEvmLithiumChannel()?.id, evmAssetMap, feeRate, false);
        return txFeeAmount;
      case TransferTxType.EVM_WITHDRAW:
        // @ts-ignore
        txFeeAmount = await evmEstimateWithdrawChannelFee(channel?.network?.asEvm(), channel?.asEvmLithiumChannel()?.id, evmAssetMap, feeRate, false);
        return txFeeAmount;
      case TransferTxType.EVM_CLOSE:
        // @ts-ignore
        txFeeAmount = await evmEstimateCloseChannelFee(channel?.network?.asEvm(), channel?.asEvmLithiumChannel()?.id, feeRate, false);
        return txFeeAmount;
      case TransferTxType.BTC_OPEN:
        // @ts-ignore
        txFeeAmount = await bitcoinEstimateOpenChannelFee(network?.asBitcoin(), BitcoinPublicKey.fromString(runtimeConfig.public.BTC_PEER), btcAssetMap, feeRate, false);
        return txFeeAmount;
      case TransferTxType.BTC_CLOSE:
        // @ts-ignore
        txFeeAmount = await bitcoinEstimateCloseChannelFee(channel?.network?.asBitcoin(), channel?.asBitcoinLightningChannel()?.id, feeRate, false);
        return txFeeAmount;
      default:
        console.debug('Unknown transaction type');
        return null;
    }
  }

  return {
    channels,
    hasFetched,
    fetchChannels,
    lastFetched,
    getChannelFeeEstimation,
    isChannelReadyForOperation,
    getChannelsByPeer,
    findNetworkChannelById,
    findAssetChannelFromChannelId,
    findAssetChannelFromChannel,
    getAssetChannelByAsset,
    getAssetChannelByAssetAndPeer
  };
});
