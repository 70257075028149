import { z } from 'zod';
import {
  AddressSchemaObject,
  AmountSchemaObject,
  ValiditySchemaObject,
  InvoiceAddressSchema,
  InvoiceAmountSchema,
  SendAmountSchema
} from './partials';
import { BitcoinAddress, BitcoinPublicKey, EvmAddress, Layer } from 'hydra-node';
import type { Translator, InvoiceZodContext, SendZodContext } from '~/types';
import {
  decodeInvoice,
  mapInvoiceString
} from '~/utils/invoice';

function getAddressSchema (context: SendZodContext) {
  const baseSchema = z.string().min(1, context.t('form-schemas.errors.address.required_error'));
  if (context.isEvm) {
    return baseSchema.refine(
      (address) => {
        try {
          EvmAddress.fromString(address);
          return true;
        } catch (err) {
          return false;
        }
      },
      context.t('form-schemas.errors.address.evm.invalid_format_error')
    );
  } else {
    return baseSchema.refine(
      (address) => {
        try {
          if (context.layer === Layer.Offchain) {
            BitcoinPublicKey.fromString(address);
          } else {
            BitcoinAddress.fromString(address);
          }
          return true;
        } catch (err) {
          return false;
        }
      },
      context.t('form-schemas.errors.address.btc.invalid_format_error')
    );
  }
}

export const SendOnChainFormSchemaObject = AddressSchemaObject.merge(AmountSchemaObject);

export const ReceiveInvoiceFormSchemaObject = AmountSchemaObject.merge(ValiditySchemaObject);
export const SendInvoiceFormSchemaObject = (context : InvoiceZodContext) => InvoiceAddressSchema(context)
  .merge(InvoiceAmountSchema(context))
  .merge(ValiditySchemaObject);
export const SendFormSchemaObject = (context : SendZodContext) => SendAmountSchema(context).merge(AddressSchemaObject);

export const SendOnChainToAddressStepFormSchema = (context: SendZodContext) => z.object({
  address: getAddressSchema(context)
});

export const SendOnChainAmountStepSchema = (context: SendZodContext) =>
  z.object({
    amount: z.number({
      invalid_type_error: context.t('form-schemas.errors.amount.invalid_type_error'),
      required_error: context.t('form-schemas.errors.amount.required_error')
    })
      .positive({
        message: context.t('form-schemas.errors.amount.positive_error')
      })
      .finite()
      .refine(val => val <= context.maxSendAmount, {
        message: context.t('generics.not-enough-balance')
      }),
    txSpeed: z.number().finite().int()
  });

export const SendOffChainMethodStepFormSchema = (t: Translator) => z.object({
  l2PaymentMethod: z.number({
    required_error: t('form-schemas.errors.method.required_error')
  })
});

export const SendOffChainViaAddressAmountStepSchema = (context: SendZodContext) =>
  z.object({
    amount: z.number({
      invalid_type_error: context.t('form-schemas.errors.amount.invalid_type_error'),
      required_error: context.t('form-schemas.errors.amount.required_error')
    })
      .positive({
        message: context.t('form-schemas.errors.amount.positive_error')
      })
      .finite()
      .refine(val => val <= context.maxSendAmount, {
        message: context.t('generics.not-enough-balance')
      }),
    toAddress: getAddressSchema(context)
  });

/**
 * We use the superRefine method to chain custom validation and
 * prevent further validation if one of them fails.
 * @param context
 * @constructor
 */
export const SendOffChainViaInvoiceStepSchema = (context: SendZodContext) =>
  z.object({
    amount: z.number({
      invalid_type_error: context.t('form-schemas.errors.amount.invalid_type_error'),
      required_error: context.t('form-schemas.errors.amount.required_error')
    })
      .positive({
        message: context.t('form-schemas.errors.amount.positive_error')
      })
      .finite()
      .refine(val => val <= context.maxSendAmount, {
        message: context.t('generics.not-enough-balance')
      }),
    invoiceString: z.string().min(1, context.t('form-schemas.errors.invoice.required_error'))
      .superRefine((value, zodCtx) => {
        const decoded = decodeInvoice(value, context.isEvm);
        if (!decoded) {
          zodCtx.addIssue({
            code: z.ZodIssueCode.custom,
            message: context.t('send-offchain.steps.details.inputs.invoice.hint.invalid'),
            // prevent further validation
            fatal: true
          });
        }
        // prevent further validation
        return z.NEVER;
      })
      .superRefine((value, zodCtx) => {
        const invoice = mapInvoiceString(value, context.isEvm, context.assetBalance);

        if (invoice.expiryDate < new Date()) {
          zodCtx.addIssue({
            code: z.ZodIssueCode.custom,
            message: context.t('send-offchain.steps.details.inputs.invoice.hint.expired'),
            fatal: true
          });
        }
        if (invoice?.assetId !== getAssetBalanceId(context.assetBalance)) {
          zodCtx.addIssue({
            code: z.ZodIssueCode.custom,
            message: context.t('send-offchain.steps.details.inputs.invoice.hint.wrong-asset'),
            fatal: true
          });
        }
        return z.NEVER;
      })
  });
