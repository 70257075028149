import {
  Layer,
  Network,
  Protocol,
  Transaction
} from 'hydra-node';
import type { AssetAmount } from '~/types/asset';

export function getFirstOperation (transaction: Transaction) {
  const getProtocolTransaction = () => {
    switch (transaction.protocol) {
      case Protocol.Evm:
        return transaction.layer === Layer.Onchain
          ? transaction.asEvmTransaction()
          : transaction.asEvmLithiumPayment();
      case Protocol.Bitcoin:
        return transaction.layer === Layer.Onchain
          ? transaction.asBitcoinTransaction()
          : transaction.asBitcoinLightningPayment();
      default:
        return null;
    }
  };

  const protocolTransaction = getProtocolTransaction();
  return protocolTransaction?.operations?.[0] ?? null;
}

export function extractFirstAssetAmount (transaction: Transaction):AssetAmount | null {
  if (transaction?.assetsAmountsToShow?.size) {
    const arr = Array.from(transaction.assetsAmountsToShow.entries())
      .map(([asset, amount]) => ({
        asset,
        amount
      }));
    return arr[0] || null;
  }
  return null;
}

export function makeTransactionExplorerUrl (txId: string, network: Network) {
  return `${network.explorerUrl}/tx/${txId}`;
}
