export enum TxSpeed {
  low,
  medium,
  high
}

export enum TxRequestState {
  NULL,
  CREATED,
  CONFIRMED,
  BROADCASTED,
  SENDING
}

export enum TransferTxType {
  BTC_CLOSE,
  BTC_OPEN,
  EVM_OPEN,
  EVM_CLOSE,
  EVM_DEPOSIT,
  EVM_WITHDRAW
}

export enum LayerTxType {
  ONCHAIN_ADDRESS,
  OFFCHAIN_ADDRESS,
  OFFCHAIN_INVOICE
}
