import { SupportedLocale } from '~/enums';

export const DEFAULT_LOCALE = SupportedLocale.en;

export const NUMBER_FORMATS = {
  'currency-long': {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 6,
    style: 'currency',
    currency: '',
    currencyDisplay: 'code'
  },
  'currency-short': {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4,
    currency: '',
    style: 'currency',
    currencyDisplay: 'code'
  },
  'currency-compact-long': {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4,
    currency: '',
    style: 'currency',
    currencyDisplay: 'code'
  },
  'currency-compact-short': {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 2,
    currency: '',
    style: 'currency',
    currencyDisplay: 'code'
  },
  decimal: {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  },
  'decimal-currency': {
    style: 'decimal',
    minimumFractionDigits: 6,
    maximumFractionDigits: 6
  },
  'decimal-long': {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 6
  },
  'decimal-max': {
    style: 'decimal',
    minimumFractionDigits: 6,
    maximumFractionDigits: 6

  },
  'decimal-short': {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4
  },
  'decimal-compact-long': {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4
  },
  'decimal-compact-short': {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 2
  },
  'decimal-floor': {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  },
  percent: {
    style: 'percent',
    useGrouping: false
  }
} as const;

export const DATE_FORMATS = {
  // DDDD, MMMM, D, YYYY
  // Tuesday, March 28, 2023
  long: {
    year: 'numeric',
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  },
  // MMMM D, YYYY
  // March 28, 2023
  'long-year': {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  },
  // MMMM D, YY
  // March 28, 23
  'short-year': {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  },
  // MMMM D
  // March 28
  'long-month': {
    month: 'long',
    day: 'numeric'
  },
  // MMM D
  // Mar 28
  'short-month': {
    month: 'short',
    day: 'numeric'
  },
  // DDDD, MMMM D
  // Tuesday, March 28
  'long-week': {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  },
  // YYYY-MM-DD
  // 2023-03-28
  'year-month-day': {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  },
  // HH:mm:ss
  // 17:42:10
  'hours-minutes-second-format': {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  },
  // YYYY-MM-DD HH:mm:ss
  // 2023-03-28 17:42:10
  'numeric-date-hour-minute-seconds': {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  },
  'short-date-time': {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  },
  // DDDD, MMMM D, YYYY 'at' h:mm:ss A
  // Tuesday, March 28, 2023 at 4:23:16
  'date-time': {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  },
  /// DDDD, MMMM D, YYYY, h:mm:ss A Z
  // Tuesday, March 28, 2023, 5:42:10 GMT+1
  'date-time-zone': {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    hour12: false
  }
} as const;
