import { defineStore, storeToRefs } from 'pinia';
import { useHydraNodeStore } from './hydra-node';
import {
  BitcoinNetwork,
  EvmNetwork,
  EvmLithiumPeer,
  evmConnectToPeer,
  bitcoinConnectToPeer,
  BitcoinLightningPeer,
  EvmAddress,
  BitcoinPublicKey
} from 'hydra-node';
import { PiniaStoresId } from '~/enums';

export const usePeerStore = defineStore(PiniaStoresId.PeerStore, () => {
  const runtimeConfig = useRuntimeConfig();
  const hydraNodeStore = useHydraNodeStore();
  const { btcLastSynced, evmLastSynced } = storeToRefs(hydraNodeStore);

  const evmPeers = ref<EvmAddress []>([]);
  const btcPeers = ref<BitcoinPublicKey []>([]);

  const isNodeConnectingToBtcPeer = ref(false);
  const isNodeConnectingToEvmPeer = ref(false);

  async function connectToBitcoinPeer () {
    const peer = BitcoinLightningPeer.fromString(runtimeConfig.public.BTC_PEER_STRING);
    isNodeConnectingToBtcPeer.value = true;

    try {
      await bitcoinConnectToPeer(BitcoinNetwork.Signet, peer);
    } catch (err) {
      console.warn('Error while connecting to Bitcoin peer');
    } finally {
      isNodeConnectingToBtcPeer.value = false;
    }
  }

  async function connectToEvmPeer () {
    const peer = EvmLithiumPeer.fromString(runtimeConfig.public.EVM_PEER_STRING);
    isNodeConnectingToEvmPeer.value = false;
    try {
      await evmConnectToPeer(EvmNetwork.EthereumHolesky, peer);
    } catch (err) {
      console.warn('Error while connecting to EVM peer');
    } finally {
      isNodeConnectingToEvmPeer.value = false;
    }
  }
  function addEvmPeer (evmPeer: EvmAddress) {
    evmPeers.value.push(evmPeer);
  }

  function addBitcoinPeer (bitcoinPeer: BitcoinPublicKey) {
    btcPeers.value.push(bitcoinPeer);
  }

  function removeEvmPeer () {
    evmPeers.value = [];
  }

  function removeBitcoinPeer () {
    btcPeers.value = [];
  }

  function resetPeerStore () {
    removeEvmPeer();
    removeBitcoinPeer();
  }

  function getCurrentEvmPeer () {
    return evmPeers.value[0] || null;
  }

  function getCurrentBtcPeer () {
    return btcPeers.value[0] || null;
  }

  return {
    connectToBitcoinPeer,
    connectToEvmPeer,
    isNodeConnectingToBtcPeer,
    isNodeConnectingToEvmPeer,
    evmLastSynced,
    btcLastSynced,
    getCurrentBtcPeer,
    evmPeers,
    btcPeers,
    addEvmPeer,
    addBitcoinPeer,
    removeEvmPeer,
    removeBitcoinPeer,
    getCurrentEvmPeer,
    resetPeerStore
  };
});
