import { createI18n } from 'vue-i18n';
import en from '~/assets/translations/en.json';

import { SupportedLocale } from '~/enums';
import { DATE_FORMATS, NUMBER_FORMATS } from '~/constants';

const instance = createI18n({
  globalInjection: true,
  legacy: false,
  locale: SupportedLocale.en,
  fallbackLocale: SupportedLocale.en,
  fallbackWarn: false,
  silentFallbackWarn: true,
  inheritLocale: true,
  messages: {
    en
  },
  numberFormats: {
    en: {
      ...NUMBER_FORMATS
    }
  },
  datetimeFormats: {
    en: {
      ...DATE_FORMATS
    }
  }
});

export default instance;

export const i18n = instance.global;
