<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { useHydraNodeStore } from '~/stores/hydra-node';
import NodeEventHelper from '~/components/pro/helper/NodeEventHelper.vue';
import { AppMode, LocalStorageIds } from '~/enums';

// TODO adapt store https://github.com/Offchain-DEX/web-dex/issues/280
const hydraNodeStore = useHydraNodeStore();

const { mnemonic, isPackageInitialized } =
  storeToRefs(hydraNodeStore);

const selectedAppMode = useLocalStorage(LocalStorageIds.uiMode, AppMode.PRO);

onBeforeMount(async () => {
  await hydraNodeStore.initializePackage();
});

watchEffect(async () => {
  if (mnemonic.value && isPackageInitialized.value) {
    await hydraNodeStore.initializeHydraNode();
  }
});
const renderNodeEventHelperBasedOnAppMode = computed(() => {
  if (selectedAppMode.value === AppMode.PRO) {
    return NodeEventHelper;
  }
  return null;
});
</script>
<template>
  <div
    class="u-border-gray-200 relative mx-auto max-w-[120rem] 3xl:border-2"
  >
    <NuxtPage />
    <UNotifications />
    <component :is="renderNodeEventHelperBasedOnAppMode" v-if="renderNodeEventHelperBasedOnAppMode" />
    <UModals />
  </div>
</template>
